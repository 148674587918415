import React from "react";
import { EventEmitter } from "events";
import classNames from "classnames";

import { TrackVisualisation } from "adwar-shared/src/trackvisualisation/TrackVisualisation";

import { TrackControls } from "./types";
import * as adwarCompat from "./adwarCompat";

import "./AdwarMiniDisplay.scss";

interface AdwarMiniDisplay {
  trackId: string;
  controls: TrackControls;
  events: EventEmitter;
  disabled: boolean;
  onToggleEdit: () => void;
}
export const AdwarMiniDisplay: React.FC<AdwarMiniDisplay> = ({
  trackId,
  controls,
  events,
  disabled,
  onToggleEdit,
}) => {
  let { adwarTrack, playState } = adwarCompat.useAdwarUIAdapter(
    trackId,
    controls,
    events
  );

  if (!adwarTrack || !playState) return <></>;

  return (
    <div className={classNames("adwarMiniDisplay", { disabled })}>
      <TrackVisualisation
        track={adwarTrack}
        playState={playState}
        trackOpen={false}
        onToggleTrigger={() => {}}
        onChangeTriggerVelocity={() => {}}
      />
      <div
        className="adwarMiniDisplay--openOverlay"
        onClick={() => !disabled && onToggleEdit()}
      ></div>
    </div>
  );
};
