import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import useLongPressDrag from "../useLongPressDrag";
import { PitchClass } from "../types";
import { getPitchCents } from "../utils";
import { useMoveOutside } from "../useMoveOutside";
import { clamp } from "lodash";

const HIGHLIGHT_WIDTH = 20;

interface HighlightProps {
  left: number;
}
const Highlight = styled.div<HighlightProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${HIGHLIGHT_WIDTH}px;
  height: 100%;
  transform: ${({ left }) => `translateX(${left - HIGHLIGHT_WIDTH / 2}px)`};
  cursor: pointer;
  touch-action: none;
`;

const VelocitySlider = styled.input`
  width: calc(var(--unit) * 3);
  height: ${HIGHLIGHT_WIDTH}px;
  position: absolute;
  z-index: 3;
  top: calc(var(--unit) + var(--margin));
  left: -2px;
  transform: rotate(270deg) translate(-50%, -50%);
  transform-origin: ${HIGHLIGHT_WIDTH / 2}px 0;
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${HIGHLIGHT_WIDTH}px;
    background: transparent;
  }
  &::-webkit-slider-thumb {
    width: ${HIGHLIGHT_WIDTH}px;
    height: ${HIGHLIGHT_WIDTH * 2}px;
    transform: translateY(-${HIGHLIGHT_WIDTH}px);
    border: none;
    border-left: 1px solid white;
    border-right: 1px solid white;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &::-webkit-slider-thumb:hover {
  }
`;

const VelocitySliderBubble = styled.div<{
  bubblePosition: number;
}>(
  ({ bubblePosition }) => `
  position: relative;
    z-index: 4;
    width: ${HIGHLIGHT_WIDTH}px;
    height: ${HIGHLIGHT_WIDTH}px;
    display: grid;
    align-items: center;
    padding-bottom: 1px;
    transform: translateX(-100%) translateY(${bubblePosition}px);
    user-select: none;
    pointer-events: none;
    text-align: right;
  `
);

const VELOCITY_MIN = 1;
const VELOCITY_MAX = 127;

interface TrackMarkerHighlightProps {
  pc: PitchClass;
  isActive: boolean;
  velocity: number;
  trackWidth: number;
  onMouseDown: () => void;
  onClose: () => void;
  onUpdateVelocity: (velocity: number) => void;
}
export const TrackMarkerHighlight: React.FC<TrackMarkerHighlightProps> = ({
  pc,
  isActive,
  velocity,
  trackWidth,
  onMouseDown,
  onClose,
  onUpdateVelocity,
}) => {
  let left = (getPitchCents(pc) / 1200) * trackWidth;
  let containerRef = useRef<HTMLDivElement>(null);

  let velocityOnDragStartRef = useRef(velocity);
  let onLongPressDragStart = () => {
    velocityOnDragStartRef.current = velocity;
  };
  let onLongPressDrag = (delta: number) => {
    let relDelta = -delta / 1.5;
    let newVelocity = clamp(
      velocityOnDragStartRef.current + relDelta,
      VELOCITY_MIN,
      VELOCITY_MAX
    );
    onUpdateVelocity(newVelocity);
  };

  const { action, handlers } = useLongPressDrag(
    isActive,
    onMouseDown,
    onLongPressDragStart,
    onLongPressDrag
  );

  useMoveOutside(containerRef, onClose);

  const [bubblePosition, setBubblePosition] = useState(0);
  const sliderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!sliderRef.current) return;
    const relValue = (velocity - VELOCITY_MIN) / (VELOCITY_MAX - VELOCITY_MIN);
    const travel = sliderRef.current.offsetWidth - HIGHLIGHT_WIDTH;
    const calcStep = (1 - relValue) * travel;
    setBubblePosition(calcStep);
  }, [velocity, action]);

  return (
    <Highlight ref={containerRef} left={left} {...handlers}>
      {action === "longpress" && (
        <>
          <VelocitySliderBubble bubblePosition={bubblePosition}>
            {Math.round(velocity)}
          </VelocitySliderBubble>
          <VelocitySlider
            readOnly
            type="range"
            min={VELOCITY_MIN}
            max={VELOCITY_MAX}
            value={velocity}
            ref={sliderRef}
          />
        </>
      )}
    </Highlight>
  );
};
