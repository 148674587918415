import ReconnectingWebSocket from "reconnecting-websocket";
import { EventEmitter } from "events";
import { isNull, isNumber, sortedIndexBy } from "lodash";
import { setTimeout } from "worker-timers";

import { VisualizerConnectionSettings } from "./types";
import { getContext, getTransport, immediate } from "tone";
import {
  getTrackControls,
  getTrackMelodyShapeCurve,
  getTrackVoiceCounts,
  playbackEvents,
} from "./apotomeController";

let socket: ReconnectingWebSocket | null = null;
let curveSenderTimer: number | null = null;
let currentSettings: VisualizerConnectionSettings | null = null;
let trackSubscriptions = new Map<string, () => void>();
let transportControlsSubscription: (() => void) | null = null;
export let visualizerEvents = new EventEmitter();

export function onSettingsUpdated(newSettings: VisualizerConnectionSettings) {
  if (newSettings.on) {
    if (socket && currentSettings?.url !== newSettings.url) {
      socket.close();
      socket = null;
    }
    if (!socket) {
      console.log("Opening visualizer connection", newSettings.url);
      socket = new ReconnectingWebSocket(newSettings.url);
      socket.addEventListener("open", () => sendStateEvent(socket!));
      socket.addEventListener("close", () => sendStateEvent(socket!));
      socket.addEventListener("error", () => sendStateEvent(socket!));
    }
    if (isNull(curveSenderTimer)) {
      let frame = () => {
        sendCurves();
        curveSenderTimer = setTimeout(frame, 1000 / 60);
      };
      curveSenderTimer = setTimeout(frame, 0);
    }
    if (!transportControlsSubscription) {
      let onTransportControlsChange = () => {
        let cycleTime = getTransport().toSeconds("1m");
        let msgBuffer = new ArrayBuffer(5);
        let msgDataView = new DataView(msgBuffer);
        msgDataView.setUint8(0, 3);
        msgDataView.setFloat32(1, cycleTime, true);
        socket?.send(msgBuffer);
      };
      playbackEvents.on("transportControlsChange", onTransportControlsChange);
      transportControlsSubscription = () =>
        playbackEvents.off(
          "transportControlsChange",
          onTransportControlsChange
        );
      onTransportControlsChange();
    }
  } else {
    if (socket) {
      console.log("Closing visualizer connection");
      socket.close();
      socket = null;
    }
    if (isNumber(curveSenderTimer)) {
      cancelAnimationFrame(curveSenderTimer);
      curveSenderTimer = null;
    }
    if (transportControlsSubscription) {
      transportControlsSubscription();
      transportControlsSubscription = null;
    }
  }
  currentSettings = newSettings;
}

function sendCurves() {
  if (socket && socket.readyState === ReconnectingWebSocket.OPEN) {
    let sortKey = { time: immediate(), octave: -1, indexFraction: -1, raw: -1 };
    trackSubscriptions.forEach((_, id) => {
      let controls = getTrackControls(id);
      if (
        getTransport().state === "started" &&
        controls?.started &&
        controls?.melodyType === "shaped"
      ) {
        let curve = getTrackMelodyShapeCurve(id);
        let idx = sortedIndexBy(curve, sortKey, (c) => c.time);
        if (idx >= 0 && idx < curve.length) {
          let msgBuffer = new ArrayBuffer(7);
          let msgDataView = new DataView(msgBuffer);
          msgDataView.setUint8(0, 1);
          msgDataView.setUint8(1, +id);
          msgDataView.setFloat32(2, curve[idx].raw, true);
          msgDataView.setUint8(6, 1);
          socket?.send(msgBuffer);
        }
      } else {
        let msgBuffer = new ArrayBuffer(7);
        let msgDataView = new DataView(msgBuffer);
        msgDataView.setUint8(0, 1);
        msgDataView.setUint8(1, +id);
        msgDataView.setFloat32(2, 0, true);
        msgDataView.setUint8(6, 0);
        socket?.send(msgBuffer);
      }
    });
  }
}

function sendStateEvent(socket: ReconnectingWebSocket) {
  let state = "";
  if (socket.readyState === ReconnectingWebSocket.CLOSED) state = "closed";
  if (socket.readyState === ReconnectingWebSocket.CLOSING) state = "closing";
  if (socket.readyState === ReconnectingWebSocket.CONNECTING)
    state = "connection";
  if (socket.readyState === ReconnectingWebSocket.OPEN) state = "open";
  visualizerEvents.emit("stateChange", state);
}

export function onTrackAdded(id: string, events: EventEmitter) {
  let onNote = ({
    scheduledForTime,
    voiceIdx,
    freq,
    duration,
    beatDivisionRelativeDuration,
    velocity,
  }: {
    scheduledForTime: number;
    voiceIdx?: number;
    octave?: number;
    freq?: number;
    duration?: number;
    beatDivisionRelativeDuration?: number;
    velocity?: number;
  }) => {
    let timeUntil = scheduledForTime - getContext().currentTime - 0.01;
    if (timeUntil <= 0) {
      sendNoteEvent(
        id,
        voiceIdx,
        freq,
        beatDivisionRelativeDuration,
        duration,
        velocity
      );
    } else {
      setTimeout(() => {
        sendNoteEvent(
          id,
          voiceIdx,
          freq,
          beatDivisionRelativeDuration,
          duration,
          velocity
        );
      }, timeUntil * 1000);
    }
  };
  events.on("noteImmediate", onNote);
  trackSubscriptions.set(id, () => events.off("noteImmediate", onNote));
}

export function sendNoteEvent(
  trackId: string,
  voiceIdx: number | undefined,
  freq: number | undefined,
  beatDivisionRelativeDuration: number | undefined,
  duration: number | undefined,
  velocity: number | undefined
) {
  if (
    socket &&
    isNumber(freq) &&
    socket.readyState === ReconnectingWebSocket.OPEN
  ) {
    let msgBuffer = new ArrayBuffer(18);
    let msgDataView = new DataView(msgBuffer);
    msgDataView.setUint8(0, 2);
    msgDataView.setUint8(1, resolveTrackTableRow(+trackId, voiceIdx ?? 0));
    msgDataView.setFloat32(2, freq ?? 0, true);
    msgDataView.setFloat32(6, beatDivisionRelativeDuration ?? 0, true);
    msgDataView.setFloat32(10, duration ?? 0, true);
    msgDataView.setFloat32(14, velocity ?? 0, true);
    socket?.send(msgBuffer);
  }
}

function resolveTrackTableRow(trackId: number, voiceIdx: number) {
  let voiceCounts = getTrackVoiceCounts();
  let prior = 0;
  for (let i = 1; i < trackId; i++) {
    prior += voiceCounts.get(`${i}`) ?? 0;
  }
  return prior + voiceIdx;
}

export function onTrackRemoved(id: string) {
  if (trackSubscriptions.has(id)) {
    trackSubscriptions.get(id)!();
    trackSubscriptions.delete(id);
  }
}

export function onAllTracksRemoved() {
  trackSubscriptions.forEach((unSub) => unSub());
  trackSubscriptions.clear();
}
