import React from "react";
import styled from "@emotion/styled";
import { Tuning } from "../types";
import { getPitchCents } from "../utils";

const Container = styled.div`
  position: absolute;
  height: 100%;
`;

interface MarkerProps {
  left: number;
  isHighlighted?: boolean;
}
const Marker = styled.div<MarkerProps>(
  ({ left, isHighlighted }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  transform: translateX(${left ? left : 0}px);
  box-sizing: border-box;
  border-left: 1px dashed ${
    isHighlighted ? "transparent" : "var(--pitch-marker)"
  };
  transition: border-color 0.05s;
`
);

interface TrackTuningVisualisationProps {
  tuning?: Tuning;
  width: number;
  highlightIndex?: number | null;
}
export const TrackTuningVisualisation: React.FC<
  TrackTuningVisualisationProps
> = ({ tuning, width, highlightIndex }) => {
  let pcs = tuning?.pitchClasses ?? [];
  return (
    <Container>
      {pcs.map((pc, i) => (
        <Marker
          key={i}
          left={(getPitchCents(pc) / 1200) * width}
          isHighlighted={i === highlightIndex}
        />
      ))}
    </Container>
  );
};
