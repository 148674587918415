import React from "react";
import { CompoundNumberInput } from "../main/CompoundNumberInput";

import "./TimeSignatureControl.scss";
import { TimeSignatureDenominator } from "./types";

interface TimeSignatureControlProps {
  numerator: string;
  denominator: TimeSignatureDenominator;
  onUpdateNumerator: (newNumerator: string) => void;
  onUpdateDenominator: (newDenominator: TimeSignatureDenominator) => void;
}
export const TimeSignatureControl: React.FC<TimeSignatureControlProps> =
  React.memo(
    ({ numerator, denominator, onUpdateNumerator, onUpdateDenominator }) => {
      return (
        <div className="timeSignatureControl">
          <CompoundNumberInput
            className="input"
            value={numerator}
            onChange={onUpdateNumerator}
          />
          <select
            className="select"
            value={denominator}
            onChange={(evt) =>
              onUpdateDenominator(+evt.target.value as TimeSignatureDenominator)
            }
          >
            {[1, 2, 4, 8, 16].map((d) => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
            )
          </select>
        </div>
      );
    }
  );
