import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { IPlayer, PlayState, Tuning, TuningSubset } from "../types";
import { getPitchCents } from "../utils";

const Container = styled.div`
  position: absolute;
  height: 100%;
`;

type MarkerState = "active" | "inactiveInSubset" | "inactiveNotInSubset";

interface MarkerMoverProps {
  left: number;
}
const MarkerMover = styled.div<MarkerMoverProps>`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  transform: ${({ left }) => `translateX(${left}px)`};
`;

interface MarkerProps {
  state: MarkerState;
  isHighlighted?: boolean;
}
const Marker = styled.div<MarkerProps>(
  ({ state, isHighlighted }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background: ${
    state === "active" && !isHighlighted
      ? "var(--pitch-marker-trigger)"
      : state === "active" && isHighlighted
      ? "var(--pitch-marker-trigger)"
      : state !== "active" && isHighlighted
      ? "var(--pitch-marker-highlight)"
      : "transparent"
  };
  transition: background-color 0.05s;
  transform-origin: left;
`
);

const VelocityBar = styled.div<{ scale: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5ed8ff;
  transform-origin: left bottom;
  transform: scaleY(${(p) => p.scale});
`;

interface TrackTriggersVisualisationProps {
  trackId: string;
  tuning?: Tuning;
  subset?: TuningSubset;
  triggers: { index: number; velocity: number }[];
  playState: PlayState<IPlayer>;
  width: number;
  highlightIndex?: number | null;
}
export const TrackTriggersVisualisation: React.FC<
  TrackTriggersVisualisationProps
> = ({
  trackId,
  tuning,
  subset,
  triggers,
  playState,
  width,
  highlightIndex,
}) => {
  let pcs = tuning?.pitchClasses ?? [];
  let selectedTriggerIndexes = new Set(triggers.map((t) => t.index));

  let getMarkerState = (pcIndex: number): MarkerState => {
    let inSubset = subset?.degrees.find((d) => d.index === pcIndex);
    if (selectedTriggerIndexes.has(pcIndex)) {
      return "active";
    } else if (inSubset) {
      return "inactiveInSubset";
    } else {
      return "inactiveNotInSubset";
    }
  };

  let getVelocity = (pcIndex: number): number => {
    let trigger = triggers.find((t) => t.index === pcIndex);
    return trigger?.velocity ?? 70;
  };

  let refMap = useRef(new Map<number, HTMLDivElement | null>());
  useEffect(() => {
    if (playState.state === "playing") {
      let onTrigger = (
        triggeredTrackId: string,
        triggeredPcIndex: number,
        delay: number
      ) => {
        if (
          trackId === triggeredTrackId &&
          refMap.current.has(triggeredPcIndex)
        ) {
          let el = refMap.current.get(triggeredPcIndex);
          if (el) {
            el.style.willChange = "transform";
            let a = el.animate(
              [{ transform: "scaleX(6)" }, { transform: "scaleX(1)" }],
              {
                duration: 100,
                delay,
              }
            );
            a.finished.then(() => {
              if (el) el.style.willChange = "auto";
            });
          }
        }
      };
      playState.player.on("trigger", onTrigger);
      return () => {
        playState.player.off("trigger", onTrigger);
      };
    }
  }, [trackId, playState]);

  return (
    <Container>
      {pcs.map((pc, i) => (
        <MarkerMover key={i} left={(getPitchCents(pc) / 1200) * width}>
          <Marker
            ref={(el) => refMap.current.set(i, el)}
            state={getMarkerState(i)}
            isHighlighted={i === highlightIndex}
          >
            {getMarkerState(i) === "active" && (
              <VelocityBar scale={getVelocity(i) / 127} />
            )}
          </Marker>
        </MarkerMover>
      ))}
    </Container>
  );
};
