import React, { useEffect, useState } from "react";
import classNames from "classnames";
import WebMidi, { Output } from "webmidi";
import { includes, isEqual } from "lodash";

import { ToggleSwitch } from "./ToggleSwitch";

import "./SettingsDialog.scss";
import { VisualizerConnectionSettings } from "./types";
interface SettingsDialogProps {
  isOpen: boolean;
  midiClockOutputs: Output[];
  visualizerConnectionSettings: VisualizerConnectionSettings;
  onSetMidiClockOutputs: (newoutputs: Output[]) => void;
  onClose: () => void;
  onSetVisualizerConnectionSettings: (
    newSettings: VisualizerConnectionSettings
  ) => void;
}
export const SettingsDialog: React.FC<SettingsDialogProps> = ({
  isOpen,
  midiClockOutputs,
  visualizerConnectionSettings,
  onSetMidiClockOutputs,
  onSetVisualizerConnectionSettings,
  onClose,
}) => {
  let [midiState, setMidiState] = useState<"init" | "error" | "active">(
    WebMidi.enabled ? "active" : "init"
  );
  let [clockOutputOptions, setClockOutputOptions] = useState<Output[]>();

  useEffect(() => {
    if (midiState === "init") {
      WebMidi.enable((err) => {
        setMidiState(err ? "error" : "active");
      }, true);
    } else if (midiState === "active") {
      let updateOutputs = () => {
        setClockOutputOptions(Array.from(WebMidi.outputs));
      };
      updateOutputs();
      WebMidi.addListener("connected", updateOutputs);
      WebMidi.addListener("disconnected", updateOutputs);
      return () => {
        WebMidi.removeListener("connected", updateOutputs);
        WebMidi.removeListener("disconnected", updateOutputs);
      };
    }
  }, [midiState]);

  useEffect(() => {
    if (!clockOutputOptions) return;
    let availableOutputs = midiClockOutputs.filter((o) =>
      includes(clockOutputOptions, o)
    );
    if (!isEqual(availableOutputs, midiClockOutputs)) {
      onSetMidiClockOutputs(availableOutputs);
    }
  }, [clockOutputOptions, midiClockOutputs, onSetMidiClockOutputs]);

  return (
    <div
      className={classNames("settingsDialogWrapper", { isOpen })}
      onClick={(evt) => evt.target === evt.currentTarget && onClose()}
    >
      <div className="settingsDialog">
        <h2>Settings</h2>
        <div className="settingsDialog--content">
          <h3>MIDI Clock Out</h3>
          <p>Send MIDI clock to</p>
          {clockOutputOptions?.map((output) => (
            <div key={output.id}>
              <label>
                <ToggleSwitch
                  checked={includes(midiClockOutputs, output)}
                  onToggle={(on) =>
                    onSetMidiClockOutputs(
                      on
                        ? [...midiClockOutputs, output]
                        : midiClockOutputs.filter((o) => o !== output)
                    )
                  }
                />
                {output.name}
              </label>
            </div>
          ))}
          <h3>Visualiser Connection</h3>
          <div>
            <label>
              <ToggleSwitch
                checked={visualizerConnectionSettings.on}
                onToggle={(on) =>
                  onSetVisualizerConnectionSettings({
                    ...visualizerConnectionSettings,
                    on,
                  })
                }
              />
              Connect to visualiser WebSocket in URL
            </label>
          </div>
          <div>
            Host{" "}
            <input
              className="input"
              type="text"
              value={visualizerConnectionSettings.url}
              onChange={(evt) =>
                onSetVisualizerConnectionSettings({
                  ...visualizerConnectionSettings,
                  url: evt.target.value,
                })
              }
            />
          </div>
          <p>Connection state: {visualizerConnectionSettings.state}</p>
        </div>
        <div className="settingsDialog--actions">
          <button className="button isPrimary" onClick={onClose}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
