import {
  PitchClass,
  RatioPitchClass,
  Track,
  Tuning,
  TuningSubset,
} from "./types";

export function reduceIntoOctave(pitch: PitchClass): PitchClass {
  if (pitch.type === "ratio") {
    let upper = pitch.upper;
    let lower = pitch.lower;
    while (upper / lower >= 2) {
      lower *= 2;
    }
    while (upper / lower < 1) {
      upper *= 2;
    }
    return { ...pitch, upper, lower };
  } else {
    return { ...pitch, cents: pitch.cents % 1200 };
  }
}

export function reduceRatio(pitch: RatioPitchClass): RatioPitchClass {
  function gcd(a: number, b: number): number {
    return b ? gcd(b, a % b) : a;
  }
  let numerator = pitch.upper;
  let denominator = pitch.lower;
  let g = gcd(numerator, denominator);
  return {
    ...pitch,
    upper: numerator / g,
    lower: denominator / g,
  };
}

export function getPitchCents(pitch: PitchClass) {
  if (pitch.type === "ratio") {
    return ratioToCents(pitch.upper, pitch.lower);
  } else {
    return pitch.cents;
  }
}

export function ratioToCents(upper: number, lower: number) {
  return (1200 * Math.log(upper / lower)) / Math.log(2);
}

export function getPhaseRotation(tuning: Tuning, subset?: TuningSubset) {
  let phases = tuning.pitchClasses.map((pc) => getPitchCents(pc) / 1200);
  let rootDegree = subset?.degrees.find((d) => d.role === "tonic");
  let rootIndex = rootDegree?.index ?? 0;
  let rootPhase = phases[rootIndex];
  return rootPhase;
}

export function msToBPM(ms: number) {
  let wholeNoteDur = ms / 1000;
  let beatDur = wholeNoteDur / 4;
  let bpm = 60 / beatDur;
  return +bpm.toFixed(2);
}
export function bpmToMs(bpm: number) {
  let beatDur = 60 / bpm;
  let wholeNoteDur = beatDur * 4;
  let ms = wholeNoteDur * 1000;
  return Math.round(ms);
}

export function updateTrack(track: Track, index: number, allTracks: Track[]) {
  let originalTuning = track.tuning;
  if (index > 0 && track.follow.tuning) {
    let tuning = allTracks[0].tuning;
    let subset = track.follow.subset ? allTracks[0].subset : track.subset;
    if (!tuning || !subset || tuning.subsets.indexOf(subset) === -1) {
      subset = undefined;
    }
    track = { ...track, tuning, subset };
  }
  if (track.tuning !== originalTuning) {
    track = { ...track, triggers: [] };
  }
  let newTracks = [
    ...allTracks.slice(0, index),
    track,
    ...allTracks.slice(index + 1, allTracks.length),
  ];
  if (index === 0) {
    newTracks = newTracks.map((t) => {
      let tuning = t.follow.tuning ? track.tuning : t.tuning;
      let subset = t.follow.subset ? track.subset : t.subset;
      let triggers = tuning === t.tuning ? t.triggers : [];
      return {
        ...t,
        tuning,
        subset,
        triggers,
      };
    });
  }
  return newTracks;
}
