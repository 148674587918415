import React from "react";
import { MelodyVisualiser, MelodyVisualiserProps } from "./MelodyVisualiser";

type ShapedMelodyVisualiserProps = Omit<
  MelodyVisualiserProps,
  "drawShapeCurve" | "min" | "max"
>;

export const ShapedMelodyVisualiser: React.FC<ShapedMelodyVisualiserProps> = (
  props
) => {
  let min = props.controls.melodyShapeMin ?? { index: 0, octave: 2 };
  let max = props.controls.melodyShapeMax ?? { index: 0, octave: 5 };
  return <MelodyVisualiser {...props} drawShapeCurve min={min} max={max} />;
};
