import { EventEmitter } from "events";
import { isNumber, range } from "lodash";
import React, { useEffect, useRef } from "react";
import { Handles, Rail, Slider } from "react-compound-slider";
import classNames from "classnames";

import "./RangeSlider.scss";

interface RangeSliderProps {
  voiceCount: number;
  minValue: number;
  maxValue: number;
  minMinValue?: number;
  maxMaxValue?: number;
  step?: number;
  immediate?: boolean;
  disabled?: boolean;
  onUpdate: (minValue: number, maxValue: number) => void;
  events?: EventEmitter;
  noteEventValueKey?: string;
}
export const RangeSlider: React.FC<RangeSliderProps> = React.memo(
  ({
    voiceCount,
    minValue,
    maxValue,
    minMinValue = 0,
    maxMaxValue = 1,
    step = 0.01,
    immediate,
    disabled,
    onUpdate,
    events,
    noteEventValueKey,
  }) => {
    return (
      <Slider
        domain={[minMinValue, maxMaxValue]}
        step={step}
        values={[minValue, maxValue]}
        mode={2}
        disabled={disabled}
        onUpdate={(vals) => immediate && onUpdate(vals[0], vals[1])}
        onChange={(vals) => !immediate && onUpdate(vals[0], vals[1])}
        className={classNames("rangeSlider", { isDisabled: disabled })}
      >
        <Rail>
          {({ getRailProps }) => (
            <div className="rangeSlider--rail" {...getRailProps()} />
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              <div
                className="rangeSlider--activeRange"
                style={{
                  left: `${handles[0].percent}%`,
                  width: `${handles[1].percent - handles[0].percent + 2}%`,
                }}
              />
              {handles.map((handle) => (
                <div
                  key={handle.id}
                  className="rangeSlider--handle"
                  style={{ left: `${handle.percent}%` }}
                  {...getHandleProps(handle.id)}
                />
              ))}
              {range(0, voiceCount).map((voiceIdx) => (
                <RangeSliderValueMarker
                  key={voiceIdx}
                  voiceIdx={voiceIdx}
                  minMinValue={minMinValue}
                  maxMaxValue={maxMaxValue}
                  events={events}
                  noteEventValueKey={noteEventValueKey}
                />
              ))}
            </>
          )}
        </Handles>
      </Slider>
    );
  }
);

interface RangeSliderValueMarkerProps {
  voiceIdx: number;
  minMinValue?: number;
  maxMaxValue?: number;
  events?: EventEmitter;
  noteEventValueKey?: string;
}

const RangeSliderValueMarker: React.FC<RangeSliderValueMarkerProps> = ({
  voiceIdx,
  minMinValue = 0,
  maxMaxValue = 1,
  events,
  noteEventValueKey,
}) => {
  let valueMarkerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!events || !noteEventValueKey) return;
    let onNote = (note: any) => {
      if (note.voiceIdx === voiceIdx && isNumber(note[noteEventValueKey])) {
        let val = note[noteEventValueKey];
        let rel = (val - minMinValue) / (maxMaxValue - minMinValue);
        let marker = valueMarkerRef.current!;
        marker.style.left = `${rel * 100}%`;
        marker.classList.add("isVisible");
        setTimeout(() => {
          marker.classList.remove("isVisible");
        }, note.duration * 900);
      }
    };
    events.on("note", onNote);
    return () => {
      events.off("note", onNote);
    };
  }, [voiceIdx, events, noteEventValueKey, minMinValue, maxMaxValue]);

  return (
    <div className="rangeSlider--currentValueMarker" ref={valueMarkerRef} />
  );
};
