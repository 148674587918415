import React, { useRef, useEffect, useState } from "react";
import * as Nexus from "nexusui";
import classNames from "classnames";
import { EventEmitter } from "events";
import { isArray } from "lodash";

import "./WeightsControl.scss";
import { Weights } from "../main/core";
import { TrackVoice } from "./types";

interface WeightsControlProps {
  weightKeys: string[];
  weightLabels?: string[];
  weights: Weights;
  onUpdateWeights: (newWeights: Weights) => void;
  events?: EventEmitter;
  noteEventHighlightKey?: string;
  disabled?: boolean;
}
export const WeightsControl: React.FC<WeightsControlProps> = React.memo(
  ({
    weightKeys,
    weightLabels,
    weights,
    onUpdateWeights,
    events,
    noteEventHighlightKey,
    disabled,
  }) => {
    let slidersContainerRef = useRef<HTMLDivElement>(null);
    let [sliders, setSliders] = useState<any>(null);

    useEffect(() => {
      let sliders = new Nexus.Multislider(slidersContainerRef.current, {
        size: [slidersContainerRef.current!.offsetWidth, 70],
        numberOfSliders: weightKeys.length,
        min: 0,
        max: 1,
      });
      if (!disabled) {
        sliders.on("change", (evt: number[]) => {
          if (!isArray(evt)) return;
          onUpdateWeights(
            Object.fromEntries(evt.map((v, idx) => [weightKeys[idx], v]))
          );
        });
      }
      setSliders(sliders);
      return () => {
        sliders.destroy();
        setSliders(null);
      };
    }, [weightKeys, disabled, onUpdateWeights]);

    useEffect(() => {
      if (!weights || !sliders) return;
      sliders.setAllSliders(weightKeys.map((k) => weights[k]));
    }, [weights, sliders, weightKeys]);

    return (
      <div className={classNames("weightsControl", { isDisabled: disabled })}>
        <div
          className="weightsControl--sliders"
          ref={slidersContainerRef}
        ></div>
        <div className="weightsControl--labels">
          {weightKeys.map((k, idx) => (
            <WeightsControlLabel
              key={k}
              weightKey={k}
              label={weightLabels?.[idx] ?? k}
              events={events}
              noteEventHighlightKey={noteEventHighlightKey}
            />
          ))}
        </div>
      </div>
    );
  }
);

interface WeightsControlLabelProps {
  weightKey: string;
  label: string;
  events?: EventEmitter;
  noteEventHighlightKey?: string;
}
const WeightsControlLabel: React.FC<WeightsControlLabelProps> = ({
  weightKey,
  label,
  events,
  noteEventHighlightKey,
}) => {
  let labelRef = useRef<HTMLDivElement>(null);
  let onForVoices = useRef(new Set<TrackVoice>());
  useEffect(() => {
    if (!events || !noteEventHighlightKey) return;
    let onNote = (note: any) => {
      let labelEl = labelRef.current;
      if (!labelEl) return;
      let val = "" + note[noteEventHighlightKey];
      let voice = note.voiceIdx;
      if (val === weightKey && !onForVoices.current.has(voice)) {
        onForVoices.current.add(voice);
      } else if (val !== weightKey && onForVoices.current.has(voice)) {
        onForVoices.current.delete(voice);
      }
      if (
        onForVoices.current.size > 0 &&
        !labelEl.classList.contains("isPlaying")
      ) {
        labelEl.classList.add("isPlaying");
        setTimeout(() => {
          onForVoices.current.delete(voice);
          if (onForVoices.current.size === 0)
            labelEl?.classList.remove("isPlaying");
        }, note.duration * 900);
      } else if (
        onForVoices.current.size === 0 &&
        labelEl.classList.contains("isPlaying")
      ) {
        labelEl.classList.remove("isPlaying");
      }
    };
    events.on("note", onNote);
    return () => {
      events.off("note", onNote);
    };
  }, [events, noteEventHighlightKey, weightKey]);
  return (
    <div
      ref={labelRef}
      className={classNames("weightsControl--label", {
        isRatio: label.indexOf("/") >= 0,
      })}
    >
      {label.indexOf("/") < 0 ? (
        label
      ) : (
        <>
          <span className="weightsControl--label-upper">
            {label.substring(0, label.indexOf("/"))}
          </span>
          <span className="weightsControl--label-lower">
            {label.substring(label.indexOf("/") + 1)}
          </span>
        </>
      )}
    </div>
  );
};
