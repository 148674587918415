import React, { useEffect, useRef } from "react";
import { EventEmitter } from "events";
import { isNumber } from "lodash";

import "./MelodicIntervalToggle.scss";
import { ToggleButton } from "./ToggleButton";
import { TrackVoice } from "./types";

interface MelodicIntervalToggleProps {
  interval: number | "8ve";
  on: boolean;
  disabled?: boolean;
  onToggle: (on: boolean) => void;
  events: EventEmitter;
}
export const MelodicIntervalToggle: React.FC<MelodicIntervalToggleProps> =
  React.memo(({ interval, on, disabled, onToggle, events }) => {
    let labelRef = useRef<HTMLSpanElement>(null);
    let onForVoices = useRef(new Set<TrackVoice>());
    useEffect(() => {
      let onNote = (note: any) => {
        let labelEl = labelRef.current!;
        let voice = note.voiceIdx;
        if (note.interval === interval && !onForVoices.current.has(voice)) {
          onForVoices.current.add(voice);
        } else if (
          note.interval !== interval &&
          onForVoices.current.has(voice)
        ) {
          onForVoices.current.delete(voice);
        }
        if (
          onForVoices.current.size > 0 &&
          !labelEl.classList.contains("isPlaying")
        ) {
          labelEl.classList.add("isPlaying");
          setTimeout(() => {
            onForVoices.current.delete(voice);
            if (onForVoices.current.size === 0)
              labelEl.classList.remove("isPlaying");
          }, note.duration * 900);
        } else if (
          onForVoices.current.size === 0 &&
          labelEl.classList.contains("isPlaying")
        ) {
          labelEl.classList.remove("isPlaying");
        }
      };
      events.on("note", onNote);
      return () => {
        events.off("note", onNote);
      };
    }, [events, interval]);
    return (
      <label className="melodicIntervalToggle">
        <ToggleButton checked={on} disabled={disabled} onToggle={onToggle} />
        <span className="melodicIntervalToggle--label" ref={labelRef}>
          {isNumber(interval) ? interval : interval}
        </span>
      </label>
    );
  });
