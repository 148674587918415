import { isNumber } from "lodash";
import { dbToGain, immediate, getTransport } from "tone";
import webmidi from "webmidi";
import { MIDIOutput } from "../main/core";
import {
  BEAT_DIVISION_TICKS,
  MAX_BASIC_SYNTH_FILTER_FREQUENCY,
  MAX_BASIC_SYNTH_FILTER_RESONANCE,
  MAX_VOLUME_DB,
  MIN_BASIC_SYNTH_FILTER_FREQUENCY,
  MIN_BASIC_SYNTH_FILTER_RESONANCE,
  MIN_VOLUME_DB,
} from "./constants";
import { LFOState, TrackState, getTrackPhase } from "./apotomeController";
import { ParamModulation, TrackControls, TrackLFO } from "./types";
import { ApotomePlayer } from "./ApotomePlayer";
import { MIDI_CHANNEL_MPE_CONTROL } from "../constants";
import { MIDIOutputManager } from "./MIDIOutputManager";

let lfoTicksPerStep = getTransport().toTicks("16n");
let lfoEventsPerStep = 16;
let lfoTicks = 0;
export function runLFOTick(
  tracks: Map<string, TrackState>,
  player: ApotomePlayer,
  midiOutputs: { [trackId: string]: MIDIOutput },
  midiOutputMgr: MIDIOutputManager,
  time: number
) {
  if (isAnyLFOOn(tracks)) {
    let secondsPerEvent = getTransport().toSeconds("16n") / lfoEventsPerStep;
    tracks.forEach((track, trackId) => {
      runLFOModulations(
        track,
        secondsPerEvent,
        trackId,
        player,
        time,
        midiOutputs,
        midiOutputMgr
      );
      runMelodyShapeLFOs(trackId, track, secondsPerEvent, time);
    });
  }
  lfoTicks += lfoTicksPerStep;
}

function isAnyLFOOn(tracks: Map<string, TrackState>) {
  for (let track of tracks.values()) {
    let anyOn =
      track.controls.lfos.some((l) => l.on) ||
      track.controls.melodyType === "shaped";
    if (anyOn) return true;
  }
  return false;
}

function runLFOModulations(
  track: TrackState,
  secondsPerEvent: number,
  trackId: string,
  player: ApotomePlayer,
  time: number,
  midiOutputs: { [trackId: string]: MIDIOutput },
  midiOutputMgr: MIDIOutputManager
) {
  for (let lfoIdx = 0; lfoIdx < track.controls.lfos.length; lfoIdx++) {
    let lfoCtrl = track.controls.lfos[lfoIdx];
    let lfoState = track.lfoStates[lfoIdx];
    if (lfoCtrl.on) {
      if (!isNumber(lfoState.triggeredOnTick)) {
        lfoState.triggeredOnTick = lfoTicks;
      }

      if (track.controls.instrument === "basicSynth") {
        if (lfoState.lastTargets.basicSynth !== lfoCtrl.targets.basicSynth) {
          if (lfoState.lastTargets.basicSynth >= 10000) {
            resetTrackLFO(
              lfoState.lastTargets.basicSynth,
              trackId,
              track,
              player,
              time
            );
          } else if (lfoState.lastTargets.basicSynth === 0) {
            player.setTrackBasicSynthFilterFrequency(
              trackId,
              track.controls.filterFrequency,
              time
            );
          } else if (lfoState.lastTargets.basicSynth === 1) {
            player.setTrackBasicSynthFilterQ(
              trackId,
              track.controls.filterResonance,
              time
            );
          }
          lfoState.lastTargets.basicSynth = lfoCtrl.targets.basicSynth;
        }
      } else if (track.controls.instrument === "string") {
        if (lfoState.lastTargets.string !== lfoCtrl.targets.string) {
          if (lfoState.lastTargets.string >= 10000) {
            resetTrackLFO(
              lfoState.lastTargets.string,
              trackId,
              track,
              player,
              time
            );
          }
          lfoState.lastTargets.string = lfoCtrl.targets.string;
        }
      } else if (track.controls.instrument === "obxd") {
        if (lfoState.lastTargets.obxd !== lfoCtrl.targets.obxd) {
          if (lfoState.lastTargets.obxd >= 10000) {
            resetTrackLFO(
              lfoState.lastTargets.obxd,
              trackId,
              track,
              player,
              time
            );
          } else {
            player.resetOBXDParamModulation(
              trackId,
              lfoState.lastTargets.obxd,
              time
            );
          }
          lfoState.lastTargets.obxd = lfoCtrl.targets.obxd;
        }
      } else if (track.controls.instrument === "dexed") {
        if (lfoState.lastTargets.dexed !== lfoCtrl.targets.dexed) {
          if (lfoState.lastTargets.dexed >= 10000) {
            resetTrackLFO(
              lfoState.lastTargets.dexed,
              trackId,
              track,
              player,
              time
            );
          } else {
            player.resetDEXEDParamModulation(
              trackId,
              lfoState.lastTargets.dexed,
              time
            );
          }
          lfoState.lastTargets.dexed = lfoCtrl.targets.dexed;
        }
      } else if (track.controls.instrument === "yoshimi") {
        if (lfoState.lastTargets.yoshimi !== lfoCtrl.targets.yoshimi) {
          if (lfoState.lastTargets.yoshimi >= 10000) {
            resetTrackLFO(
              lfoState.lastTargets.yoshimi,
              trackId,
              track,
              player,
              time
            );
          }
          lfoState.lastTargets.yoshimi = lfoCtrl.targets.yoshimi;
        }
      } else if (track.controls.instrument === "midi") {
        if (lfoState.lastTargets.midi !== lfoCtrl.targets.midi) {
          if (lfoState.lastTargets.midi >= 10000) {
            resetTrackLFO(
              lfoState.lastTargets.midi,
              trackId,
              track,
              player,
              time
            );
          }
          lfoState.lastTargets.midi = lfoCtrl.targets.midi;
        }
      }

      let trackGainModulations: ParamModulation[] = [];
      let trackPanModulations: ParamModulation[] = [];
      let trackSend1GainModulations: ParamModulation[] = [];
      let trackSend2GainModulations: ParamModulation[] = [];
      let basicSynthFilterFrequencyModulations: ParamModulation[] = [];
      let basicSynthFilterQModulations: ParamModulation[] = [];
      let obxdModulations: ParamModulation[] = [];
      let dexedModulations: ParamModulation[] = [];

      let applyTrackLFO = (target: number, modNorm: number, time: number) => {
        if (
          target === 10000 &&
          !track.controls.muted &&
          track.controls.soloStatus !== "other"
        ) {
          // Volume
          let targetVol = modulateParam(
            track.controls.volume,
            MIN_VOLUME_DB,
            MAX_VOLUME_DB,
            modNorm
          );
          trackGainModulations.push({
            atTime: time,
            value: dbToGain(targetVol),
          });
        } else if (target === 10001) {
          // Pan
          let targetPan = modulateParam(track.controls.pan, -1, 1, modNorm);
          trackPanModulations.push({ atTime: time, value: targetPan });
        } else if (target === 10002) {
          // Send 1
          let targetSend = modulateParam(
            track.controls.send1Gain,
            0,
            1,
            modNorm
          );
          trackSend1GainModulations.push({ atTime: time, value: targetSend });
        } else if (target === 10003) {
          // Send 2
          let targetSend = modulateParam(
            track.controls.send2Gain,
            0,
            1,
            modNorm
          );
          trackSend2GainModulations.push({ atTime: time, value: targetSend });
        }
      };

      for (let event = 0; event < lfoEventsPerStep; event++) {
        let eventTime = time + secondsPerEvent * event;
        let eventControl = getLFOModulation(
          event,
          eventTime,
          secondsPerEvent,
          lfoState,
          lfoCtrl
        );

        let amplitude = lfoCtrl.amount / 128;
        let modNorm =
          lfoCtrl.amount > 0 ? (eventControl / 128 - amplitude / 2) * 2 : 0;

        if (track.controls.instrument === "basicSynth") {
          if (lfoCtrl.targets.basicSynth >= 10000) {
            applyTrackLFO(lfoCtrl.targets.basicSynth, modNorm, eventTime);
          } else {
            if (lfoCtrl.targets.basicSynth === 0) {
              let targetFreq = modulateParam(
                track.controls.filterFrequency,
                MIN_BASIC_SYNTH_FILTER_FREQUENCY,
                MAX_BASIC_SYNTH_FILTER_FREQUENCY,
                modNorm
              );
              basicSynthFilterFrequencyModulations.push({
                atTime: eventTime,
                value: targetFreq,
              });
            } else if (lfoCtrl.targets.basicSynth === 1) {
              let targetRes = modulateParam(
                track.controls.filterResonance,
                MIN_BASIC_SYNTH_FILTER_RESONANCE,
                MAX_BASIC_SYNTH_FILTER_RESONANCE,
                modNorm
              );
              basicSynthFilterQModulations.push({
                atTime: eventTime,
                value: targetRes,
              });
            }
          }
        } else if (track.controls.instrument === "string") {
          if (lfoCtrl.targets.string >= 10000) {
            applyTrackLFO(lfoCtrl.targets.string, modNorm, eventTime);
          }
        } else if (track.controls.instrument === "obxd") {
          if (lfoCtrl.targets.obxd >= 10000) {
            applyTrackLFO(lfoCtrl.targets.obxd, modNorm, eventTime);
          } else {
            obxdModulations.push({ value: modNorm, atTime: eventTime });
          }
        } else if (track.controls.instrument === "dexed") {
          if (lfoCtrl.targets.dexed >= 10000) {
            applyTrackLFO(lfoCtrl.targets.dexed, modNorm, eventTime);
          } else {
            dexedModulations.push({ value: modNorm, atTime: eventTime });
          }
        } else if (track.controls.instrument === "yoshimi") {
          if (lfoCtrl.targets.yoshimi >= 10000) {
            applyTrackLFO(lfoCtrl.targets.yoshimi, modNorm, eventTime);
          }
        } else if (track.controls.instrument === "midi") {
          if (lfoCtrl.targets.midi >= 10000) {
            applyTrackLFO(lfoCtrl.targets.midi, modNorm, eventTime);
          } else {
            let output = midiOutputs[trackId];
            if (output && output.output && lfoCtrl.targets.midi >= 0) {
              let eventTimeFromNow = eventTime - immediate();
              let eventMidiTime = webmidi.time + eventTimeFromNow * 1000;
              let channel =
                output.channel === "mpe"
                  ? MIDI_CHANNEL_MPE_CONTROL
                  : output.channel;
              midiOutputMgr.sendController(
                output.output,
                lfoCtrl.targets.midi,
                eventControl,
                channel,
                eventMidiTime
              );
            }
          }
        }
      }

      if (trackGainModulations.length > 0) {
        player.modulateTrackGain(trackId, trackGainModulations);
      }
      if (trackPanModulations.length > 0) {
        player.modulateTrackPan(trackId, trackPanModulations);
      }
      if (trackSend1GainModulations.length > 0) {
        player.modulateTrackSend1Gain(trackId, trackSend1GainModulations);
      }
      if (trackSend2GainModulations.length > 0) {
        player.modulateTrackSend2Gain(trackId, trackSend2GainModulations);
      }
      if (basicSynthFilterFrequencyModulations.length > 0) {
        player.modulateTrackBasicSynthFilterFrequency(
          trackId,
          basicSynthFilterFrequencyModulations
        );
      }
      if (basicSynthFilterQModulations.length > 0) {
        player.modulateTrackBasicSynthFilterQ(
          trackId,
          basicSynthFilterQModulations
        );
      }
      if (obxdModulations.length > 0) {
        player.modulateOBXDParam(
          trackId,
          lfoCtrl.targets.obxd,
          obxdModulations
        );
      }
      if (dexedModulations.length > 0) {
        player.modulateDEXEDParam(
          trackId,
          lfoCtrl.targets.dexed,
          dexedModulations
        );
      }
    } else {
      if (isNumber(lfoState.triggeredOnTick)) {
        if (track.controls.instrument === "basicSynth") {
          if (lfoCtrl.targets.basicSynth >= 10000) {
            resetTrackLFO(
              lfoCtrl.targets.basicSynth,
              trackId,
              track,
              player,
              time
            );
          } else if (lfoCtrl.targets.basicSynth === 0) {
            player.setTrackBasicSynthFilterFrequency(
              trackId,
              track.controls.filterFrequency,
              time
            );
          } else if (lfoCtrl.targets.basicSynth === 1) {
            player.setTrackBasicSynthFilterQ(
              trackId,
              track.controls.filterResonance,
              time
            );
          }
        } else if (track.controls.instrument === "string") {
          if (lfoCtrl.targets.string >= 10000) {
            resetTrackLFO(lfoCtrl.targets.string, trackId, track, player, time);
          }
        } else if (track.controls.instrument === "obxd") {
          if (lfoCtrl.targets.obxd >= 10000) {
            resetTrackLFO(lfoCtrl.targets.obxd, trackId, track, player, time);
          } else {
            player.resetOBXDParamModulation(
              trackId,
              lfoCtrl.targets.obxd,
              time
            );
          }
          lfoState.lastTargets.obxd = -1;
        } else if (track.controls.instrument === "dexed") {
          if (lfoCtrl.targets.dexed >= 10000) {
            resetTrackLFO(lfoCtrl.targets.dexed, trackId, track, player, time);
          } else {
            player.resetDEXEDParamModulation(
              trackId,
              lfoCtrl.targets.dexed,
              time
            );
            lfoState.lastTargets.dexed = -1;
          }
        } else if (track.controls.instrument === "yoshimi") {
          if (lfoCtrl.targets.yoshimi >= 10000) {
            resetTrackLFO(
              lfoCtrl.targets.yoshimi,
              trackId,
              track,
              player,
              time
            );
          }
        } else if (track.controls.instrument === "midi") {
          if (lfoCtrl.targets.midi >= 10000) {
            resetTrackLFO(lfoCtrl.targets.midi, trackId, track, player, time);
          }
        }
        lfoState.triggeredOnTick = null;
      }
    }
  }
}

function runMelodyShapeLFOs(
  trackId: string,
  track: TrackState,
  secondsPerEvent: number,
  time: number
) {
  let totalLfoAmplitude = track.controls.melodyShapeLfos.reduce(
    (sum, l) => sum + (l.on ? l.amount / 128 : 0),
    0
  );

  for (let event = 0; event < lfoEventsPerStep; event++) {
    let eventTime = time + secondsPerEvent * event;
    let eventModRelative = 0.5;
    if (totalLfoAmplitude !== 0) {
      let eventModSum = 0;
      for (
        let lfoIdx = 0;
        lfoIdx < (track.controls.melodyShapeLfos ?? []).length;
        lfoIdx++
      ) {
        let lfoCtrl = track.controls.melodyShapeLfos[lfoIdx];
        let lfoState = track.melodyShapeLfoStates[lfoIdx];
        if (lfoCtrl.on) {
          if (lfoCtrl.retrigger) {
            // Retrigger when track phase wraps around
            let eventTickGlobal =
              lfoTicks + (event / lfoEventsPerStep) * lfoTicksPerStep;
            let phase = getTrackPhase(trackId, eventTickGlobal);
            if (phase < lfoState.lastPhase) {
              lfoState.pendingRetriggerTimes.push(eventTime);
              track.events.emit("lfoRetrigger", {
                time: eventTime,
                lfo: lfoIdx,
              });
            }
            lfoState.lastPhase = phase;
          }
          let eventControl = getLFOModulation(
            event,
            eventTime,
            secondsPerEvent,
            lfoState,
            lfoCtrl
          );
          eventModSum += eventControl / 128; // 0..1
        }
      }
      eventModRelative = eventModSum / totalLfoAmplitude; // 0..1
    }
    track.melodyShapeLfoCurve.push(
      getMelodyCurveValue(eventTime, eventModRelative, track.controls)
    );
  }

  let dropCurveUntilIdx = track.melodyShapeLfoCurve.findIndex(
    (c) => c.time >= time - 5
  );
  if (dropCurveUntilIdx > 0) {
    track.melodyShapeLfoCurve.splice(0, dropCurveUntilIdx);
  }
}

export function getMelodyCurveValue(
  time: number,
  modRelative: number,
  controls: TrackControls
) {
  let melodyMin = controls.melodyShapeMin ?? { octave: 2, index: 0 };
  let melodyMax = controls.melodyShapeMax ?? { octave: 5, index: 0 };
  let numScaleDegrees = controls.scale?.scaleDegrees.length ?? 0;
  let melodyRange =
    (melodyMax.octave - melodyMin.octave) * numScaleDegrees -
    melodyMin.index +
    melodyMax.index;

  let melodyShapeValue = modRelative * melodyRange;
  let octave =
    Math.floor((melodyShapeValue + melodyMin.index) / numScaleDegrees) +
    melodyMin.octave;
  let indexFraction = (melodyShapeValue + melodyMin.index) % numScaleDegrees;
  return {
    time,
    octave,
    indexFraction,
    raw: modRelative,
  };
}

function getLFOModulation(
  eventNumber: number,
  time: number,
  secondsPerEvent: number,
  lfoState: LFOState,
  lfoCtrl: TrackLFO
) {
  let eventTickGlobal =
    lfoTicks + (eventNumber / lfoEventsPerStep) * lfoTicksPerStep;
  while (
    lfoState.pendingRetriggerTimes.length > 0 &&
    lfoState.pendingRetriggerTimes[0] <= time
  ) {
    lfoState.pendingRetriggerTimes.shift();
    retriggerTrackLFO(lfoState, lfoCtrl, eventTickGlobal);
  }
  let eventTick = eventTickGlobal - lfoState.triggeredOnTick!;
  let rateTicks = 0;
  if (lfoCtrl.synced || !("synced" in lfoCtrl)) {
    // the latter case is for legacy snapshot tracks
    rateTicks = BEAT_DIVISION_TICKS[lfoCtrl.rate];
  } else {
    let rateHz = lfoCtrl.unsyncedRate ?? 0.1;
    let eventHz = 1 / secondsPerEvent;
    let ticksPerEvent = lfoTicksPerStep / lfoEventsPerStep;
    rateTicks = Math.round((eventHz / rateHz) * ticksPerEvent);
  }
  let phase = lfoCtrl.phase ?? 0;
  if (lfoCtrl.shape === "sine") {
    let periodRad = (eventTick / rateTicks + phase) * Math.PI * 2;
    let periodAngle = Math.sin(periodRad);
    return Math.round(lfoCtrl.amount * (periodAngle / 2 + 0.5));
  } else if (lfoCtrl.shape === "square") {
    let ticksInPeriod = (eventTick + phase * rateTicks) % rateTicks;
    return ticksInPeriod < rateTicks / 2 ? 0 : lfoCtrl.amount;
  } else if (lfoCtrl.shape === "rampUp") {
    let ticksInPeriod = (eventTick + phase * rateTicks) % rateTicks;
    return lfoCtrl.amount * (ticksInPeriod / rateTicks);
  } else if (lfoCtrl.shape === "rampDown") {
    let ticksInPeriod = (eventTick + phase * rateTicks) % rateTicks;
    return lfoCtrl.amount * (1 - ticksInPeriod / rateTicks);
  } else if (lfoCtrl.shape === "random") {
    let value = lfoState.randomValue;
    if (
      !isNumber(value) ||
      lfoState.nextRandomValueAtTick! <= eventTickGlobal
    ) {
      value = Math.random() * lfoCtrl.amount;
      lfoState.randomValue = value;
      lfoState.nextRandomValueAtTick =
        eventTickGlobal + rateTicks * (Math.random() / 2 + 1);
    }
    return value;
  } else {
    return 0;
  }
}

function retriggerTrackLFO(state: LFOState, ctrl: TrackLFO, atTick: number) {
  state.triggeredOnTick = atTick;
  if (ctrl.shape === "random") {
    state.nextRandomValueAtTick = atTick;
  }
}

function resetTrackLFO(
  target: number,
  trackId: string,
  track: TrackState,
  player: ApotomePlayer,
  time: number
) {
  if (
    target === 10000 &&
    !track.controls.muted &&
    track.controls.soloStatus !== "other"
  ) {
    // Volume
    player.setTrackGain(trackId, dbToGain(track.controls.volume), time);
  } else if (target === 10001) {
    // Pan
    player.setTrackPan(trackId, track.controls.pan, time);
  } else if (target === 10002) {
    // Send 1
    player.setTrackSend1Gain(trackId, track.controls.send1Gain, time);
  } else if (target === 10003) {
    // Send 2
    player.setTrackSend2Gain(trackId, track.controls.send2Gain, time);
  }
}

function modulateParam(
  base: number,
  min: number,
  max: number,
  modNorm: number
) {
  let mod = (max - min) * modNorm;
  let target = Math.max(min, Math.min(max, base + mod));
  return target;
}
