import { flatMap, isNumber, sortBy } from "lodash";
import { useRef } from "react";

import {
  Scale,
  TuningSystem,
  getPitchSum,
  reduceIntoOctave,
} from "../main/core";
import { TrackControls } from "./types";
import {
  CycleCallback,
  IPlayer,
  PitchClass,
  PlayState,
  Track,
  TriggerCallback,
  TuningSubsetDegree,
  getPitchCents,
} from "adwar-shared";
import { EventEmitter } from "events";
import { getTrackPhase } from "./apotomeController";

export function getPhasing(controls: TrackControls) {
  let { tuningSystem, scale } = getEffectiveAdwarTuning(controls);
  if (tuningSystem && scale) {
    let { pcs, pcIdPcIdxMap } = getAdwarPCs(tuningSystem);
    let subsetDegrees = getAdwarSubsetDegrees(scale, pcIdPcIdxMap);
    let phases = pcs.map((pc) => getPitchCents(pc) / 1200);

    let rootDegree = subsetDegrees.find((d) => d.role === "tonic");
    let phaseRotation = 0;
    if (rootDegree) {
      phaseRotation = phases[rootDegree.index];
    }
    let rotatedPhases = phases.map((p) => {
      let rotated = p - phaseRotation;
      return rotated < 0 ? rotated + 1 : rotated;
    });

    let triggers = (controls.beatDivisionAdwarTriggers ?? []).map((d) => ({
      ...d,
      phase: rotatedPhases[d.index],
    }));
    triggers = sortBy(triggers, (t) => t.phase);

    return {
      pcs,
      subsetDegrees,
      triggers,
      phaseRotation,
    };
  } else {
    return {
      pcs: [],
      subsetDegrees: [],
      triggers: [],
      phaseRotation: 0,
    };
  }
}

function getEffectiveAdwarTuning(controls: TrackControls) {
  let tuningSystem: TuningSystem | undefined, scale: Scale | undefined;
  if (controls.beatDivisionAdwarTuningSystem) {
    tuningSystem = controls.beatDivisionAdwarTuningSystem;
    scale = controls.beatDivisionAdwarScale;
  } else {
    tuningSystem = controls.tuningSystem;
    scale = controls.scale;
  }
  return { tuningSystem, scale };
}

function getAdwarPCs(tuningSystem: TuningSystem): {
  pcs: PitchClass[];
  pcIdPcIdxMap: Map<string, number>;
} {
  let pcIdPcIdxMap = new Map<string, number>();
  let result: PitchClass[] = [];
  for (
    let stringIdx = 0;
    stringIdx < tuningSystem.strings.length;
    stringIdx++
  ) {
    let string = tuningSystem.strings[stringIdx];
    for (let pcIdx = 0; pcIdx < string.pitchClasses.length; pcIdx++) {
      let reduction = reduceIntoOctave(
        getPitchSum(string, string.pitchClasses[pcIdx])
      );
      let pc: PitchClass =
        isNumber(reduction.ratioUpper) && isNumber(reduction.ratioLower)
          ? {
              type: "ratio" as "ratio",
              upper: reduction.ratioUpper,
              lower: reduction.ratioLower,
            }
          : {
              type: "cents" as "cents",
              cents: reduction.cents!,
            };
      let existingIdx = result.findIndex(
        (e) => getPitchCents(e) === getPitchCents(pc)
      );
      if (existingIdx >= 0) {
        pcIdPcIdxMap.set(`${stringIdx}-${pcIdx}`, existingIdx);
      } else {
        result.push(pc);
        pcIdPcIdxMap.set(`${stringIdx}-${pcIdx}`, result.length - 1);
      }
    }
  }
  return {
    pcs: result,
    pcIdPcIdxMap,
  };
}

function getAdwarSubsetDegrees(
  scale: Scale,
  pcIdPcIdxMap: Map<string, number>
): TuningSubsetDegree[] {
  return scale.scaleDegrees
    .map((d) => {
      let index = pcIdPcIdxMap.get(`${d.stringIndex}-${d.pitchClassIndex}`);
      return {
        index,
        role: d.role ?? "none",
      };
    })
    .filter((d): d is TuningSubsetDegree => isNumber(d.index));
}

export function useAdwarUIAdapter(
  trackId: string,
  controls: TrackControls,
  events: EventEmitter
) {
  let cbsRef = useRef<
    Map<
      TriggerCallback | CycleCallback,
      (noteData: { [k: string]: any }) => void
    >
  >(new Map());

  let { tuningSystem, scale } = getEffectiveAdwarTuning(controls);

  if (!tuningSystem || !scale)
    return {
      adwarTrack: null,
      playState: null,
    };

  let { pcs, subsetDegrees, triggers, phaseRotation } = getPhasing(controls);

  let adwarTrack: Track = {
    id: trackId,
    follow: { tuning: false, subset: false },
    tuning: {
      id: tuningSystem.id!,
      name: tuningSystem.name,
      category: tuningSystem.category,
      pitchClasses: pcs,
      subsets: [],
    },
    subset: {
      id: scale.id!,
      name: scale.name,
      degrees: subsetDegrees,
    },
    triggers,
    output: { type: "none" },
  };

  let player: IPlayer = {
    on(evt: "trigger" | "cycle", cb: TriggerCallback | CycleCallback) {
      if (evt === "trigger") {
        let noteCb = (note: { [k: string]: any }) => {
          if (isNumber(note.beatDivision)) {
            let trigger = triggers.find((t) => t.phase === note.beatDivision);
            if (trigger) cb(trackId, trigger.index, 0);
          }
        };
        events.on("note", noteCb);
        cbsRef.current.set(cb, noteCb);
      }
    },
    off(_evt: "trigger" | "cycle", cb: TriggerCallback | CycleCallback) {
      let noteCb = cbsRef.current.get(cb);
      if (noteCb) events.off("note", noteCb);
    },
    getTrackPhase(trackId: string) {
      let trackPhase = getTrackPhase(trackId);
      trackPhase += phaseRotation;
      while (trackPhase < 0) trackPhase += 1;
      while (trackPhase > 1) trackPhase -= 1;
      return trackPhase;
    },
  };

  let playState: PlayState<IPlayer> = controls.started
    ? {
        state: "playing",
        player,
      }
    : {
        state: "notPlaying",
        phase: 0,
      };

  return { adwarTrack, playState };
}
