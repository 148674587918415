import React from "react";
import classNames from "classnames";

import { SessionHeader } from "../types";

import "./SubmitConfirmationDialog.scss";

interface SubmitConfirmationDialogProps {
  isOpenFor?: SessionHeader;
  onConfirm: () => void;
  onCancel: () => void;
}
export const SubmitConfirmationDialog: React.FC<SubmitConfirmationDialogProps> =
  React.memo(({ isOpenFor, onConfirm, onCancel }) => {
    return (
      <div
        className={classNames("submitConfirmationDialogWrapper", {
          isOpen: !!isOpenFor,
        })}
        onClick={(evt) => evt.target === evt.currentTarget && onCancel()}
      >
        <div className="submitConfirmationDialog">
          <h2>Submit {isOpenFor?.name} to community</h2>
          <p>
            After being accepted, this session will be available for others to
            play, and your username will be listed as the author.
          </p>
          <p>
            The session will be also played in the{" "}
            <a
              href="https://ctm.isartum.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              Apotome livestream website
            </a>{" "}
            for CTM Festival 2021, as well as in CTM Cyberia.
          </p>{" "}
          <div className="submitConfirmationDialog--actions">
            <button className="button" onClick={onCancel}>
              Cancel
            </button>
            <button className="button" onClick={onConfirm}>
              Publish
            </button>
          </div>
        </div>
      </div>
    );
  });
