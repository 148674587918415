import React, { useEffect, useState } from "react";

import * as serviceWorker from "./serviceWorkerRegistration";

import "./ServiceWorker.scss";
import classNames from "classnames";

export const ServiceWorker: React.FC = () => {
  let [showReload, setShowReload] = useState(false);
  let [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setWaitingWorker(registration.waiting);
        setShowReload(true);
      },
    });
  }, []);

  let onReload = () => {
    if (!waitingWorker) return;
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  return (
    <div className={classNames("serviceWorker", { isVisible: showReload })}>
      There is a new version of Leimma & Apotome available.{" "}
      <button className="button" onClick={onReload}>
        Reload
      </button>
    </div>
  );
};
