import React from "react";
import styled from "@emotion/styled";
import { PitchClass, Tuning, TuningSubset, TuningSubsetDegree } from "../types";
import { getPitchCents } from "../utils";

const Container = styled.div<{
  trackOpen: boolean;
}>(
  ({ trackOpen }) => `
  position: absolute;
  height: 100%;
  pointer-events: ${trackOpen ? "auto" : "none"};
  `
);

interface MarkerProps {
  left: number;
  isHighlighted?: boolean;
}
const Marker = styled.div<MarkerProps>(
  ({ left, isHighlighted }) => `
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  transform: translateX(${left ? left : 0}px);
  box-sizing: border-box;
  border-left: 1px solid ${
    isHighlighted ? "var(--white)" : "var(--pitch-marker)"
  };
  transition: border-color 0.05s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`
);

let RoleLabel = styled.div<{
  roleType: string;
  left: number;
  isHighlighted?: boolean;
}>(
  ({ roleType, left, isHighlighted }) => `
  position: absolute;
  z-index: 997;
  top: 50%;
  transform: translate(calc(${left ? left : 0}px - 50%), -50%);
  width: 16px;
  height: 16px;
  display: grid;
  place-content: center;
  padding-bottom: 1px;
  border: 1px solid ${
    roleType === "tonic" && !isHighlighted
      ? "var(--pitch-marker-role)"
      : (roleType !== "tonic" && isHighlighted) ||
        (roleType === "tonic" && isHighlighted)
      ? "var(--pitch-marker-highlight)"
      : "var(--pitch-marker)"
  };
  transition: border-color 0.05s;
  border-radius: 2px;
  font-weight: ${roleType === "tonic" || isHighlighted ? "700" : "400"};
  color: ${
    roleType === "tonic" && !isHighlighted
      ? "var(--track)"
      : (roleType !== "tonic" && isHighlighted) ||
        (roleType === "tonic" && isHighlighted)
      ? "var(--track)"
      : "var(--pitch-marker-role)"
  };
  background: ${
    roleType === "tonic" && !isHighlighted
      ? "var(--pitch-marker-role)"
      : (roleType !== "tonic" && isHighlighted) ||
        (roleType === "tonic" && isHighlighted)
      ? "var(--pitch-marker-highlight)"
      : "var(--track-inner)"
  };
  user-select: none;
  pointer-events: none;
  `
);

interface TrackSubsetVisualisationProps {
  tuning?: Tuning;
  subset?: TuningSubset;
  width: number;
  highlightIndex?: number | null;
  trackOpen: boolean;
}
export const TrackSubsetVisualisation: React.FC<
  TrackSubsetVisualisationProps
> = ({ tuning, subset, width, highlightIndex, trackOpen }) => {
  let degrees = subset?.degrees ?? [];
  let pcs = tuning?.pitchClasses ?? [];
  let markers = pcs
    .map((pc, idx) => ({
      pc,
      degree: degrees.find((d) => d.index === idx),
      idx,
    }))
    .filter(
      (m): m is { pc: PitchClass; degree: TuningSubsetDegree; idx: number } =>
        !!m.degree
    );
  return (
    <Container trackOpen={trackOpen}>
      {markers.map((m, i) => (
        <React.Fragment key={i}>
          <Marker
            left={(getPitchCents(m.pc) / 1200) * width}
            isHighlighted={m.idx === highlightIndex}
          />
          {m.degree.role !== "none" && (
            <RoleLabel
              left={(getPitchCents(m.pc) / 1200) * width}
              roleType={m.degree.role}
              isHighlighted={m.idx === highlightIndex}
            >
              {getRoleLabel(m.degree.role)}
            </RoleLabel>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

function getRoleLabel(role: TuningSubsetDegree["role"]) {
  switch (role) {
    case "tonic":
      return "R";
    case "primary":
      return "P";
    case "secondary":
      return "S";
    case "none":
      return "";
  }
}
