import { Rnd } from "react-rnd";
import { immediate } from "tone";
import { getClosestMIDINote, getMIDIPitchBend } from "../../main/audio";
import {
  NumericInstrumentBank,
  NumericInstrumentPreset,
  ParamModulation,
} from "../types";
import { loadCommon, loadScript } from "./common";

declare let WAM: any;

const BANKS = [
  { name: "Factory", url: "/wam/obxd/banks/factory.fxb" },
  { name: "Bass", url: "/wam/obxd/banks/kvr_community_bass.fxb" },
  {
    name: "Brass, Synths",
    url: "/wam/obxd/banks/kvr_community_brass_synths.fxb",
  },
  {
    name: "Drums, Percussion, SFX",
    url: "/wam/obxd/banks/kvr_community_drums_percussion_sfx.fxb",
  },
  {
    name: "Keys, Bells, Plucked",
    url: "/wam/obxd/banks/kvr_community_keys_bells_plucked.fxb",
  },
  { name: "Leads", url: "/wam/obxd/banks/kvr_community_leads.fxb" },
  {
    name: "Pads, Strings, Vocalic",
    url: "/wam/obxd/banks/kvr_community_pads_strings_vocalic.fxb",
  },
];

let loadedPromise: Promise<NumericInstrumentBank[]> | null = null;
let banks: NumericInstrumentBank[] = [];
async function _load(audioCtx: AudioContext) {
  await loadCommon();
  await loadScript("/wam/obxd/obxd.js");
  if (!WAM.OBXD) return []; // No AudioWorklet
  await WAM.OBXD.importScripts(audioCtx);
  await Promise.all(
    BANKS.map(async (bank) => {
      let bankBuffer = await fetch(bank.url).then((res) => res.arrayBuffer());
      let presets = WAM.OBXD.loadBank(bankBuffer).filter(
        (p: NumericInstrumentPreset) => !p.name.startsWith("***")
      );
      banks.push({ name: bank.name, presets });
    })
  );
  return banks;
}
function load(audioCtx: AudioContext) {
  if (!loadedPromise) loadedPromise = _load(audioCtx);
  return loadedPromise;
}

export class OBXD {
  private wam: any;
  private outputGain?: GainNode;
  private gui?: any;
  public currentBank: number | null = null;
  public currentPreset: number | null = null;

  static preload(audioCtx: AudioContext) {
    return load(audioCtx);
  }

  static getNumberOfVoices(patchState: number[]) {
    return Math.ceil(patchState[3] * 8); // Based on map in obxd.html
  }

  getBanks() {
    return BANKS;
  }

  constructor(audioCtx: AudioContext, dest: any) {
    this.wam = new WAM.OBXD(audioCtx);
    this.outputGain = audioCtx.createGain();

    while (dest.input) {
      dest = dest.input;
    }
    if (dest._nativeAudioNode) {
      dest = dest._nativeAudioNode;
    }

    this.wam.connect(this.outputGain);
    this.outputGain.connect(dest);
  }

  triggerAttackRelease(
    frequency: number,
    duration: number,
    time: number,
    velocity: number
  ) {
    let midiNote = getClosestMIDINote(frequency, 200, null);
    let pitchBend = getMIDIPitchBend(frequency, midiNote, 200);
    var bendLevel = Math.round(((pitchBend + 1) / 2) * 16383);
    var bendMsb = (bendLevel >> 7) & 0x7f;
    var bendLsb = bendLevel & 0x7f;

    this.wam.onMidi([0x90, midiNote, velocity * 127], time);
    this.wam.onMidi([0xe0, bendLsb, bendMsb], time);

    this.wam.onMidi([0x80, midiNote, velocity * 127], time + duration);
  }

  sendControlChange(cc: number, value: number, time: number) {
    this.wam.onMidi([0xb0, cc, value], time);
  }

  setParam(param: number, value: number) {
    this.wam.applyParam(param, value);
  }

  modulate(param: number, modulations: ParamModulation[]) {
    this.wam.modulateParam(param, modulations);
  }

  resetModulation(param: number, time: number) {
    this.wam.resetParamModulation(param, time);
  }

  onGUIParamChange(
    listener: (param: number, value: number, emitEvent: boolean) => void
  ) {
    this.wam.onparamchange = listener;
    return () => {
      this.wam.onparamchange = undefined;
    };
  }

  setPreset(
    bank: number,
    preset: number,
    patchState: number[]
  ): number[] | undefined {
    if (bank !== this.currentBank || preset !== this.currentPreset) {
      this.wam.setPatch(patchState);
      this.currentBank = bank;
      this.currentPreset = preset;
    }
    return this.wam.patch;
  }

  async openGUI(container: Rnd) {
    if (!this.gui) {
      this.gui = await this.wam.loadGUI("/wam/obxd/skin");
      let containerEl = container
        .getSelfElement()!
        .querySelector(".pluginGUIContent")!;
      containerEl.appendChild(this.gui);
      container.updateSize({
        width: this.gui.width,
        height: this.gui.height + 15,
      });
      container.updatePosition({
        x: window.innerWidth - this.gui.width - 20,
        y: 20,
      });
    }
  }

  closeGUI() {
    this.gui?.remove();
    this.gui = undefined;
  }

  dispose(time = immediate()) {
    this.closeGUI();
    this.outputGain?.gain.setTargetAtTime(0, time, 0.33);
    setTimeout(() => {
      this.wam.dispose();
      this.outputGain?.disconnect();
    }, 1000);
  }
}
