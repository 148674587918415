import React, { useState, useCallback, useEffect } from "react";

import * as controller from "./apotomeController";
import { Slider } from "./Slider";

import "./GlobalControls.scss";
import { NumberInput } from "../main/NumberInput";
import { TempoVisualizer } from "./TempoVisualizer";
import { SettingsDialog } from "./SettingsDialog";
import { Output } from "webmidi";
import { TimeSignatureControl } from "./TimeSignatureControl";
import { useAuth0 } from "@auth0/auth0-react";
import {
  TimeSignatureDenominator,
  TransportControls,
  VisualizerConnectionSettings,
} from "./types";
import { useAdminAccess } from "../useAdminAccess";
import { MAX_VOLUME_DB, MIN_VOLUME_DB } from "./constants";
import { StudioHeaderControls } from "./StudioHeaderControls";
import { round } from "lodash";

import Logo from "./images/logoApotome.png";
import { UserProfileDialog } from "./UserProfileDialog";
import { useUserProfile } from "../useUserProfile";

interface GlobalControlsProps {
  currentSpace: "Studio" | "Stage";
  visualizerConnectionSettings: VisualizerConnectionSettings;
  transportControls: TransportControls;
  startAllowed: boolean;
  onUpdateTransportControls: (
    updater: (newTransportControls: TransportControls) => TransportControls
  ) => void;
  onUpdateVisualizerConnectionSettings: (
    newSettings: VisualizerConnectionSettings
  ) => void;
}
export const GlobalControls: React.FC<GlobalControlsProps> = React.memo(
  ({
    currentSpace,
    transportControls,
    visualizerConnectionSettings,
    startAllowed,
    onUpdateTransportControls,
    onUpdateVisualizerConnectionSettings,
  }) => {
    let {
      isAuthenticated,
      isLoading: isAuthLoading,
      user,
      loginWithPopup,
      logout,
    } = useAuth0();
    let { hasAdminAccess } = useAdminAccess();
    let { profile } = useUserProfile();
    let [isPlaying, setIsPlaying] = useState(false);
    let [masterVolume, setMasterVolume] = useState(MAX_VOLUME_DB);
    let [midiClockOutputs, setMidiClockOutputs] = useState<Output[]>([]);
    let [settingsOpen, setSettingsOpen] = useState(false);
    let [userProfileOpen, setUserProfileOpen] = useState(false);

    useEffect(() => {
      let onStart = () => setIsPlaying(true);
      let onStop = () => setIsPlaying(false);
      let onMasterVol = (vol: number) => setMasterVolume(vol);
      controller.playbackEvents.on("start", onStart);
      controller.playbackEvents.on("stop", onStop);
      controller.playbackEvents.on("masterVolumeChange", onMasterVol);
      return () => {
        controller.playbackEvents.off("start", onStart);
        controller.playbackEvents.off("stop", onStop);
        controller.playbackEvents.off("masterVolumeChange", onMasterVol);
      };
    }, []);

    let start = useCallback(async () => {
      await controller.start();
    }, []);
    let stop = useCallback(() => {
      controller.stop();
    }, []);
    let resync = useCallback(() => {
      controller.resync();
    }, []);
    let sendMidiPanic = useCallback(() => {
      controller.sendMidiPanic();
    }, []);
    let onSetTempo = useCallback(
      (tempo: number) => {
        onUpdateTransportControls((c) => ({ ...c, tempo }));
      },
      [onUpdateTransportControls]
    );
    let onSetMasterVolume = useCallback((newVol: number) => {
      controller.setMasterVolume(newVol);
    }, []);
    let onSetTimeSigNumerator = useCallback(
      (timeSignatureNumerator: string) => {
        onUpdateTransportControls((c) => ({
          ...c,
          timeSignatureNumerator,
        }));
      },
      [onUpdateTransportControls]
    );
    let onSetTimeSigDenominator = useCallback(
      (timeSignatureDenominator: TimeSignatureDenominator) => {
        onUpdateTransportControls((c) => ({
          ...c,
          timeSignatureDenominator,
        }));
      },
      [onUpdateTransportControls]
    );
    let updateMidiClockOutputs = useCallback((newOutputs: Output[]) => {
      controller.setMidiClockOutputs(newOutputs);
      setMidiClockOutputs(newOutputs);
    }, []);
    let closeUserProfile = useCallback(() => {
      setUserProfileOpen(false);
    }, []);

    return (
      <>
        <div className="globalControls">
          <div className="globalControls--headerBar">
            <h1>
              <img src={Logo} alt="Apotome" />
              <span className="globalControls--currentSpace">
                {currentSpace}
              </span>
            </h1>
            {currentSpace === "Studio" && <StudioHeaderControls />}
            <div className="globalControls--headerBar--guide">
              <a
                href="https://docs.google.com/document/d/1vxLZaL8jeXQcj3m7q6qF42ZWAaG-2HlRkQZiGacRUXI/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                User guide
              </a>
              <a
                href="https://vimeo.com/503451447"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tutorial
              </a>
            </div>
            {!isAuthLoading && (
              <div className="globalControls--headerBar--auth">
                {isAuthenticated ? (
                  <span
                    className="globalControls--headerBar--userProfileName"
                    onClick={() => setUserProfileOpen(true)}
                  >
                    {profile?.username ?? user.email}
                  </span>
                ) : (
                  <>Anonymous User</>
                )}{" "}
                {hasAdminAccess && <>(Administrator)</>}{" "}
                {isAuthenticated ? (
                  <button
                    className="button button--small"
                    onClick={() =>
                      logout({ returnTo: `${window.location.origin}/apotome` })
                    }
                  >
                    Sign out
                  </button>
                ) : (
                  <button
                    className="button button--small"
                    onClick={() => loginWithPopup()}
                  >
                    Sign in
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="globalControls--bar">
            {!isPlaying && (
              <button
                onClick={start}
                className="button button--primary button--startStop"
                disabled={!startAllowed}
              >
                Start
              </button>
            )}
            {isPlaying && (
              <button
                onClick={stop}
                className="button button--primary button--startStop"
              >
                Stop
              </button>
            )}
            <button
              onClick={resync}
              className="button button--primary"
              disabled={!isPlaying}
            >
              Re-Sync Tracks
            </button>
            <button onClick={sendMidiPanic} className="button button--primary">
              MIDI Panic
            </button>
            <label className="globalControls--tempo">
              <TempoVisualizer /> Tempo &#188;=
              <Slider
                min={1}
                max={200}
                step={1}
                value={transportControls.tempo}
                width={250}
                onUpdateValue={onSetTempo}
              />
              <NumberInput
                className="input numberInput"
                value={transportControls.tempo}
                min={1}
                max={200}
                onChange={onSetTempo}
              />
            </label>
            <label className="globalControls--timeSignature">
              Time Signature
              <TimeSignatureControl
                numerator={transportControls.timeSignatureNumerator}
                denominator={transportControls.timeSignatureDenominator}
                onUpdateNumerator={onSetTimeSigNumerator}
                onUpdateDenominator={onSetTimeSigDenominator}
              />
            </label>
            <label className="globalControls--masterGain">
              Volume
              <Slider
                value={masterVolume}
                min={MIN_VOLUME_DB}
                max={MAX_VOLUME_DB}
                step={0.01}
                onUpdateValue={onSetMasterVolume}
              />
              {masterVolume === MIN_VOLUME_DB ? (
                <>-InfdB</>
              ) : (
                <>{round(masterVolume, 1)}dB</>
              )}
            </label>
            <button
              onClick={() => setSettingsOpen(true)}
              className="button button--primary"
            >
              Settings
            </button>
          </div>
        </div>
        <SettingsDialog
          isOpen={settingsOpen}
          midiClockOutputs={midiClockOutputs}
          visualizerConnectionSettings={visualizerConnectionSettings}
          onSetMidiClockOutputs={updateMidiClockOutputs}
          onSetVisualizerConnectionSettings={
            onUpdateVisualizerConnectionSettings
          }
          onClose={() => setSettingsOpen(false)}
        />
        <UserProfileDialog
          isOpen={userProfileOpen}
          onClose={closeUserProfile}
        />
      </>
    );
  }
);
