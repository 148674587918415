import React, { useState, useEffect, useCallback, useMemo } from "react";
import { findIndex, flatMap, memoize, range, round, sortBy } from "lodash";
import classNames from "classnames";

import { ScaleHeader, Scale, Weights } from "../main/core";
import * as tunings from "./tuningsAccess";
import { ScalePicker } from "../main/ScalePicker";
import { WeightsControl } from "./WeightsControl";
import { EnvelopeControl } from "./EnvelopeControl";
import {
  DEFAULT_TIME_SIGNATURE_NUMERATOR,
  DEFAULT_TIME_SIGNATURE_DENOMINATOR,
  MIN_VOLUME_DB,
  MAX_VOLUME_DB,
  DEFAULT_BASIC_SYNTH_FILTER_FREQUENCY,
  DEFAULT_BASIC_SYNTH_FILTER_RESONANCE,
  MAX_BASIC_SYNTH_FILTER_FREQUENCY,
  MIN_BASIC_SYNTH_FILTER_FREQUENCY,
  MIN_BASIC_SYNTH_FILTER_RESONANCE,
  MAX_BASIC_SYNTH_FILTER_RESONANCE,
  MIN_NEGATIVE_NOTE_DELAY_S,
  MIN_NOTE_ECHO_FEED,
  MAX_NOTE_ECHO_FEED,
  MIN_NOTE_ECHO_FEEDBACK,
  MAX_NOTE_ECHO_FEEDBACK,
  MIN_VOICE_COUNT,
  MAX_VOICE_COUNT,
} from "./constants";
import { KnobControl } from "./KnobControl";

import { PanControl } from "./PanControl";
import { RangeSlider } from "./RangeSlider";
import { ScaleStrip } from "./ScaleStrip";
import { EventEmitter } from "events";
import { MIDIOutput } from "../main/MIDIOutput";
import { Looper } from "./Looper";
import { RefPitchInput } from "../RefPitchInput";
import { TimeSignatureControl } from "./TimeSignatureControl";
import { MelodicIntervalToggle } from "./MelodicIntervalToggle";
import { UseAccentToggle } from "./UseAccentToggle";
import { ToggleButton } from "./ToggleButton";
import { ToggleSwitch } from "./ToggleSwitch";
import {
  NoteValue,
  Envelope,
  TrackControls,
  Looper as LooperType,
  TrackInstrument,
  BasicTone,
  InstrumentBanks,
  TrackModulatorLFO,
  TrackMelodyLFO,
  TrackLFO,
} from "./types";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { randomizeTrack } from "./randomization";
import { getLeimmaLink } from "../urlSerialization";
import { EuclideanVisualizer } from "./EuclideanVisualizer";
import { AdwarMiniDisplay } from "./AdwarMiniDisplay";
import { LFOControls } from "./LFOControls";
import {
  MAX_OCTAVE,
  MIN_OCTAVE,
  SCALE_DEGREE_NAMES_ENGLISH,
} from "../constants";
import { getTrackScaleDegrees } from "./apotomeController";
import { ShapedMelodyVisualiser } from "./ShapedMelodyVisualiser";
import { RandomWalkMelodyVisualiser } from "./RandomWalkMemoryVisualiser";

import "./Track.scss";

const SCALE_DEGREE_ROLE_WEIGHT_KEYS = [
  "Tonic",
  "Primary",
  "Secondary",
  "None",
  "Rest",
];
const SCALE_DEGREE_ROLE_WEIGHT_LABELS = [
  "Root",
  "Primary",
  "Secondary",
  "None",
  "Rest",
];
const OCTAVE_WEIGHT_KEYS = range(9).map((o) => `${o}`);
const BEAT_DIVISION_WEIGTH_KEYS = {
  Shorter: [
    "1",
    "1/2",
    "1/3",
    "1/4",
    "1/5",
    "1/6",
    "1/7",
    "1/8",
    "1/9",
    "1/10",
    "1/11",
    "1/12",
    "1/13",
    "1/14",
    "1/15",
    "1/16",
  ] as NoteValue[],
  Longer: [
    "16",
    "15",
    "14",
    "13",
    "12",
    "11",
    "10",
    "9",
    "8",
    "7",
    "6",
    "5",
    "4",
    "3",
    "2",
    "1",
  ] as NoteValue[],
};
export const ALL_BEAT_DIVISION_WEIGHT_KEYS: NoteValue[] =
  BEAT_DIVISION_WEIGTH_KEYS.Shorter.slice()
    .reverse()
    .concat(BEAT_DIVISION_WEIGTH_KEYS.Longer.slice().reverse());

const DEFAULT_SCALE_DEGREE_ROLE_WEIGHTS: Weights = {
  Tonic: 0.0,
  Primary: 0.0,
  Secondary: 0.0,
  None: 0.0,
  Rest: 0.0,
};
const DEFAULT_OCTAVE_WEIGHTS: Weights = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
};
const DEFAULT_BEAT_DIVISION_WEIGHTS: Weights = {
  "1": 0,
  "1/2": 0,
  "1/3": 0,
  "1/4": 0,
  "1/5": 0,
  "1/6": 0,
  "1/7": 0,
  "1/8": 0,
  "1/9": 0,
  "1/10": 0,
  "1/11": 0,
  "1/12": 0,
  "1/13": 0,
  "1/14": 0,
  "1/15": 0,
  "1/16": 0,
  "2": 0,
  "3": 0,
  "4": 0,
  "5": 0,
  "6": 0,
  "7": 0,
  "8": 0,
  "9": 0,
  "10": 0,
  "11": 0,
  "12": 0,
  "13": 0,
  "14": 0,
  "15": 0,
  "16": 0,
};
const DEFAULT_NOTE_LENGTHS: Weights = {
  "1": 1,
  "1/2": 1,
  "1/3": 1,
  "1/4": 1,
  "1/5": 1,
  "1/6": 1,
  "1/7": 1,
  "1/8": 1,
  "1/9": 1,
  "1/10": 1,
  "1/11": 1,
  "1/12": 1,
  "1/13": 1,
  "1/14": 1,
  "1/15": 1,
  "1/16": 1,
  "2": 1,
  "3": 1,
  "4": 1,
  "5": 1,
  "6": 1,
  "7": 1,
  "8": 1,
  "9": 1,
  "10": 1,
  "11": 1,
  "12": 1,
  "13": 1,
  "14": 1,
  "15": 1,
  "16": 1,
};
const DEFAULT_AMPLITUDE_ENVELOPE: Envelope = {
  attack: 0.05,
  decay: 0.4,
  sustain: 0.0,
  release: 0.8,
};

export const MIN_NOTE_LENGTH = 0.1;
export const MAX_NOTE_LENGTH = 1;
export const MIN_BEAT_DELAY = MIN_NEGATIVE_NOTE_DELAY_S * 1000;
export const MAX_BEAT_DELAY = 100;

const DEFAULT_MODULATOR_LFO: TrackModulatorLFO = {
  on: false,
  shape: "sine",
  synced: true,
  rate: "1",
  unsyncedRate: 0.1,
  retrigger: false,
  amount: 127,
  phase: 0,
  targets: {
    basicSynth: -1,
    dexed: -1,
    obxd: -1,
    midi: -1,
    string: -1,
    yoshimi: -1,
  },
};

const DEFAULT_MELODY_SHAPE_LFO: TrackMelodyLFO = {
  on: false,
  shape: "sine",
  synced: true,
  rate: "1",
  unsyncedRate: 0.1,
  retrigger: false,
  amount: 127,
  phase: 0,
};

export let makeDefaultControls = (instrumentBanks: InstrumentBanks) => {
  return {
    started: false,
    muted: false,
    soloStatus: "none",
    followTuningFromLeadTrack: false,
    followScaleWeightsFromLeadTrack: false,
    followAllowedIntervalsFromLeadTrack: false,
    followBeatDivisionWeightsFromLeadTrack: false,
    voiceCount: 1,
    roleWeights: DEFAULT_SCALE_DEGREE_ROLE_WEIGHTS,
    scaleDegreeWeights: { "1": 1, Rest: 0 },
    activeScaleWeights: "role",
    melodyType: "randomWalk",
    octaveWeights: DEFAULT_OCTAVE_WEIGHTS,
    allowedIntervals: [{ interval: 0, allowed: true }],
    melodyShapeMin: { octave: 2, index: 0 },
    melodyShapeMax: { octave: 5, index: 0 },
    melodyShapeLfos: [
      DEFAULT_MELODY_SHAPE_LFO,
      DEFAULT_MELODY_SHAPE_LFO,
      DEFAULT_MELODY_SHAPE_LFO,
      DEFAULT_MELODY_SHAPE_LFO,
    ],
    forcePolyphony: false,
    overridetimeSignature: false,
    timeSignatureNumerator: DEFAULT_TIME_SIGNATURE_NUMERATOR,
    timeSignatureDenominator: DEFAULT_TIME_SIGNATURE_DENOMINATOR,
    beatDivisionType: "weights",
    beatDivisionWeights: DEFAULT_BEAT_DIVISION_WEIGHTS,
    beatDivisionRestToggles: Object.keys(DEFAULT_BEAT_DIVISION_WEIGHTS).map(
      (division) => ({ division, enabled: true })
    ),
    beatDivisionEuclideanK: 2,
    beatDivisionEuclideanN: 8,
    beatDivisionEuclideanBeatValue: "1/8",
    beatDivisionAdwarTriggers: [],
    activeArticulation: "minMax",
    minNoteLength: 0.98,
    maxNoteLength: 1,
    noteLengths: DEFAULT_NOTE_LENGTHS,
    minBeatDelay: 0,
    maxBeatDelay: 0,
    minVelocity: 80 / 127,
    maxVelocity: 100 / 127,
    useAccentVelocity: false,
    forceTuplets: true,
    noteEchoOn: false,
    noteEchoTime: "1/3",
    noteEchoFeed: 0.8,
    noteEchoFeedback: 0.5,
    instrument: "basicSynth",
    tone: "triangle",
    amplitudeEnvelope: DEFAULT_AMPLITUDE_ENVELOPE,
    filterFrequency: DEFAULT_BASIC_SYNTH_FILTER_FREQUENCY,
    filterResonance: DEFAULT_BASIC_SYNTH_FILTER_RESONANCE,
    volume: 0,
    pan: 0,
    send1Gain: 0.2,
    send2Gain: 0,
    obState: {
      bank: 0,
      preset: 0,
      patchState: instrumentBanks.OBXD[0].presets[0].patch,
    },
    dxState: {
      bank: 0,
      preset: 0,
      patchState: instrumentBanks.DEXED[0].presets[0].patch,
    },
    yoshimiState: {
      bank: 0,
      preset: 0,
    },
    lfos: [
      DEFAULT_MODULATOR_LFO,
      DEFAULT_MODULATOR_LFO,
      DEFAULT_MODULATOR_LFO,
      DEFAULT_MODULATOR_LFO,
    ],
    pluginGUIOpen: false,
    adwarEditorOpen: false,
  } as TrackControls;
};
interface TrackProps {
  id: string;
  controls: TrackControls;
  instrumentBanks: InstrumentBanks;
  events: EventEmitter;
  onSetControls: (
    id: string,
    update: (oldControls: TrackControls) => TrackControls
  ) => void;
  onRemove: (id: string) => void;
  onDuplicate?: (id: string) => void;
  onSolo: (id: string, on: boolean) => void;
  onPickTuningSystem: (id: string, adwar: boolean) => void;
  onToggleTuningSnapshots: (id: string) => void;
}
export const Track: React.FC<TrackProps> = React.memo(
  ({
    id,
    controls,
    instrumentBanks,
    events,
    onSetControls,
    onRemove,
    onDuplicate,
    onSolo,
    onPickTuningSystem,
    onToggleTuningSnapshots,
  }) => {
    let { isAuthenticated, getAccessTokenSilently } = useAuth0();
    let [scaleOptions, setScaleOptions] = useState<ScaleHeader[]>();
    let [adwarScaleOptions, setAdwarScaleOptions] = useState<ScaleHeader[]>();
    let [visibleBeatDivisionWeights, setVisibleBeatDivisionWeights] = useState<
      "Shorter" | "Longer"
    >("Shorter");
    let [visibleArticulationDivisions, setVisibleArticulationDivisions] =
      useState<"Shorter" | "Longer">("Shorter");

    useEffect(() => {
      if (controls.tuningSystem) {
        if (isAuthenticated) {
          getAccessTokenSilently()
            .then((token) => tunings.loadScales(token))
            .then((scales) =>
              scales.filter(
                (scale) => scale.tuningSystemId === controls.tuningSystem!.id!
              )
            )
            .then(setScaleOptions);
        } else {
          tunings
            .loadScales()
            .then((scales) =>
              scales.filter(
                (scale) => scale.tuningSystemId === controls.tuningSystem!.id
              )
            )
            .then(setScaleOptions);
        }
      }
    }, [controls.tuningSystem, isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
      let effectiveTuningSystem =
        controls.beatDivisionAdwarTuningSystem ?? controls.tuningSystem;
      if (effectiveTuningSystem) {
        if (isAuthenticated) {
          getAccessTokenSilently()
            .then((token) => tunings.loadScales(token))
            .then((scales) =>
              scales.filter(
                (scale) => scale.tuningSystemId === effectiveTuningSystem!.id
              )
            )
            .then(setAdwarScaleOptions);
        } else {
          tunings
            .loadScales()
            .then((scales) =>
              scales.filter(
                (scale) => scale.tuningSystemId === effectiveTuningSystem!.id
              )
            )
            .then(setAdwarScaleOptions);
        }
      }
    }, [
      controls.tuningSystem,
      controls.beatDivisionAdwarTuningSystem,
      isAuthenticated,
      getAccessTokenSilently,
    ]);

    let onStart = useCallback(() => {
      onSetControls(id, (c) => ({ ...c, started: true }));
    }, [id, onSetControls]);
    let onStop = useCallback(() => {
      onSetControls(id, (c) => ({ ...c, started: false }));
    }, [id, onSetControls]);
    let onToggleMute = useCallback(() => {
      onSetControls(id, (c) => ({ ...c, muted: !c.muted }));
    }, [id, onSetControls]);

    let pickScale = async (scaleId: number, adwar: boolean) => {
      let scale: Scale | undefined;
      if (isAuthenticated) {
        let token = await getAccessTokenSilently();
        scale = (await tunings.loadScales(token)).find((s) => s.id === scaleId);
      } else {
        scale = (await tunings.loadScales()).find((s) => s.id === scaleId);
      }
      if (!scale) {
        throw new Error(`Scale with id ${scaleId} not found`);
      }
      if (adwar) {
        onSetControls(id, (c) => ({
          ...c,
          beatDivisionAdwarScale: scale,
        }));
      } else {
        onSetControls(id, (c) =>
          syncScaleDegreeWeightsAndAllowedIntervalsWithScale({
            ...c,
            scale,
          })
        );
      }
    };

    let onUpdateScaleDegreeRoleWeights = useCallback(
      (newWeights: Weights) => {
        onSetControls(id, (c) => ({ ...c, roleWeights: newWeights }));
      },
      [id, onSetControls]
    );
    let onUpdateScaleDegreeWeights = useCallback(
      (newWeights: Weights) => {
        onSetControls(id, (c) => ({ ...c, scaleDegreeWeights: newWeights }));
      },
      [id, onSetControls]
    );
    let onUpdateAllowedInterval = useCallback(
      (interval: number | "8ve", allowed: boolean) => {
        onSetControls(id, (c) => ({
          ...c,
          allowedIntervals: sortBy(
            c.allowedIntervals
              .filter((a) => a.interval !== interval)
              .concat([{ interval, allowed }]),
            (i) => (i.interval === "8ve" ? Number.MAX_VALUE : i.interval)
          ),
        }));
      },
      [id, onSetControls]
    );

    let switchToDegreeScaleWeights = useCallback(() => {
      onSetControls(id, (c) => ({ ...c, activeScaleWeights: "degree" }));
    }, [id, onSetControls]);
    let switchToRoleScaleWeights = useCallback(() => {
      onSetControls(id, (c) => ({ ...c, activeScaleWeights: "role" }));
    }, [id, onSetControls]);

    let onUpdateOctaveWeights = useCallback(
      (newWeights: Weights) => {
        onSetControls(id, (c) => ({ ...c, octaveWeights: newWeights }));
      },
      [id, onSetControls]
    );

    let onUpdateBeatDivisionWeights = useCallback(
      (newWeights: Weights) => {
        onSetControls(id, (c) => ({
          ...c,
          beatDivisionWeights: { ...c.beatDivisionWeights, ...newWeights },
        }));
      },
      [id, onSetControls]
    );

    let onUpdateBeatDivisionRestToggle = useCallback(
      (division: string, enabled: boolean) => {
        onSetControls(id, (c) => {
          let idx = findIndex(
            c.beatDivisionRestToggles,
            (t) => t.division === division
          );
          return {
            ...c,
            beatDivisionRestToggles: [
              ...c.beatDivisionRestToggles.slice(0, idx),
              { division, enabled },
              ...c.beatDivisionRestToggles.slice(idx + 1),
            ],
          };
        });
      },
      [id, onSetControls]
    );

    let onUpdateEuclideanRange = useCallback(
      (k: number, n: number) => {
        if (
          k !== controls.beatDivisionEuclideanK ||
          n !== controls.beatDivisionEuclideanN
        ) {
          onSetControls(id, (c) => ({
            ...c,
            beatDivisionEuclideanK: k,
            beatDivisionEuclideanN: n,
          }));
        }
      },
      [id, onSetControls, controls]
    );

    let onUpdateNoteLengthRange = useCallback(
      (minNoteLength: number, maxNoteLength: number) => {
        onSetControls(id, (c) => ({ ...c, minNoteLength, maxNoteLength }));
      },
      [id, onSetControls]
    );
    let onUpdateDivisionNoteLengths = useCallback(
      (newLengths: Weights) => {
        onSetControls(id, (c) => ({
          ...c,
          noteLengths: { ...c.noteLengths, ...newLengths },
        }));
      },
      [id, onSetControls]
    );
    let switchToMinMaxArticulation = useCallback(() => {
      onSetControls(id, (c) => ({ ...c, activeArticulation: "minMax" }));
    }, [id, onSetControls]);
    let switchToBeatDivisionBasedArticulation = useCallback(() => {
      onSetControls(id, (c) => ({ ...c, activeArticulation: "beatDivisions" }));
    }, [id, onSetControls]);

    let onUpdateBeatDelayRange = useCallback(
      (minBeatDelay: number, maxBeatDelay: number) => {
        onSetControls(id, (c) => ({ ...c, minBeatDelay, maxBeatDelay }));
      },
      [id, onSetControls]
    );

    let onUpdateLooper = useCallback(
      (looper?: LooperType) => {
        onSetControls(id, (c) => ({ ...c, looper }));
      },
      [id, onSetControls]
    );

    let onUpdateVelocityRange = useCallback(
      (minVelocity: number, maxVelocity: number) => {
        onSetControls(id, (c) => ({ ...c, minVelocity, maxVelocity }));
      },
      [id, onSetControls]
    );

    let onUpdateAmplitudeEnvelope = useCallback(
      (newEnvelope: Envelope) => {
        onSetControls(id, (c) => ({ ...c, amplitudeEnvelope: newEnvelope }));
      },
      [id, onSetControls]
    );

    let onUpdateFilterFrequency = useCallback(
      (f: number) => {
        onSetControls(id, (c) => ({ ...c, filterFrequency: f }));
      },
      [id, onSetControls]
    );
    let onUpdateFilterResonance = useCallback(
      (r: number) => {
        onSetControls(id, (c) => ({ ...c, filterResonance: r }));
      },
      [id, onSetControls]
    );

    let onUpdateVolume = useCallback(
      (v: number) => {
        onSetControls(id, (c) => ({ ...c, volume: v }));
      },
      [id, onSetControls]
    );

    let onUpdatePan = useCallback(
      (p: number) => {
        onSetControls(id, (c) => ({ ...c, pan: p }));
      },
      [id, onSetControls]
    );

    let onUpdateSend1Gain = useCallback(
      (g: number) => {
        onSetControls(id, (c) => ({ ...c, send1Gain: g }));
      },
      [id, onSetControls]
    );

    let onUpdateSend2Gain = useCallback(
      (g: number) => {
        onSetControls(id, (c) => ({ ...c, send2Gain: g }));
      },
      [id, onSetControls]
    );

    let onSetNoteEchoTime = useCallback(
      (val: number) =>
        onSetControls(id, (c) => ({
          ...c,
          noteEchoTime: ALL_BEAT_DIVISION_WEIGHT_KEYS[val],
        })),
      [id, onSetControls]
    );

    let onSetNoteEchoFeed = useCallback(
      (val: number) =>
        onSetControls(id, (c) => ({
          ...c,
          noteEchoFeed: val,
        })),
      [id, onSetControls]
    );

    let onSetNoteEchoFeedback = useCallback(
      (val: number) =>
        onSetControls(id, (c) => ({
          ...c,
          noteEchoFeedback: val,
        })),
      [id, onSetControls]
    );

    let onRandomize = useCallback(async () => {
      let randomized = await randomizeTrack(
        controls,
        instrumentBanks,
        isAuthenticated ? await getAccessTokenSilently() : undefined
      );
      onSetControls(id, (c) => ({ ...c, ...randomized }));
    }, [
      id,
      onSetControls,
      isAuthenticated,
      getAccessTokenSilently,
      instrumentBanks,
      controls,
    ]);

    let melodyShapeGamutOptions = useMemo(
      () => getMelodyShapeGamuOptions(controls.scale),
      [controls.scale]
    );
    let onSetMelodyShapeMin = useCallback(
      (index: number) => {
        let newMin = melodyShapeGamutOptions[index];
        onSetControls(id, (c) => ({
          ...c,
          melodyShapeMin: newMin,
          melodyShapeMax: c.melodyShapeMax
            ? newMin.octave * 99 + newMin.index >
              c.melodyShapeMax.octave * 99 + c.melodyShapeMax.index
              ? newMin
              : c.melodyShapeMax
            : newMin,
        }));
      },
      [id, onSetControls, melodyShapeGamutOptions]
    );
    let onSetMelodyShapeMax = useCallback(
      (index: number) => {
        let newMax = melodyShapeGamutOptions[index];
        onSetControls(id, (c) => ({
          ...c,
          melodyShapeMax: newMax,
          melodyShapeMin: c.melodyShapeMin
            ? newMax.octave * 99 + newMax.index <
              c.melodyShapeMin.octave * 99 + c.melodyShapeMin.index
              ? newMax
              : c.melodyShapeMin
            : newMax,
        }));
      },
      [id, onSetControls, melodyShapeGamutOptions]
    );

    return (
      <div className="track">
        <div
          className={classNames("trackHeader", {
            isFollowingTuning: controls.followTuningFromLeadTrack,
          })}
        >
          <div className="trackHeader--top">
            <h2>Track {id}</h2>
            <button
              className="button button--trackRandomize"
              onClick={onRandomize}
              title="Randomize track parameters"
            >
              Randomize
            </button>
            {!controls.started && (
              <button
                className="button button--trackStartStop"
                onClick={onStart}
                title="Start track"
              >
                <svg
                  className="icon iconStart"
                  height="48"
                  width="48"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M-838-2232H562v3600H-838z" fill="none" />
                  <path d="M16 10v28l22-14z" />
                  <path d="M0 0h48v48H0z" fill="none" />
                </svg>
              </button>
            )}
            {controls.started && (
              <button
                className="button button--trackStartStop"
                onClick={onStop}
                title="Stop track"
              >
                <svg
                  className="icon iconStop"
                  height="48"
                  width="48"
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h48v48H0z" fill="none" />
                  <path d="M12 12h24v24H12z" />
                </svg>
              </button>
            )}
            <button
              className={classNames("button", "button--trackMute", {
                isActive: controls.muted,
              })}
              onClick={onToggleMute}
              title="Mute track"
            >
              <svg
                className="icon iconMuted"
                height="48"
                width="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 18v12h8l10 10V8L22 18h-8z" />
                <path d="M0 0h48v48H0z" fill="none" />
              </svg>
              <svg
                className="icon iconUnmuted"
                height="48"
                width="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6 18v12h8l10 10V8L14 18H6zm27 6c0-3.53-2.04-6.58-5-8.05v16.11c2.96-1.48 5-4.53 5-8.06zM28 6.46v4.13c5.78 1.72 10 7.07 10 13.41s-4.22 11.69-10 13.41v4.13c8.01-1.82 14-8.97 14-17.54S36.01 8.28 28 6.46z" />
                <path d="M0 0h48v48H0z" fill="none" />
              </svg>
            </button>
            <button
              className={classNames("button", "button--trackSolo", {
                isActive: controls.soloStatus === "this",
              })}
              onClick={() => onSolo(id, controls.soloStatus !== "this")}
              title="Solo track"
            >
              <svg
                className="icon iconSolo"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.3,27.9c0.1,1.1,0.5,1.9,1.2,2.4c0.7,0.5,1.6,0.8,2.9,0.8c1.1,0,1.9-0.2,2.4-0.6c0.5-0.4,0.8-0.9,0.8-1.6
		c0-0.5-0.2-1-0.5-1.3s-1-0.6-2-0.8l-3-0.6c-2.5-0.5-4.4-1.3-5.6-2.4c-1.2-1.1-1.8-2.6-1.8-4.6c0-2.4,0.8-4.2,2.4-5.4
		c1.6-1.2,3.9-1.8,6.7-1.8c1.7,0,3.1,0.2,4.3,0.6c1.2,0.4,2.1,0.9,2.9,1.5c0.7,0.6,1.3,1.4,1.7,2.2c0.4,0.8,0.6,1.7,0.7,2.6l-6,0.6
		c-0.2-0.9-0.6-1.6-1.1-2c-0.5-0.4-1.4-0.7-2.5-0.7c-1,0-1.8,0.2-2.3,0.5c-0.5,0.4-0.8,0.8-0.8,1.4c0,0.5,0.2,1,0.6,1.3
		c0.4,0.3,1.1,0.6,2,0.7l3.1,0.6c2.7,0.5,4.6,1.4,5.7,2.6c1.1,1.2,1.7,2.7,1.7,4.5c0,1.1-0.2,2.2-0.6,3.1c-0.4,0.9-1,1.7-1.8,2.4
		c-0.8,0.7-1.8,1.2-3,1.5c-1.2,0.4-2.6,0.5-4.3,0.5c-6.3,0-9.7-2.7-10-8.1H20.3z"
                />
                <path fill="none" d="M0,0h48v48H0V0z" />
              </svg>
            </button>
            <button
              className="button button--trackDuplicate"
              onClick={() => onDuplicate?.(id)}
              disabled={!onDuplicate}
              title={
                onDuplicate
                  ? "Duplicate track"
                  : "Maximum amount of polyphony reached. Remove tracks or reduce the number of voices in instruments to make room"
              }
            >
              <svg
                className="icon iconDuplicate"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M27,38V27h11v-6H27V10h-6v11H10v6h11v11H27z" />
                <path fill="none" d="M0,0h48v48H0V0z" />
              </svg>
            </button>
            <button
              className="button button--trackRemove"
              onClick={() => onRemove(id)}
              title="Remove track"
              disabled={id === "1"}
            >
              <svg
                className="icon iconRemove"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M36,31.8L28.2,24l7.8-7.8L31.8,12L24,19.8L16.2,12L12,16.2l7.8,7.8L12,31.8l4.2,4.2l7.8-7.8l7.8,7.8L36,31.8z" />
                <path fill="none" d="M0,0h48v48H0V0z" />
              </svg>
            </button>
          </div>
          <div className="trackSection trackSection--tuningAndSubset">
            <div className="trackSectionHeader">
              <h4>Tuning & Subset</h4>
              {id === "1" ? (
                <>
                  <button
                    className="button button--inline"
                    onClick={() => onToggleTuningSnapshots(id)}
                  >
                    Favourites
                  </button>
                </>
              ) : (
                <label className="followToggle">
                  Follow Track 1
                  <ToggleSwitch
                    checked={controls.followTuningFromLeadTrack}
                    onToggle={(on) =>
                      onSetControls(id, (c) => ({
                        ...c,
                        followTuningFromLeadTrack: on,
                        followScaleWeightsFromLeadTrack:
                          on && c.followScaleWeightsFromLeadTrack,
                        followAllowedIntervalsFromLeadTrack:
                          on && c.followAllowedIntervalsFromLeadTrack,
                      }))
                    }
                  />
                </label>
              )}
            </div>
            <div className="trackSectionBody">
              <div className="track--tuningSystem">
                {controls.tuningSystem ? (
                  <Link
                    to={getLeimmaLink(controls.tuningSystem, controls.scale)}
                    target="_blank"
                    className="track--tuningSystem--name"
                  >
                    {controls.tuningSystem.name}
                  </Link>
                ) : (
                  <span className="track--tuningSystem--name">
                    No tuning system selected
                  </span>
                )}
                <button
                  onClick={() => onPickTuningSystem(id, false)}
                  className="button button--small track--tuningSystem--changeButton"
                  disabled={controls.followTuningFromLeadTrack}
                >
                  {controls.tuningSystem ? <>Switch</> : <>Select</>}
                </button>
              </div>
              <div className="track--referencePitch">
                Reference pitch{" "}
                {controls.tuningSystem && (
                  <RefPitchInput
                    className="tiny"
                    semitones={controls.tuningSystem.refPitchNoteMidi}
                    note={controls.tuningSystem.refPitchNoteName}
                    disabled={controls.followTuningFromLeadTrack}
                    onUpdateSemitones={(st) =>
                      onSetControls(id, (c) => ({
                        ...c,
                        tuningSystem: {
                          ...c.tuningSystem!,
                          refPitchNoteMidi: st,
                        },
                      }))
                    }
                    onUpdateNote={(n) =>
                      onSetControls(id, (c) => ({
                        ...c,
                        tuningSystem: {
                          ...c.tuningSystem!,
                          refPitchNoteName: n,
                        },
                      }))
                    }
                  />
                )}
              </div>
              <div className="track--scale">
                <ScalePicker
                  className="select"
                  scales={scaleOptions || []}
                  currentScaleId={controls.scale?.id}
                  label="Select Subset"
                  disabled={controls.followTuningFromLeadTrack}
                  onPickScale={(scale) => pickScale(scale, false)}
                />
              </div>
              <ScaleStrip
                tuningSystem={controls.tuningSystem}
                scale={controls.scale}
                events={events}
              />
            </div>
          </div>
          <div className="trackSection trackSection--polyphony">
            <div className="trackSectionHeader">
              <h4>Polyphony</h4>
            </div>
            <div className="trackSectionBody">
              <div className="track--voiceCountControl">
                <select
                  className="select"
                  value={controls.voiceCount}
                  onChange={(evt) =>
                    onSetControls(id, (c) => ({
                      ...c,
                      voiceCount: +evt.target.value,
                    }))
                  }
                >
                  {range(MIN_VOICE_COUNT, MAX_VOICE_COUNT + 1).map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>{" "}
                voices
              </div>
            </div>
          </div>

          <div className="trackSection trackSection--scaleRoleWeights">
            <div className="trackSectionHeader">
              <h4>
                Subset{" "}
                {controls.activeScaleWeights === "role" ? (
                  <>Role</>
                ) : (
                  <>Degree</>
                )}{" "}
                Weights
              </h4>
              {id !== "1" && (
                <label className="followToggle">
                  Follow Track 1
                  <ToggleSwitch
                    checked={controls.followScaleWeightsFromLeadTrack}
                    onToggle={(on) =>
                      onSetControls(id, (c) => ({
                        ...c,
                        followScaleWeightsFromLeadTrack: on,
                      }))
                    }
                  />
                </label>
              )}
            </div>
            <div className="trackSectionBody">
              <div className="track--activeScaleWeightsControls">
                {controls.activeScaleWeights === "role" && (
                  <button
                    className="button button--small track--switchActiveScaleWeights"
                    onClick={switchToDegreeScaleWeights}
                    disabled={controls.followScaleWeightsFromLeadTrack}
                  >
                    Use Degree Weights
                  </button>
                )}
                {controls.activeScaleWeights === "degree" && (
                  <button
                    className="button button--small track--switchActiveScaleWeights"
                    onClick={switchToRoleScaleWeights}
                    disabled={controls.followScaleWeightsFromLeadTrack}
                  >
                    Use Role Weights
                  </button>
                )}
              </div>
              {controls.activeScaleWeights === "role" ? (
                <WeightsControl
                  weightKeys={SCALE_DEGREE_ROLE_WEIGHT_KEYS}
                  weightLabels={SCALE_DEGREE_ROLE_WEIGHT_LABELS}
                  weights={controls.roleWeights}
                  onUpdateWeights={onUpdateScaleDegreeRoleWeights}
                  events={events}
                  noteEventHighlightKey="chosenScaleWeight"
                  disabled={controls.followScaleWeightsFromLeadTrack}
                />
              ) : (
                <WeightsControl
                  weightKeys={getScaleDegreeWeightKeys(controls.scale)}
                  weights={controls.scaleDegreeWeights}
                  onUpdateWeights={onUpdateScaleDegreeWeights}
                  events={events}
                  noteEventHighlightKey="chosenScaleWeight"
                  disabled={controls.followScaleWeightsFromLeadTrack}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames("trackBody", {
            isFollowingScaleWeights: controls.followScaleWeightsFromLeadTrack,
            isFollowingAllowedIntervals:
              controls.followAllowedIntervalsFromLeadTrack,
            isFollowingBeatDivisions:
              controls.followBeatDivisionWeightsFromLeadTrack,
          })}
        >
          <div className="trackSection trackSection--melody">
            <div className="trackSectionHeader">
              <h4>
                {controls.melodyType === "shaped" ? (
                  <>Shaped Melody</>
                ) : (
                  <>Random Walk Melody</>
                )}
              </h4>
            </div>
            <div className="trackSectionBody">
              {controls.melodyType !== "randomWalk" && controls.melodyType && (
                <button
                  className="button button--small track--switchMelodyType"
                  onClick={() =>
                    onSetControls(id, (c) => ({
                      ...c,
                      melodyType: "randomWalk",
                    }))
                  }
                >
                  Random Walk
                </button>
              )}
              {controls.melodyType !== "shaped" && (
                <button
                  className="button button--small track--switchMelodyType"
                  onClick={() =>
                    onSetControls(id, (c) => ({
                      ...c,
                      melodyType: "shaped",
                    }))
                  }
                >
                  Shaped
                </button>
              )}
              <div className="track--melody--forcePolyphonyControl">
                <div className="tabs">
                  <button
                    className={classNames("button button--tab", {
                      isActive:
                        controls.forcePolyphony === "none" ||
                        controls.forcePolyphony === false,
                    })}
                    onClick={() => {
                      onSetControls(id, (c) => ({
                        ...c,
                        forcePolyphony: "none",
                      }));
                    }}
                  >
                    <span>Free voicing</span>
                  </button>
                  <button
                    className={classNames("button button--tab", {
                      isActive:
                        controls.forcePolyphony === "unison" ||
                        controls.forcePolyphony === true,
                    })}
                    onClick={() => {
                      onSetControls(id, (c) => ({
                        ...c,
                        forcePolyphony: "unison",
                      }));
                    }}
                  >
                    <span>No unison</span>
                  </button>
                  <button
                    className={classNames("button button--tab", {
                      isActive: controls.forcePolyphony === "octaves",
                    })}
                    onClick={() => {
                      onSetControls(id, (c) => ({
                        ...c,
                        forcePolyphony: "octaves",
                      }));
                    }}
                  >
                    <span>No octaves</span>
                  </button>
                </div>
              </div>
              {(controls.melodyType === "randomWalk" ||
                !controls.melodyType) && (
                <>
                  <div className="track--melody--vis">
                    <RandomWalkMelodyVisualiser
                      id={id}
                      controls={controls}
                      events={events}
                    />
                  </div>
                  <div className="trackSectionSubheader">
                    <h5>Allowed Intervals</h5>
                    {id !== "1" && (
                      <label className="followToggle">
                        Follow Track 1
                        <ToggleSwitch
                          checked={controls.followAllowedIntervalsFromLeadTrack}
                          onToggle={(on) =>
                            onSetControls(id, (c) => ({
                              ...c,
                              followAllowedIntervalsFromLeadTrack: on,
                            }))
                          }
                        />
                      </label>
                    )}
                  </div>
                  <div className="track--melody--allowedIntervals">
                    {controls.allowedIntervals.map(({ interval, allowed }) => (
                      <MelodicIntervalToggle
                        key={interval}
                        interval={interval}
                        on={allowed}
                        disabled={controls.followAllowedIntervalsFromLeadTrack}
                        onToggle={(on) => onUpdateAllowedInterval(interval, on)}
                        events={events}
                      />
                    ))}
                  </div>
                  <div className="trackSectionSubheader">
                    <h5>Octave Weights</h5>
                  </div>
                  <WeightsControl
                    weightKeys={OCTAVE_WEIGHT_KEYS}
                    weights={controls.octaveWeights}
                    onUpdateWeights={onUpdateOctaveWeights}
                    events={events}
                    noteEventHighlightKey="octave"
                  />
                </>
              )}
              {controls.melodyType === "shaped" && (
                <>
                  <div className="track--melody--vis">
                    <ShapedMelodyVisualiser
                      id={id}
                      controls={controls}
                      events={events}
                    />
                  </div>
                  <div className="track--melody--shapeGamut">
                    LFO Gamut:{" "}
                    <select
                      className="select"
                      value={findIndex(
                        melodyShapeGamutOptions,
                        controls.melodyShapeMin
                      )}
                      disabled={melodyShapeGamutOptions.length === 0}
                      onChange={(evt) =>
                        onSetMelodyShapeMin(+evt.currentTarget.value)
                      }
                    >
                      {melodyShapeGamutOptions.map((o, i) => (
                        <option key={i} value={i}>
                          {getMelodyShapeGamutOptionLabel(o, controls)}
                        </option>
                      ))}
                    </select>{" "}
                    -{" "}
                    <select
                      className="select"
                      value={findIndex(
                        melodyShapeGamutOptions,
                        controls.melodyShapeMax
                      )}
                      disabled={melodyShapeGamutOptions.length === 0}
                      onChange={(evt) =>
                        onSetMelodyShapeMax(+evt.currentTarget.value)
                      }
                    >
                      {melodyShapeGamutOptions.map((o, i) => (
                        <option key={i} value={i}>
                          {getMelodyShapeGamutOptionLabel(o, controls)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <LFOControls<TrackLFO>
                    id={id}
                    controls={controls}
                    prop="melodyShapeLfos"
                    onSetControls={onSetControls}
                  />
                </>
              )}
            </div>
          </div>
          <div className="trackSection trackSection--timeSignature">
            <div className="trackSectionHeader">
              <h4
                className={classNames({
                  isEnabled: controls.overridetimeSignature,
                })}
              >
                <label className="toggleSwitchLabel">
                  <ToggleSwitch
                    checked={controls.overridetimeSignature}
                    onToggle={(checked) => {
                      onSetControls(id, (c) => ({
                        ...c,
                        overridetimeSignature: checked,
                      }));
                    }}
                  />
                  Override Time Signature
                </label>
              </h4>
            </div>
            <div className="trackSectionBody">
              {controls.overridetimeSignature && (
                <TimeSignatureControl
                  numerator={controls.timeSignatureNumerator}
                  denominator={controls.timeSignatureDenominator}
                  onUpdateNumerator={(n) =>
                    onSetControls(id, (c) => ({
                      ...c,
                      timeSignatureNumerator: n,
                    }))
                  }
                  onUpdateDenominator={(d) =>
                    onSetControls(id, (c) => ({
                      ...c,
                      timeSignatureDenominator: d,
                    }))
                  }
                />
              )}
            </div>
          </div>
          <div className="trackSection trackSection--useAccentControl">
            <div className="trackSectionHeader">
              <UseAccentToggle
                on={controls.useAccentVelocity}
                onToggle={(on) =>
                  onSetControls(id, (c) => ({ ...c, useAccentVelocity: on }))
                }
                events={events}
              />
            </div>
          </div>
          <div className="trackSection trackSection--beatDivisionWeights">
            <div className="trackSectionHeader">
              <h4>
                {controls.beatDivisionType === "weights" ? (
                  <>Beat Division Weights</>
                ) : controls.beatDivisionType === "euclidean" ? (
                  <>Euclidean Beat Division</>
                ) : (
                  <>Adwar Beat Division</>
                )}
              </h4>
              {id !== "1" && (
                <label className="followToggle">
                  Follow Track 1
                  <ToggleSwitch
                    checked={controls.followBeatDivisionWeightsFromLeadTrack}
                    onToggle={(on) =>
                      onSetControls(id, (c) => ({
                        ...c,
                        followBeatDivisionWeightsFromLeadTrack: on,
                        adwarEditorOpen: c.adwarEditorOpen && !on,
                      }))
                    }
                  />
                </label>
              )}
            </div>
            <div className="trackSectionBody">
              <div className="trackSection trackSection--activeBeatDivisionControls">
                {controls.beatDivisionType !== "euclidean" && (
                  <button
                    className="button button--small track--switchVisibleBeatDivisionWeights"
                    onClick={() =>
                      onSetControls(id, (c) => ({
                        ...c,
                        beatDivisionType: "euclidean",
                        adwarEditorOpen: false,
                      }))
                    }
                    disabled={controls.followBeatDivisionWeightsFromLeadTrack}
                  >
                    Euclidean
                  </button>
                )}
                {controls.beatDivisionType !== "weights" && (
                  <button
                    className="button button--small track--switchVisibleBeatDivisionWeights"
                    onClick={() =>
                      onSetControls(id, (c) => ({
                        ...c,
                        beatDivisionType: "weights",
                        adwarEditorOpen: false,
                      }))
                    }
                    disabled={controls.followBeatDivisionWeightsFromLeadTrack}
                  >
                    Weights
                  </button>
                )}
                {controls.beatDivisionType !== "adwar" && (
                  <button
                    className="button button--small track--switchVisibleBeatDivisionWeights"
                    onClick={() =>
                      onSetControls(id, (c) => ({
                        ...c,
                        beatDivisionType: "adwar",
                      }))
                    }
                    disabled={controls.followBeatDivisionWeightsFromLeadTrack}
                  >
                    Adwar
                  </button>
                )}
                {controls.beatDivisionType === "weights" &&
                  visibleBeatDivisionWeights === "Longer" && (
                    <button
                      className="button button--small track--switchVisibleBeatDivisionWeights"
                      onClick={() => setVisibleBeatDivisionWeights("Shorter")}
                      disabled={controls.followBeatDivisionWeightsFromLeadTrack}
                    >
                      Shorter
                    </button>
                  )}
                {controls.beatDivisionType === "weights" &&
                  visibleBeatDivisionWeights === "Shorter" && (
                    <button
                      className="button button--small track--switchVisibleBeatDivisionWeights"
                      onClick={() => setVisibleBeatDivisionWeights("Longer")}
                      disabled={controls.followBeatDivisionWeightsFromLeadTrack}
                    >
                      Longer
                    </button>
                  )}
              </div>
              {controls.beatDivisionType === "weights" && (
                <>
                  <WeightsControl
                    weightKeys={
                      BEAT_DIVISION_WEIGTH_KEYS[visibleBeatDivisionWeights]
                    }
                    weights={controls.beatDivisionWeights}
                    onUpdateWeights={onUpdateBeatDivisionWeights}
                    disabled={controls.followBeatDivisionWeightsFromLeadTrack}
                    events={events}
                    noteEventHighlightKey="beatDivision"
                  />
                  <div className="track--beatDivisionRestToggles">
                    <div className="track--beatDivisionRestToggles-inputs">
                      {BEAT_DIVISION_WEIGTH_KEYS[
                        visibleBeatDivisionWeights
                      ].map((t) => (
                        <ToggleButton
                          key={t}
                          checked={
                            controls.beatDivisionRestToggles.find(
                              (tog) => tog.division === t
                            )!.enabled
                          }
                          disabled={
                            controls.followBeatDivisionWeightsFromLeadTrack
                          }
                          onToggle={(checked) =>
                            onUpdateBeatDivisionRestToggle(t, checked)
                          }
                        />
                      ))}
                    </div>
                    Allow rests
                  </div>
                  <label className="track--forceBeatTupletsControl">
                    <ToggleSwitch
                      checked={controls.forceTuplets}
                      disabled={controls.followBeatDivisionWeightsFromLeadTrack}
                      onToggle={(checked) => {
                        onSetControls(id, (c) => ({
                          ...c,
                          forceTuplets: checked,
                        }));
                      }}
                    />{" "}
                    Force tuplets
                  </label>
                </>
              )}
              {controls.beatDivisionType === "euclidean" && (
                <div className="track--euclideanControls">
                  <div className="track--euclideanInputs">
                    <div className="track--euclideanRange">
                      <RangeSlider
                        voiceCount={controls.voiceCount}
                        minValue={controls.beatDivisionEuclideanK}
                        maxValue={controls.beatDivisionEuclideanN}
                        minMinValue={0}
                        maxMaxValue={16}
                        step={1}
                        immediate
                        disabled={
                          controls.followBeatDivisionWeightsFromLeadTrack
                        }
                        onUpdate={onUpdateEuclideanRange}
                      />
                      k={controls.beatDivisionEuclideanK}, n=
                      {controls.beatDivisionEuclideanN}
                    </div>
                    <label className="track--euclideanBeatValue">
                      beat =
                      <select
                        className="select"
                        value={controls.beatDivisionEuclideanBeatValue}
                        disabled={
                          controls.followBeatDivisionWeightsFromLeadTrack
                        }
                        onChange={(evt) => {
                          let beatDivisionEuclideanBeatValue = evt.target
                            .value as NoteValue;
                          onSetControls(id, (c) => ({
                            ...c,
                            beatDivisionEuclideanBeatValue,
                          }));
                        }}
                      >
                        <option value="1">1</option>
                        <option value="1/2">1/2</option>
                        <option value="1/4">1/4</option>
                        <option value="1/8">1/8</option>
                        <option value="1/16">1/16</option>
                      </select>
                    </label>
                  </div>
                  <EuclideanVisualizer
                    k={controls.beatDivisionEuclideanK}
                    n={controls.beatDivisionEuclideanN}
                    events={events}
                  />
                </div>
              )}
              {controls.beatDivisionType === "adwar" && (
                <>
                  <div className="track--adwarTuningSystem">
                    {controls.beatDivisionAdwarTuningSystem ? (
                      <Link
                        to={getLeimmaLink(
                          controls.beatDivisionAdwarTuningSystem,
                          controls.beatDivisionAdwarScale
                        )}
                        target="_blank"
                        className="track--adwarTuningSystem--name"
                      >
                        {controls.beatDivisionAdwarTuningSystem.name}
                      </Link>
                    ) : (
                      <span className="track--adwarTuningSystem--name">
                        Using same tuning as track
                      </span>
                    )}
                    <button
                      onClick={() => onPickTuningSystem(id, true)}
                      className="button button--small track--adwarTuningSystem--changeButton"
                      disabled={controls.followBeatDivisionWeightsFromLeadTrack}
                    >
                      {controls.beatDivisionAdwarTuningSystem ? (
                        <>Switch</>
                      ) : (
                        <>Override</>
                      )}
                    </button>
                  </div>
                  <div className="track--adwarScale">
                    <ScalePicker
                      className="select"
                      scales={adwarScaleOptions || []}
                      currentScaleId={controls.beatDivisionAdwarScale?.id}
                      label="Select Subset"
                      disabled={
                        controls.followBeatDivisionWeightsFromLeadTrack ||
                        !controls.beatDivisionAdwarTuningSystem
                      }
                      onPickScale={(scale) => pickScale(scale, true)}
                    />
                  </div>
                  <AdwarMiniDisplay
                    trackId={id}
                    controls={controls}
                    events={events}
                    disabled={controls.followBeatDivisionWeightsFromLeadTrack}
                    onToggleEdit={() =>
                      onSetControls(id, (c) => ({
                        ...c,
                        adwarEditorOpen: !c.adwarEditorOpen,
                      }))
                    }
                  />
                </>
              )}
            </div>
          </div>
          <div className="trackSection trackSection--looper">
            <div className="trackSectionHeader">
              <h4 className="track--loopHeading">Loop</h4>
            </div>
            <div className="trackSectionBody">
              <Looper loop={controls.looper} onSetLoop={onUpdateLooper} />
            </div>
          </div>
          <div className="trackSection trackSection--beatDelayRange">
            <div className="trackSectionHeader">
              <h4>Note Delay</h4>
            </div>
            <div className="trackSectionBody">
              <RangeSlider
                voiceCount={controls.voiceCount}
                minValue={controls.minBeatDelay}
                maxValue={controls.maxBeatDelay}
                minMinValue={MIN_BEAT_DELAY}
                maxMaxValue={MAX_BEAT_DELAY}
                onUpdate={onUpdateBeatDelayRange}
                events={events}
                noteEventValueKey="delay"
              />
              {round(controls.minBeatDelay)}-{round(controls.maxBeatDelay)}
              ms
            </div>
          </div>
          <div className="trackSection trackSection--articulation">
            <div className="trackSectionHeader">
              <h4>
                {controls.activeArticulation === "minMax" ? (
                  <>Articulation Range</>
                ) : (
                  <>Note Lengths</>
                )}{" "}
              </h4>
              {controls.activeArticulation === "minMax" && (
                <button
                  className="button button--inline"
                  onClick={switchToBeatDivisionBasedArticulation}
                >
                  Lengths
                </button>
              )}
              {controls.activeArticulation === "beatDivisions" && (
                <>
                  {visibleArticulationDivisions === "Longer" && (
                    <button
                      className="button button--inline track--switchVisibleArticulationWeights"
                      onClick={() => setVisibleArticulationDivisions("Shorter")}
                    >
                      Shorter
                    </button>
                  )}
                  {visibleArticulationDivisions === "Shorter" && (
                    <button
                      className="button button--inline track--switchVisibleArticulationWeights"
                      onClick={() => setVisibleArticulationDivisions("Longer")}
                    >
                      Longer
                    </button>
                  )}
                  <button
                    className="button button--inline track--switchActiveArticulation"
                    onClick={switchToMinMaxArticulation}
                  >
                    Range
                  </button>
                </>
              )}
            </div>
            <div className="trackSectionBody">
              {controls.activeArticulation === "minMax" && (
                <>
                  <RangeSlider
                    voiceCount={controls.voiceCount}
                    minValue={controls.minNoteLength}
                    maxValue={controls.maxNoteLength}
                    minMinValue={MIN_NOTE_LENGTH}
                    maxMaxValue={MAX_NOTE_LENGTH}
                    onUpdate={onUpdateNoteLengthRange}
                    events={events}
                    noteEventValueKey="noteLength"
                  />
                  <div className="track--noteLengthRange--labels">
                    <span>Staccato</span>
                    <span>Legato</span>
                  </div>
                </>
              )}
              {controls.activeArticulation === "beatDivisions" && (
                <WeightsControl
                  weightKeys={
                    BEAT_DIVISION_WEIGTH_KEYS[visibleArticulationDivisions]
                  }
                  weights={controls.noteLengths}
                  onUpdateWeights={onUpdateDivisionNoteLengths}
                  events={events}
                  noteEventHighlightKey="noteLength"
                />
              )}
            </div>
          </div>
          <div className="trackSection trackSection--velocityWeights">
            <div className="trackSectionHeader">
              <h4>Velocity Range</h4>
            </div>
            <div className="trackSectionBody">
              <RangeSlider
                voiceCount={controls.voiceCount}
                minValue={controls.minVelocity}
                maxValue={controls.maxVelocity}
                onUpdate={onUpdateVelocityRange}
                events={events}
                noteEventValueKey="baseVelocity"
              />
            </div>
          </div>
          <div className="trackSection trackSection--noteEcho">
            <div className="trackSectionHeader">
              <h4
                className={classNames({
                  isEnabled: controls.noteEchoOn,
                })}
              >
                <label className="toggleSwitchLabel">
                  <ToggleSwitch
                    checked={controls.noteEchoOn}
                    onToggle={(checked) => {
                      onSetControls(id, (c) => ({
                        ...c,
                        noteEchoOn: checked,
                      }));
                    }}
                  />
                  Note Echo
                </label>
              </h4>
            </div>
            <div className="trackSectionBody">
              {controls.noteEchoOn && (
                <div className="track--noteEchoControls">
                  <KnobControl
                    label="Time"
                    value={ALL_BEAT_DIVISION_WEIGHT_KEYS.indexOf(
                      controls.noteEchoTime
                    )}
                    min={0}
                    max={ALL_BEAT_DIVISION_WEIGHT_KEYS.length - 1}
                    step={1}
                    valueFunction={(v) => ALL_BEAT_DIVISION_WEIGHT_KEYS[v]}
                    onUpdateValue={onSetNoteEchoTime}
                  />
                  <KnobControl
                    label="Send"
                    value={controls.noteEchoFeed}
                    min={MIN_NOTE_ECHO_FEED}
                    max={MAX_NOTE_ECHO_FEED}
                    step={0.01}
                    onUpdateValue={onSetNoteEchoFeed}
                  />
                  <KnobControl
                    label="Feedback"
                    value={controls.noteEchoFeedback}
                    min={MIN_NOTE_ECHO_FEEDBACK}
                    max={MAX_NOTE_ECHO_FEEDBACK}
                    step={0.01}
                    onUpdateValue={onSetNoteEchoFeedback}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="trackSection trackSection--toneControls">
            <div className="trackSectionHeader">
              <h4>Instrument</h4>
            </div>
            <div className="trackSectionBody">
              <div className="inputGroup inputGroup--selectHorizontal">
                <label>Instrument</label>
                <select
                  className="track--instrumentSelect select"
                  value={controls.instrument}
                  onChange={(evt) => {
                    let instrument = evt.target.value as TrackInstrument;
                    onSetControls(id, (c) => ({
                      ...c,
                      instrument,
                      pluginGUIOpen: false,
                    }));
                  }}
                >
                  <option value="basicSynth">Basic Synth</option>
                  <option value="string">Karplus-Strong</option>
                  <option value="obxd">OBXD</option>
                  <option value="dexed">DEXED</option>
                  <option value="yoshimi">Yoshimi</option>
                  <option value="midi">MIDI Out</option>
                </select>
              </div>
              {controls.instrument === "basicSynth" && (
                <>
                  <div className="inputGroup--selectHorizontal">
                    <label>Wave</label>
                    <select
                      className="select"
                      value={controls.tone}
                      onChange={(evt) => {
                        let tone = evt.target.value as BasicTone;
                        onSetControls(id, (c) => ({ ...c, tone }));
                      }}
                    >
                      <option value="sine">Sine</option>
                      <option value="sawtooth">Sawtooth</option>
                      <option value="triangle">Triangle</option>
                      <option value="square">Square</option>
                    </select>
                  </div>
                  <EnvelopeControl
                    label="Amp Env"
                    envelope={controls.amplitudeEnvelope}
                    onUpdateEnvelope={onUpdateAmplitudeEnvelope}
                  />
                  <div className="track--toneFilterControls">
                    <KnobControl
                      label="Filter Freq"
                      valueSuffix="Hz"
                      value={controls.filterFrequency}
                      min={MIN_BASIC_SYNTH_FILTER_FREQUENCY}
                      max={MAX_BASIC_SYNTH_FILTER_FREQUENCY}
                      step={1}
                      onUpdateValue={onUpdateFilterFrequency}
                    />
                    <KnobControl
                      label="Filter Reso"
                      value={controls.filterResonance}
                      min={MIN_BASIC_SYNTH_FILTER_RESONANCE}
                      max={MAX_BASIC_SYNTH_FILTER_RESONANCE}
                      step={0.1}
                      onUpdateValue={onUpdateFilterResonance}
                    />
                  </div>
                </>
              )}
              {controls.instrument === "obxd" && (
                <div>
                  <div className="track--instrumentControl">
                    <div className="inputGroup--selectHorizontal">
                      <label>Bank</label>
                      <select
                        className="select"
                        value={controls.obState.bank}
                        onChange={(evt) => {
                          let bank = +evt.target.value;
                          onSetControls(id, (c) => ({
                            ...c,
                            obState: {
                              bank,
                              preset: 0,
                              patchState:
                                instrumentBanks.OBXD[
                                  bank
                                ].presets[0].patch.slice(),
                            },
                          }));
                        }}
                      >
                        {instrumentBanks.OBXD.map((bank, i) => (
                          <option key={i} value={i}>
                            {bank.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="track--instrumentControl">
                    <div className="inputGroup--selectHorizontal">
                      <label>Preset</label>
                      <select
                        className="select"
                        value={controls.obState.preset}
                        onChange={(evt) => {
                          let preset = +evt.target.value;
                          onSetControls(id, (c) => ({
                            ...c,
                            obState: {
                              bank: c.obState.bank,
                              preset,
                              patchState:
                                instrumentBanks.OBXD[c.obState.bank].presets[
                                  preset
                                ].patch.slice(),
                            },
                          }));
                        }}
                      >
                        {instrumentBanks.OBXD[
                          controls.obState.bank
                        ].presets.map((preset, index) => (
                          <option key={index} value={index}>
                            {preset.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {!controls.pluginGUIOpen && (
                    <button
                      className="button button--small"
                      onClick={() =>
                        onSetControls(id, (c) => ({
                          ...c,
                          pluginGUIOpen: true,
                        }))
                      }
                    >
                      Open OBXD GUI
                    </button>
                  )}
                  {controls.pluginGUIOpen && (
                    <button
                      className="button"
                      onClick={() =>
                        onSetControls(id, (c) => ({
                          ...c,
                          pluginGUIOpen: false,
                        }))
                      }
                    >
                      Close OBXD GUI
                    </button>
                  )}
                </div>
              )}
              {controls.instrument === "dexed" && (
                <div>
                  <div className="track--instrumentControl">
                    <div className="inputGroup--selectHorizontal">
                      <label>Bank</label>
                      <select
                        className="select"
                        value={controls.dxState.bank}
                        onChange={(evt) => {
                          let bank = +evt.target.value;
                          onSetControls(id, (c) => ({
                            ...c,
                            dxState: {
                              bank,
                              preset: 0,
                              patchState:
                                instrumentBanks.DEXED[
                                  bank
                                ].presets[0].patch.slice(),
                            },
                          }));
                        }}
                      >
                        {instrumentBanks.DEXED.map((bank, i) => (
                          <option key={i} value={i}>
                            {bank.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="track--instrumentControl">
                    <div className="inputGroup--selectHorizontal">
                      <label>Preset</label>
                      <select
                        className="select"
                        value={controls.dxState.preset}
                        onChange={(evt) => {
                          let preset = +evt.target.value;
                          onSetControls(id, (c) => ({
                            ...c,
                            dxState: {
                              bank: c.dxState.bank,
                              preset,
                              patchState:
                                instrumentBanks.DEXED[c.dxState.bank].presets[
                                  preset
                                ].patch.slice(),
                            },
                          }));
                        }}
                      >
                        {instrumentBanks.DEXED[
                          controls.dxState.bank
                        ].presets.map((preset, index) => (
                          <option key={index} value={index}>
                            {preset.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {!controls.pluginGUIOpen && (
                    <button
                      className="button"
                      onClick={() =>
                        onSetControls(id, (c) => ({
                          ...c,
                          pluginGUIOpen: true,
                        }))
                      }
                    >
                      Open DEXED GUI
                    </button>
                  )}
                  {controls.pluginGUIOpen && (
                    <button
                      className="button"
                      onClick={() =>
                        onSetControls(id, (c) => ({
                          ...c,
                          pluginGUIOpen: false,
                        }))
                      }
                    >
                      Close DEXED GUI
                    </button>
                  )}
                </div>
              )}
              {controls.instrument === "yoshimi" && (
                <div>
                  <div className="track--instrumentControl">
                    <div className="inputGroup--selectHorizontal">
                      <label>Bank</label>
                      <select
                        className="select"
                        value={controls.yoshimiState.bank}
                        onChange={(evt) => {
                          let bank = +evt.target.value;
                          onSetControls(id, (c) => ({
                            ...c,
                            yoshimiState: {
                              bank,
                              preset: 0,
                            },
                          }));
                        }}
                      >
                        {instrumentBanks.Yoshimi.map((bank, i) => (
                          <option key={i} value={i}>
                            {bank.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="track--instrumentControl">
                    <div className="inputGroup--selectHorizontal">
                      <label>Preset</label>
                      <select
                        className="select"
                        value={controls.yoshimiState.preset}
                        onChange={(evt) => {
                          let preset = +evt.target.value;
                          onSetControls(id, (c) => ({
                            ...c,
                            yoshimiState: { bank: c.yoshimiState.bank, preset },
                          }));
                        }}
                      >
                        {instrumentBanks.Yoshimi[
                          controls.yoshimiState.bank
                        ].presets.map((preset, index) => (
                          <option key={index} value={index}>
                            {preset.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {controls.instrument === "midi" && (
                <div className="track--instrumentControl">
                  <div className="inputGroup--selectHorizontal">
                    <label>MIDI Out</label>
                    <MIDIOutput
                      track={id}
                      showInternalOptions={false}
                      productName="Apotome"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="trackSection trackSection--lfos">
            <div className="trackSectionHeader">
              <h4>LFOs</h4>
            </div>
            <div className="trackSectionBody">
              <LFOControls<TrackModulatorLFO>
                id={id}
                controls={controls}
                prop="lfos"
                onSetControls={onSetControls}
              />
            </div>
          </div>
          <div className="trackSection trackSection--mixControls">
            <div className="trackSectionHeader">
              <h4>Mixer</h4>
            </div>
            <div className="trackSectionBody">
              <KnobControl
                label="Volume"
                valueSuffix="dB"
                value={controls.volume}
                min={MIN_VOLUME_DB}
                max={MAX_VOLUME_DB}
                step={0.01}
                valueFunction={(v) =>
                  v === MIN_VOLUME_DB ? "-Inf" : `${round(v, 1)}`
                }
                disabled={controls.instrument === "midi"}
                onUpdateValue={onUpdateVolume}
              />
              <PanControl
                label="Pan"
                value={controls.pan}
                onUpdateValue={onUpdatePan}
                disabled={controls.instrument === "midi"}
              />
              <KnobControl
                label="Reverb Send"
                value={controls.send1Gain}
                min={0}
                max={1}
                step={0.01}
                disabled={controls.instrument === "midi"}
                onUpdateValue={onUpdateSend1Gain}
              />
              <KnobControl
                label="Delay Send"
                value={controls.send2Gain}
                min={0}
                max={1}
                step={0.01}
                disabled={controls.instrument === "midi"}
                onUpdateValue={onUpdateSend2Gain}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export let getScaleDegreeWeightKeys = memoize(
  (scale?: Scale) => {
    return scale
      ? range(scale.scaleDegrees.length)
          .map((i) => `${i + 1}`)
          .concat(["Rest"])
      : ["Rest"];
  },
  (scale?: Scale) => scale?.id
);

function getMelodyShapeGamuOptions(scale?: Scale) {
  let scaleSize = scale?.scaleDegrees.length ?? 0;
  return flatMap(range(MIN_OCTAVE, MAX_OCTAVE + 1), (octave) =>
    range(0, scaleSize).map((index) => ({
      octave,
      index,
    }))
  );
}

function getMelodyShapeGamutOptionLabel(
  { octave, index }: { octave: number; index: number },
  controls: TrackControls
) {
  let scale = getTrackScaleDegrees(controls);
  let mapping = scale[index].keyboardMapping;
  return `${SCALE_DEGREE_NAMES_ENGLISH[mapping]}${octave}`;
}

export function syncScaleDegreeWeightsAndAllowedIntervalsWithScale(
  controls: TrackControls
) {
  if (controls.scale) {
    let scaleDegreeWeights = controls.scaleDegreeWeights;
    for (let k of getScaleDegreeWeightKeys(controls.scale)) {
      if (!(k in scaleDegreeWeights)) {
        scaleDegreeWeights = { ...scaleDegreeWeights, [k]: 0 };
      }
    }
    let allowedIntervals = (
      range(0, controls.scale.scaleDegrees.length) as (number | "8ve")[]
    )
      .concat(["8ve"])
      .map((interval) => ({
        interval,
        allowed:
          controls.allowedIntervals.find((i) => i.interval === interval)
            ?.allowed ?? true,
      }));
    let melodyShapeMin = controls.melodyShapeMin ?? { octave: 2, index: 0 };
    melodyShapeMin = {
      ...melodyShapeMin,
      index: Math.min(
        melodyShapeMin.index,
        controls.scale.scaleDegrees.length - 1
      ),
    };
    let melodyShapeMax = controls.melodyShapeMax ?? { octave: 5, index: 0 };
    melodyShapeMax = {
      ...melodyShapeMax,
      index: Math.min(
        melodyShapeMax.index,
        controls.scale.scaleDegrees.length - 1
      ),
    };
    return {
      ...controls,
      allowedIntervals,
      scaleDegreeWeights,
      melodyShapeMin,
      melodyShapeMax,
    };
  } else {
    return controls;
  }
}
