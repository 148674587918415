import { getTransport } from "tone";
import {
  NoteValue,
  ReturnTrackSettings,
  TrackControls,
  TrackInstrument,
} from "./types";

export const LFO_TARGET_OPTIONS: {
  [instr in TrackInstrument | "track"]: { param: number; label: string }[];
} = {
  string: [],
  basicSynth: [
    { label: "Filter Freq", param: 0 },
    { label: "Filter Reso", param: 1 },
  ],
  dexed: [
    { label: "1: Coarse", param: 32 },
    { label: "1: Level", param: 30 },
    { label: "2: Coarse", param: 53 },
    { label: "2: Level", param: 51 },
    { label: "3: Coarse", param: 74 },
    { label: "3: Level", param: 72 },
    { label: "4: Coarse", param: 95 },
    { label: "4: Level", param: 93 },
    { label: "5: Coarse", param: 116 },
    { label: "5: Level", param: 114 },
    { label: "6: Coarse", param: 137 },
    { label: "6: Level", param: 135 },
    { label: "Algorithm", param: 3 },
    { label: "Feedback", param: 4 },
    { label: "Speed", param: 6 },
    { label: "PMD", param: 8 },
    { label: "AMD", param: 9 },
  ],
  midi: [
    { label: "CC 0: Bank Select", param: 0 },
    { label: "CC 1: Modulation Wheel", param: 1 },
    { label: "CC 2: Breath Controller", param: 2 },
    { label: "CC 4: Foot Controller", param: 4 },
    { label: "CC 5: Portamento Time", param: 5 },
    { label: "CC 7: Main Volume", param: 7 },
    { label: "CC 8: Balance", param: 8 },
    { label: "CC 10: Pan", param: 10 },
    { label: "CC 11: Expression", param: 11 },
    { label: "CC 12: Effect Control 1", param: 12 },
    { label: "CC 13: Effect Control 2", param: 13 },
    { label: "CC 16: General Purpose Controller 1", param: 16 },
    { label: "CC 17: General Purpose Controller 2", param: 17 },
    { label: "CC 18: General Purpose Controller 3", param: 18 },
    { label: "CC 19: General Purpose Controller 4", param: 19 },
    { label: "CC 64: Sustain", param: 64 },
    { label: "CC 65: Portamento", param: 65 },
    { label: "CC 66: Sostenuto", param: 66 },
    { label: "CC 67: Soft Pedal", param: 67 },
    { label: "CC 68: Legato Footswitch", param: 68 },
    { label: "CC 69: Hold 2", param: 69 },
    { label: "CC 70: Sound Controller 1", param: 70 },
    { label: "CC 71: Sound Controller 2", param: 71 },
    { label: "CC 72: Sound Controller 3", param: 72 },
    { label: "CC 73: Sound Controller 4", param: 73 },
    { label: "CC 74: Sound Controller 5", param: 74 },
    { label: "CC 75: Sound Controller 6", param: 75 },
    { label: "CC 76: Sound Controller 7", param: 76 },
    { label: "CC 77: Sound Controller 8", param: 77 },
    { label: "CC 78: Sound Controller 9", param: 78 },
    { label: "CC 79: Sound Controller 10", param: 79 },
    { label: "CC 80: General Purpose Controller 5", param: 80 },
    { label: "CC 81: General Purpose Controller 6", param: 81 },
    { label: "CC 82: General Purpose Controller 7", param: 82 },
    { label: "CC 83: General Purpose Controller 8", param: 83 },
    { label: "CC 84: Portamento Control", param: 84 },
    { label: "CC 91: Effects 1 Depth", param: 91 },
    { label: "CC 92: Effects 2 Depth", param: 92 },
    { label: "CC 93: Effects 3 Depth", param: 93 },
    { label: "CC 94: Effects 4 Depth", param: 94 },
    { label: "CC 95: Effects 5 Depth", param: 95 },
  ],
  obxd: [
    { label: "Spread", param: 15 },
    { label: "Oscillators: PW", param: 37 },
    { label: "Oscillators: Detune", param: 16 },
    { label: "Oscillators: Xmod", param: 29 },
    { label: "Oscillators: Bright", param: 38 },
    { label: "Mix: OSC1", param: 40 },
    { label: "Mix: OSC2", param: 41 },
    { label: "Mix: Noise", param: 42 },
    { label: "Filter: Cutoff", param: 44 },
    { label: "Filter: Reso", param: 46 },
    { label: "Modulation: Rate", param: 17 },
    { label: "Modulation: Pitch", param: 21 },
    { label: "Modulation: PWM", param: 22 },
  ],
  yoshimi: [],
  track: [
    { label: "Track: Volume", param: 10000 },
    { label: "Track: Pan", param: 10001 },
    { label: "Track: Send 1", param: 10002 },
    { label: "Track: Send 2", param: 10003 },
  ],
};

export const DEFAULT_TEMPO = 100;

export const DEFAULT_TIME_SIGNATURE_NUMERATOR = "4";
export const DEFAULT_TIME_SIGNATURE_DENOMINATOR = 4;

export const SYNCED_ECHO_OPTIONS = [
  "32n",
  "32n.",
  "16n",
  "16n.",
  "8n",
  "8n.",
  "4n",
  "4n.",
  "2n",
  "2n.",
  "1n",
];

export const MIN_VOLUME_DB = -36;
export const MAX_VOLUME_DB = 0;

export const DEFAULT_RETURN_TRACK_SETTINGS: ReturnTrackSettings = {
  reverbDecay: 3,
  reverbPreDelay: 0,
  echoTempoSync: true,
  echoDelayLeftSynced: 5,
  echoDelayRightSynced: 5,
  echoDelayLeftFree: 100,
  echoDelayRightFree: 100,
  echoFeedback: 0.6,
};

export const NOTE_VALUE_BEAT_VALUES: { [div in NoteValue]: number } = {
  "1": 1,
  "1/2": 1 / 2,
  "1/3": 1 / 3,
  "1/4": 1 / 4,
  "1/5": 1 / 5,
  "1/6": 1 / 6,
  "1/7": 1 / 7,
  "1/8": 1 / 8,
  "1/9": 1 / 9,
  "1/10": 1 / 10,
  "1/11": 1 / 11,
  "1/12": 1 / 12,
  "1/13": 1 / 13,
  "1/14": 1 / 14,
  "1/15": 1 / 15,
  "1/16": 1 / 16,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  "11": 11,
  "12": 12,
  "13": 13,
  "14": 14,
  "15": 15,
  "16": 16,
};

export const BEAT_DIVISION_TICKS = Object.fromEntries(
  Object.entries(NOTE_VALUE_BEAT_VALUES).map(([key, val]) => [
    key,
    getTransport().toTicks({ "1n": val }),
  ])
);

export const BEAT_DIVISION_TUPLETS: { [div: string]: number } = {
  "1/3": 3,
  "1/5": 5,
  "1/6": 3,
  "1/7": 7,
  "1/9": 3,
  "1/10": 5,
  "1/11": 11,
  "1/12": 3,
  "1/13": 13,
  "1/14": 7,
  "1/15": 5,
  "1/16": 4,
};

export const TUNING_LEADER_FOLLOWING_CONTROLS: (keyof TrackControls)[] = [
  "tuningSystem",
  "scale",
];
export const SCALE_WEIGHT_LEADER_FOLLOWING_CONTROLS: (keyof TrackControls)[] = [
  "roleWeights",
  "scaleDegreeWeights",
  "activeScaleWeights",
];
export const ALLOWED_INTERVALS_LEADER_FOLLOWER_CONTROLS: (keyof TrackControls)[] =
  ["allowedIntervals" /*, "forcePolyphony"*/];
export const BEAT_DIVISION_LEADER_FOLLOWER_CONTROLS: (keyof TrackControls)[] = [
  "beatDivisionType",
  "beatDivisionWeights",
  "beatDivisionRestToggles",
  "forceTuplets",
  "beatDivisionEuclideanN",
  "beatDivisionEuclideanK",
  "beatDivisionEuclideanBeatValue",
  "beatDivisionAdwarTriggers",
];

export const MIN_BASE_VELOCITY = 0.05;
export const MAX_BASE_VELOCITY = 0.7;
export const ACCENT_VELOCITY_DELTA = 1 - MAX_BASE_VELOCITY;

export const MIN_NOTE_ECHO_FEED = 0.0;
export const MAX_NOTE_ECHO_FEED = 1.0;
export const MIN_NOTE_ECHO_FEEDBACK = 0.0;
export const MAX_NOTE_ECHO_FEEDBACK = 0.9;

export const DEFAULT_BASIC_SYNTH_FILTER_FREQUENCY = 5000;
export const MIN_BASIC_SYNTH_FILTER_FREQUENCY = 200;
export const MAX_BASIC_SYNTH_FILTER_FREQUENCY = 10000;
export const DEFAULT_BASIC_SYNTH_FILTER_RESONANCE = 2;
export const MIN_BASIC_SYNTH_FILTER_RESONANCE = 0;
export const MAX_BASIC_SYNTH_FILTER_RESONANCE = 15;

export const MAX_POLYPHONY = Number.MAX_VALUE;

export const MIN_NEGATIVE_NOTE_DELAY_S = -0.05;

export const MIN_VOICE_COUNT = 1;
export const MAX_VOICE_COUNT = 8;
