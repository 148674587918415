import React from "react";
import { MelodyVisualiser, MelodyVisualiserProps } from "./MelodyVisualiser";
import { min, max } from "lodash";

type RandomWalkMelodyVisualiserProps = Omit<
  MelodyVisualiserProps,
  "drawShapeCurve" | "min" | "max"
>;

export const RandomWalkMelodyVisualiser: React.FC<
  RandomWalkMelodyVisualiserProps
> = (props) => {
  let nonZeroOctaves = Object.entries(props.controls.octaveWeights)
    .filter(([_, w]) => w > 0)
    .map(([o]) => parseInt(o));
  let minOctave = min(nonZeroOctaves) ?? 0;
  let maxOctave = max(nonZeroOctaves) ?? 0;
  return (
    <MelodyVisualiser
      {...props}
      drawShapeCurve
      min={{ octave: minOctave, index: 0 }}
      max={{
        octave: maxOctave,
        index: (props.controls.scale?.scaleDegrees.length ?? 1) - 1,
      }}
    />
  );
};
