import { useState, useRef } from "react";

export default function useLongPressDrag(
  isActive: boolean,
  onClick = () => {},
  onDragStart = () => {},
  onDrag: (delta: number) => void
) {
  const [action, setAction] = useState<"click" | "longpress" | null>(null);
  const timerRef = useRef<any>();
  const isLongPress = useRef(false);
  const longPressBaseY = useRef(0);

  function startPressTimer(atY: number) {
    isLongPress.current = false;
    timerRef.current = setTimeout(() => {
      isLongPress.current = true;
      longPressBaseY.current = atY;
      setAction("longpress");
    }, 500);
  }

  function handleOnClick() {
    if (isLongPress.current) {
      setAction(null);
      isLongPress.current = false;
    } else {
      onClick();
      setAction("click");
    }
  }

  function handleOnDrag(evt: React.PointerEvent<Element>) {
    if (isLongPress.current) {
      onDrag(evt.clientY - longPressBaseY.current);
    }
  }

  function handleOnPointerDown(evt: React.PointerEvent<Element>) {
    if (isActive) {
      startPressTimer(evt.clientY);
      evt.currentTarget.setPointerCapture(evt.pointerId);
      onDragStart();
    }
  }

  function handleOnPointerUp(evt: React.PointerEvent<Element>) {
    clearTimeout(timerRef.current);
    evt.currentTarget.releasePointerCapture(evt.pointerId);
  }

  return {
    action,
    handlers: {
      onClick: handleOnClick,
      onPointerDown: handleOnPointerDown,
      onPointerUp: handleOnPointerUp,
      onPointerMove: handleOnDrag,
    },
  };
}
