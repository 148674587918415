import { range, reduce, sortedIndexBy, transform } from "lodash";
import { Weights, mod, weightedRandom, weightedTop } from "../main/core";
import {
  PlayableScaleDegree,
  PlayedNote,
  calculateNoteFreq,
  getTrackScaleDegrees,
} from "./apotomeController";
import { TrackControls } from "./types";
import { getClosestMIDINote } from "../main/audio";
import { getMelodyCurveValue } from "./lfos";

const ALL_ALLOWED_INTERVALS: { interval: number | "8ve"; allowed: boolean }[] =
  [
    { interval: 0, allowed: true },
    { interval: 1, allowed: true },
    { interval: 2, allowed: true },
    { interval: 3, allowed: true },
    { interval: 4, allowed: true },
    { interval: 5, allowed: true },
    { interval: 6, allowed: true },
    { interval: 7, allowed: true },
    { interval: 8, allowed: true },
    { interval: 9, allowed: true },
    { interval: 10, allowed: true },
    { interval: 11, allowed: true },
    { interval: 12, allowed: true },
    { interval: 13, allowed: true },
    { interval: 14, allowed: true },
    { interval: 15, allowed: true },
    { interval: 16, allowed: true },
    { interval: "8ve", allowed: true },
  ];

const ALL_OCTAVE_WEIGHTS: Weights = {
  "0": 1,
  "1": 1,
  "2": 1,
  "3": 1,
  "4": 1,
  "5": 1,
  "6": 1,
  "7": 1,
  "8": 1,
};

export type MelodyState = {
  lastNote?: PlayedNote;
  concurrentNotes: {
    note: PlayedNote;
    forcePolyphony: "none" | "unison" | "octaves";
  }[];
  shapeCurve: { time: number; octave: number; indexFraction: number }[];
};
export function chooseNote(
  atTime: number,
  controls: TrackControls,
  allowRest: boolean,
  state: MelodyState
): {
  key: string;
  value?: {
    scaleDegree: PlayableScaleDegree;
    octave: number;
    interval: number | "8ve" | null;
  };
} {
  let scaleDegrees = getTrackScaleDegrees(controls);
  if (state.lastNote) {
    return chooseNoteBasedOnLastNote(
      atTime,
      controls,
      scaleDegrees,
      allowRest,
      state
    );
  } else {
    return chooseNoteWithNewStartingPoint(
      atTime,
      controls,
      scaleDegrees,
      allowRest,
      state
    );
  }
}

function chooseNoteBasedOnLastNote(
  atTime: number,
  controls: TrackControls,
  scaleDegrees: PlayableScaleDegree[],
  allowRest: boolean,
  state: MelodyState
) {
  let options = getNextScaleDegreeOptions(
    controls,
    scaleDegrees,
    state.lastNote!
  );

  // Pick the weights to use
  let givenWeights =
    controls.activeScaleWeights === "role"
      ? controls.roleWeights
      : controls.scaleDegreeWeights;
  // See how non-rests and rests were weighted, respectively
  let totalGivenNonRestWeight = reduce(
    givenWeights,
    (total, weight, key) => (key === "Rest" ? total : total + weight),
    0
  );
  let givenRestWeight = givenWeights.Rest;
  // Form the actual weights to choose from, given the possible intervals we could move
  let actualWeights = transform(
    options,
    (weights, option, index) =>
      (weights[`${index}`] =
        (getScaleDegreeWeight(option.scaleDegree, controls) ?? 0) *
        getMelodicWeight(
          option.scaleDegree,
          option.octave,
          atTime,
          controls,
          state
        )),
    {} as Weights
  );
  // If rests are allowed, scale the rest weight accordingly so it keeps the same relative probability as was given
  if (allowRest) {
    let totalActualNonRestWeights = Object.values(actualWeights).reduce(
      (total, weight) => total + weight,
      0
    );
    let adjustedRestWeight =
      (givenRestWeight / totalGivenNonRestWeight) * totalActualNonRestWeights;
    actualWeights.Rest = adjustedRestWeight;
  }
  // If polyphony is forced, eliminate any options that are currently already playing
  for (let i = 0; i < options.length; i++) {
    if (isExcludedByVoicing(options[i], controls, state)) {
      actualWeights[i] = 0;
    }
  }

  // If any scale degree weights are nonzero, but we can't get to them with our current intervals, consider the walk "stuck" and start from a new position
  let anyScaleDegreeAllowed = false,
    allAllowedScaleDegreesStuck = true;
  for (let i = 0; i < options.length; i++) {
    let allowed = getScaleDegreeWeight(options[i].scaleDegree, controls) > 0;
    anyScaleDegreeAllowed = anyScaleDegreeAllowed || allowed;
    if (allowed) {
      allAllowedScaleDegreesStuck =
        allAllowedScaleDegreesStuck && actualWeights[i] === 0;
    }
  }
  if (anyScaleDegreeAllowed && allAllowedScaleDegreesStuck) {
    return chooseNoteWithNewStartingPoint(
      atTime,
      controls,
      scaleDegrees,
      allowRest,
      state
    );
  }

  let selectionOptions = range(options.length).map((i) => `${i}`);
  let selectionOptionsWithRest = allowRest
    ? selectionOptions.concat(["Rest"])
    : selectionOptions;
  let selection = weightedRandom(selectionOptionsWithRest, actualWeights);
  if (selection === "Rest") {
    return { key: "Rest" };
  } else {
    selection =
      controls.melodyType === "shaped"
        ? weightedTop(selectionOptions, actualWeights)
        : weightedRandom(selectionOptions, actualWeights);
    if (selection) {
      return {
        key: getScaleDegreeWeightKey(options[+selection].scaleDegree, controls),
        value: options[+selection],
      };
    }
  }
  return { key: "Rest" };
}

function getNextScaleDegreeOptions(
  controls: TrackControls,
  scaleDegrees: PlayableScaleDegree[],
  lastNote: PlayedNote
) {
  let options: {
    scaleDegree: PlayableScaleDegree;
    octave: number;
    interval: number | "8ve";
  }[] = [];
  let allowedIntervals =
    controls.melodyType === "shaped"
      ? ALL_ALLOWED_INTERVALS
      : controls.allowedIntervals;
  for (let { interval, allowed } of allowedIntervals) {
    if (!allowed) continue;
    if (interval === "8ve") {
      options.push({
        scaleDegree: lastNote.scaleDegree,
        octave: lastNote.octave + 1,
        interval,
      });
      options.push({
        scaleDegree: lastNote.scaleDegree,
        octave: lastNote.octave - 1,
        interval,
      });
    } else {
      options.push({
        scaleDegree:
          scaleDegrees[
            mod(lastNote.scaleDegree.index + interval, scaleDegrees.length)
          ],
        octave:
          lastNote.octave +
          Math.floor(
            (lastNote.scaleDegree.index + interval) / scaleDegrees.length
          ),
        interval,
      });
      if (interval !== 0) {
        options.push({
          scaleDegree:
            scaleDegrees[
              mod(lastNote.scaleDegree.index - interval, scaleDegrees.length)
            ],
          octave:
            lastNote.octave +
            Math.ceil(
              (lastNote.scaleDegree.index - interval) / scaleDegrees.length
            ),
          interval,
        });
      }
    }
  }
  return options;
}

function getScaleDegreeWeight(
  scaleDegree: PlayableScaleDegree,
  controls: TrackControls
) {
  if (controls.activeScaleWeights === "role") {
    return controls.roleWeights[scaleDegree.role];
  } else {
    return controls.scaleDegreeWeights[`${scaleDegree.index + 1}`];
  }
}

function getScaleDegreeWeightKey(
  scaleDegree: PlayableScaleDegree,
  controls: TrackControls
) {
  if (controls.activeScaleWeights === "role") {
    return scaleDegree.role;
  } else {
    return `${scaleDegree.index + 1}`;
  }
}

function chooseNoteWithNewStartingPoint(
  atTime: number,
  controls: TrackControls,
  scaleDegrees: PlayableScaleDegree[],
  allowRest: boolean,
  state: MelodyState
) {
  if (allowRest) {
    let weights =
      controls.activeScaleWeights === "role"
        ? controls.roleWeights
        : controls.scaleDegreeWeights;
    let chooseRest = weightedRandom(Object.keys(weights), weights) === "Rest";
    if (chooseRest) {
      return { key: "Rest" };
    }
  }

  let options: {
    key: string;
    value: { scaleDegree: PlayableScaleDegree; octave: number; interval: null };
  }[] = [];
  let weights: Weights = {};
  let octaveWeights =
    controls.melodyType === "shaped"
      ? ALL_OCTAVE_WEIGHTS
      : controls.octaveWeights;
  let availableOctaves = Object.keys(octaveWeights).filter(
    (o) => octaveWeights[o] > 0
  );
  for (let scaleDegree of scaleDegrees) {
    let baseSDWeight =
      controls.activeScaleWeights === "role"
        ? controls.roleWeights[scaleDegree.role]
        : controls.scaleDegreeWeights[`${scaleDegree.index + 1}`];
    let key =
      controls.activeScaleWeights === "role"
        ? scaleDegree.role
        : "" + scaleDegree.index;
    for (let octave of availableOctaves) {
      let melodicWeight = getMelodicWeight(
        scaleDegree,
        +octave,
        atTime,
        controls,
        state
      );
      let weight = baseSDWeight * melodicWeight;
      if (
        isExcludedByVoicing({ scaleDegree, octave: +octave }, controls, state)
      ) {
        weight = 0;
      }
      weights[options.length] = weight;
      options.push({
        key,
        value: { scaleDegree, octave: +octave, interval: null },
      });
    }
  }

  let chosen = weightedRandom(
    range(options.length).map((i) => `${i}`),
    weights
  );

  if (chosen) {
    return options[+chosen];
  } else {
    return { key: "Rest" };
  }
}

function getMelodicWeight(
  scaleDegree: PlayableScaleDegree,
  octave: number,
  atTime: number,
  controls: TrackControls,
  state: MelodyState
) {
  if (controls.melodyType === "randomWalk" || !controls.melodyType) {
    return controls.octaveWeights[octave] ?? 0;
  } else {
    let curveAtIdx = sortedIndexBy(
      state.shapeCurve,
      { time: atTime, octave: -1, indexFraction: -1 },
      (c) => c.time
    );
    let curveValue = state.shapeCurve[curveAtIdx];
    if (!curveValue) curveValue = getMelodyCurveValue(atTime, 0.5, controls);
    let distance = getDistance(
      octave,
      scaleDegree.index,
      curveValue.octave,
      curveValue.indexFraction,
      controls.scale?.scaleDegrees.length ?? 0
    );
    // console.log(
    //   atTime,
    //   "cv",
    //   curveValue.octave,
    //   curveValue.indexFraction,
    //   "sd",
    //   octave,
    //   scaleDegree,
    //   "dist",
    //   distance,
    //   "w",
    //   1 / (distance ** 10 + 1)
    // );
    return 1 / (distance ** 10 + 1);
  }
}

function getDistance(
  octaveA: number,
  indexA: number,
  octaveB: number,
  indexB: number,
  numScaleDegrees: number
) {
  let absA = octaveA * numScaleDegrees + indexA;
  let absB = octaveB * numScaleDegrees + indexB;
  return Math.abs(absA - absB);
}

function isExcludedByVoicing(
  option: { scaleDegree: PlayableScaleDegree; octave: number },
  controls: TrackControls,
  state: MelodyState
) {
  let midiNote = getClosestMIDINote(
    calculateNoteFreq(controls, option),
    Number.MAX_VALUE,
    null
  );
  for (let other of state.concurrentNotes) {
    let eitherPreventsOctaves =
      controls.forcePolyphony === "octaves" ||
      other.forcePolyphony === "octaves";
    let eitherPreventsUnison =
      controls.forcePolyphony === "unison" || other.forcePolyphony === "unison";
    let oMidi = getClosestMIDINote(other.note.freq, Number.MAX_VALUE, null);
    if (eitherPreventsOctaves && midiNote % 12 === oMidi % 12) {
      return true;
    } else if (eitherPreventsUnison && midiNote === oMidi) {
      return true;
    }
  }
  return false;
}
