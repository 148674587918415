import React, { useEffect, useMemo, useRef, useState } from "react";
import useComponentSize from "@rehooks/component-size";

import * as ctrl from "./apotomeController";

import "./MelodyVisualiser.scss";
import { immediate } from "tone";
import { TrackControls } from "./types";
import { EventEmitter } from "events";
import { range } from "lodash";

type NoteHistoryItem = {
  time: number;
  duration: number;
  octave: number;
  index: number;
  velocity: number;
};
type RetriggerHistoryItem = {
  time: number;
};

export interface MelodyVisualiserProps {
  id: string;
  controls: TrackControls;
  min: { index: number; octave: number };
  max: { index: number; octave: number };
  drawShapeCurve?: boolean;
  events: EventEmitter;
}
export const MelodyVisualiser: React.FC<MelodyVisualiserProps> = ({
  id,
  controls,
  min,
  max,
  drawShapeCurve = false,
  events,
}) => {
  let [isRunning, setIsRunning] = useState(ctrl.isRunning());
  let canvasRef = useRef<HTMLCanvasElement>(null);
  let { width, height } = useComponentSize(canvasRef);
  let renderWidth = width * window.devicePixelRatio;
  let renderHeight = height * window.devicePixelRatio;

  let scale = useMemo(
    () => ctrl.getTrackScaleDegrees(controls),
    [controls.scale, controls.tuningSystem]
  );
  let scaleSize = scale.length;

  let fullScaleDegrees = [
    ...range(
      min.index,
      min.octave === max.octave ? max.index + 1 : scaleSize
    ).map((index) => ({
      index,
      octave: min.octave,
    })),
    ...(max.octave > min.octave
      ? range(min.octave + 1, max.octave).flatMap((octave) =>
          range(scaleSize).map((index) => ({ index, octave }))
        )
      : []),
    ...(min.octave === max.octave
      ? []
      : range(0, max.index + 1).map((index) => ({
          index,
          octave: max.octave,
        }))),
  ];

  useEffect(() => {
    let onStart = () => setIsRunning(true);
    let onStop = () => setIsRunning(false);
    ctrl.playbackEvents.on("start", onStart);
    ctrl.playbackEvents.on("stop", onStop);
    return () => {
      ctrl.playbackEvents.off("start", onStart);
      ctrl.playbackEvents.off("stop", onStop);
    };
  }, []);

  let noteHistory = useRef<NoteHistoryItem[]>([]);
  let retriggerHistory = useRef<RetriggerHistoryItem[]>([]);
  useEffect(() => {
    noteHistory.current = [];
    retriggerHistory.current = [];
    let onNote = (note: any) => {
      noteHistory.current.push({
        time: note.time + note.delay / 1000,
        duration: note.duration,
        octave: note.octave,
        index: note.scaleDegreeIndex,
        velocity: note.velocity,
      });
      while (
        noteHistory.current.length > 0 &&
        noteHistory.current[0].time + noteHistory.current[0].duration <
          immediate() - 5
      ) {
        noteHistory.current.shift();
      }
    };
    let onRetrigger = (retrigger: any) => {
      retriggerHistory.current.push(retrigger);
      while (
        retriggerHistory.current.length > 0 &&
        retriggerHistory.current[0].time + 0.1 < immediate() - 5
      ) {
        retriggerHistory.current.shift();
      }
    };
    events.on("note", onNote);
    events.on("lfoRetrigger", onRetrigger);
    return () => {
      events.off("note", onNote);
      events.off("lfoRetrigger", onRetrigger);
    };
  }, [events, scale]);

  useEffect(() => {
    let canvas = canvasRef.current;
    if (!canvas) return;
    let ctx = canvas.getContext("2d")!;
    let gone = false;

    let frame = () => {
      if (gone) return;
      ctx.clearRect(0, 0, renderWidth, renderHeight);
      draw(
        ctx,
        id,
        drawShapeCurve,
        noteHistory.current,
        retriggerHistory.current,
        renderWidth,
        renderHeight,
        min,
        max,
        scaleSize
      );
      if (isRunning) {
        requestAnimationFrame(frame);
      }
    };
    frame();

    return () => {
      gone = true;
    };
  }, [
    id,
    drawShapeCurve,
    renderWidth,
    renderHeight,
    min.index,
    min.octave,
    max.index,
    max.octave,
    scaleSize,
    isRunning,
  ]);

  return (
    <div className="melodyVisualiser">
      <div className="melodyVisualiser--pitchGrid">
        {fullScaleDegrees.map((v) => (
          <div
            key={`${v.octave}-${v.index}`}
            className="melodyVisualiser--pitchGridItem"
          />
        ))}
      </div>
      <canvas ref={canvasRef} width={renderWidth} height={renderHeight} />
    </div>
  );
};

function draw(
  ctx: CanvasRenderingContext2D,
  trackId: string,
  drawShapeCurve: boolean,
  noteHistory: NoteHistoryItem[],
  retriggers: RetriggerHistoryItem[],
  width: number,
  height: number,
  minScaleDegree: { index: number; octave: number },
  maxScaleDegree: { index: number; octave: number },
  scaleSize: number
) {
  let now = immediate();
  let then = now - 3;

  let scaleMin = minScaleDegree.octave * scaleSize + minScaleDegree.index;
  let scaleMax = maxScaleDegree.octave * scaleSize + maxScaleDegree.index + 1;

  ctx.strokeStyle = "#353535";
  ctx.lineWidth = 2;
  ctx.beginPath();
  for (let retrigger of retriggers) {
    let x = scale(retrigger.time, then, now, 0, width);
    ctx.moveTo(x, 0);
    ctx.lineTo(x, height);
  }
  ctx.stroke();

  if (drawShapeCurve) {
    let curve = ctrl.getTrackMelodyShapeCurve(trackId);
    ctx.strokeStyle = "rgb(180,180,180)";
    ctx.lineWidth = 2;
    ctx.beginPath();
    for (let i = curve.length - 1; i >= 0; i--) {
      let v = curve[i];
      let scaleVal = v.octave * scaleSize + v.indexFraction;
      let x = scale(v.time, then, now, 0, width);
      let y = scale(scaleVal, scaleMin - 0.5, scaleMax - 0.5, height, 0);
      if (i === curve.length - 1) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
      if (x < 0) break;
    }
    ctx.stroke();
  }

  for (let note of noteHistory) {
    let xFrom = scale(note.time, then, now, 0, width);
    let xTo = scale(note.time + note.duration, then, now, 0, width);
    let yFrom = scale(
      note.octave * scaleSize + note.index,
      scaleMin,
      scaleMax,
      height,
      0
    );
    let yTo = scale(
      note.octave * scaleSize + note.index + 1,
      scaleMin,
      scaleMax,
      height,
      0
    );
    let opacity = scale(note.velocity, 0, 1, 0.5, 1);
    ctx.fillStyle = `rgba(189, 225, 244, ${opacity})`;
    ctx.fillRect(xFrom, yFrom, xTo - xFrom, yTo - yFrom);
  }
}

function scale(v: number, a1: number, a2: number, b1: number, b2: number) {
  return b1 + ((v - a1) * (b2 - b1)) / (a2 - a1);
}
