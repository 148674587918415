import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Tuning, TuningSubset, PlayState, IPlayer } from "../types";
import { getPhaseRotation, getPitchCents } from "../utils";

const Container = styled.div`
  position: absolute;
  height: 100%;
  z-index: 1;
`;

interface RootMarkerProps {
  left: number;
}
const RootMarker = styled.div<RootMarkerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--unit) * 2);
  height: 100%;
  transform: ${({ left }) => `translateX(${left}px)`};
  box-sizing: border-box;
  box-shadow: calc(-1 * var(--unit) / 1.5) 0px calc(var(--unit) / 1.5) 0px
    rgba(0, 0, 0, 0.3);
  pointer-events: none;
`;

let Playhead = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  pointer-events: none;
`;

const PlayheadMarker = styled.div`
  position: absolute;
  top: 50%;
  left: 0.5px;
  width: 8px;
  height: 8px;
  transform: translate(-4px, -4px) rotate(45deg);
  background: var(--playhead);
`;

interface TrackPlaybackVisualisationProps {
  trackId: string;
  tuning?: Tuning;
  subset?: TuningSubset;
  width: number;
  playState: PlayState<IPlayer>;
}
export const TrackPlaybackVisualisation: React.FC<
  TrackPlaybackVisualisationProps
> = ({ trackId, tuning, subset, width, playState }) => {
  let playheadRef = useRef<HTMLDivElement>(null);
  let rootDegree = subset?.degrees.find((d) => d.role === "tonic");
  let rootPc = rootDegree
    ? tuning?.pitchClasses[rootDegree.index]
    : tuning?.pitchClasses[0];

  useEffect(() => {
    let playhead = playheadRef.current;
    if (playhead && playState.state === "playing") {
      let raf = -1;
      playhead.style.willChange = "transform";
      let frame = (now: DOMHighResTimeStamp) => {
        let phase = playState.player.getTrackPhase(trackId, now);
        playhead!.style.transform = `translateX(${phase * width}px)`;
        raf = requestAnimationFrame(frame);
      };
      raf = requestAnimationFrame(frame);
      return () => {
        cancelAnimationFrame(raf);
      };
    } else if (playhead && playState.state === "notPlaying") {
      let phaseRot = tuning && subset ? getPhaseRotation(tuning, subset) : 0;
      let phase = (playState.phase + phaseRot) % 1;
      playhead.style.willChange = "auto";
      playhead.style.transform = `translateX(${phase * width}px)`;
    }
  }, [trackId, playState, tuning, subset, width]);

  return (
    <Container>
      {rootPc && (
        <>
          <RootMarker left={(getPitchCents(rootPc) / 1200) * width} />
          <Playhead ref={playheadRef}>
            <PlayheadMarker />
          </Playhead>
        </>
      )}
    </Container>
  );
};
