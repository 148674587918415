import * as api from "../main/api";
import { memoize } from "lodash";

export let loadTuningSystems = memoize((accessToken?: string) =>
  api.loadAllTuningSystems(accessToken)
);

export let loadScales = memoize((accessToken?: string) =>
  api.loadAllScales(accessToken)
);
