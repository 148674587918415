import React from "react";
import classNames from "classnames";

import "./LoadingSplash.scss";

import LoadingAnimation from "./images/loading.svg";

interface LoadingSplashProps {
  isOpen: boolean;
}
export const LoadingSplash: React.FC<LoadingSplashProps> = ({ isOpen }) => {
  return (
    <div className={classNames("loadingSplashWrapper", { isOpen })}>
      <div className="loadingSplash">
        <img
          className="loadingAnimation"
          src={LoadingAnimation}
          alt="Loading"
        />
      </div>
    </div>
  );
};
