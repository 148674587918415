import { RefObject, useEffect } from "react";

export function useMoveOutside(ref: RefObject<HTMLElement>, cb: () => void) {
  useEffect(() => {
    function handleMoveOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        ref.current !== event.target
      ) {
        cb();
      }
    }
    document.addEventListener("pointermove", handleMoveOutside);
    return () => {
      document.removeEventListener("pointermove", handleMoveOutside);
    };
  }, [ref, cb]);
}
