import { every, findIndex, isNumber, range } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";

import {
  formatPitch,
  getPitchCents,
  Pitch,
  Scale,
  ScaleDegree,
  TuningSystem,
} from "../main/core";
import { getFlatPitches } from "../OctaveDivisionBand";
import { SCALE_DEGREE_NAMES_ENGLISH } from "../constants";
import { EventEmitter } from "events";

import "./ScaleStrip.scss";

const STRIP_WIDTH = 280;
const GAP_WIDTH = 1;

interface ScaleStripProps {
  tuningSystem?: TuningSystem;
  scale?: Scale;
  events: EventEmitter;
}
export const ScaleStrip: React.FC<ScaleStripProps> = React.memo(
  ({ tuningSystem, scale, events }) => {
    let [labelMode, setLabelMode] = useState<"ratio" | "cents" | "sol">("sol");

    let cycleLabelMode = useCallback(() => {
      setLabelMode((labelMode) => {
        switch (labelMode) {
          case "sol":
            return allRatios(tuningSystem!) ? "ratio" : "cents";
          case "ratio":
            return "cents";
          case "cents":
            return "sol";
        }
      });
    }, [tuningSystem]);
    useEffect(() => {
      tuningSystem &&
        setLabelMode((mode) =>
          mode === "ratio" && !allRatios(tuningSystem!) ? "cents" : mode
        );
    }, [tuningSystem]);

    let flatPitches = useMemo(
      () => (tuningSystem ? getFlatPitches(tuningSystem.strings) : []),
      [tuningSystem]
    );

    let getScaleDegree = useCallback(
      (flatIndex: number) => {
        let pitch = flatPitches[flatIndex];
        return (
          scale &&
          scale?.scaleDegrees.find(
            (s) =>
              s.stringIndex === pitch.stringIdx &&
              s.pitchClassIndex === pitch.pcIdx
          )
        );
      },
      [scale, flatPitches]
    );

    return (
      <div className="scaleStrip" onClick={cycleLabelMode}>
        <div className="scaleStrip--strip">
          {flatPitches.map(({ stringIdx, pcIdx, pitch }, idx) => (
            <ScaleStripSegment
              key={idx}
              pitch={pitch}
              stringIndex={stringIdx}
              pitchClassIndex={pcIdx}
              scaleDegree={getScaleDegree(idx)}
              left={getPitchX(stringIdx, pcIdx, flatPitches, STRIP_WIDTH)}
              width={getPitchWidth(stringIdx, pcIdx, flatPitches, STRIP_WIDTH)}
              labelMode={labelMode}
              events={events}
            />
          ))}
        </div>
      </div>
    );
  }
);

interface ScaleStripSegmentProps {
  pitch: Pitch;
  stringIndex: number;
  pitchClassIndex: number;
  scaleDegree?: ScaleDegree;
  left: number;
  width: number;
  labelMode: "cents" | "ratio" | "sol";
  events: EventEmitter;
}
let ScaleStripSegment: React.FC<ScaleStripSegmentProps> = ({
  pitch,
  stringIndex,
  pitchClassIndex,
  scaleDegree,
  left,
  width,
  labelMode,
  events,
}) => {
  let formatLabel = useCallback(
    (pitch: Pitch, sd: ScaleDegree) => {
      switch (labelMode) {
        case "cents":
          return formatPitch(pitch, "cents");
        case "ratio":
          return formatPitch(pitch, "ratio");
        case "sol":
          return isNumber(sd.map) ? SCALE_DEGREE_NAMES_ENGLISH[sd.map] : "";
      }
    },
    [labelMode]
  );

  let segmentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let onNote = ({
      stringIndex: noteStringIndex,
      pitchClassIndex: notePitchClassIndex,
      duration,
    }: {
      stringIndex: number;
      pitchClassIndex: number;
      duration: number;
    }) => {
      if (
        noteStringIndex === stringIndex &&
        notePitchClassIndex === pitchClassIndex
      ) {
        segmentRef.current!.animate(
          [{ backgroundColor: "#BDE1F4" }, { backgroundColor: "#555" }],
          duration * 1000
        );
      }
    };
    events.on("note", onNote);
    return () => {
      events.off("note", onNote);
    };
  }, [events, stringIndex, pitchClassIndex]);

  return (
    <React.Fragment>
      <div
        className={classNames("scaleStrip--stripSegment", {
          isMapped: !!scaleDegree,
        })}
        style={{ left, width }}
        ref={segmentRef}
      ></div>
      {scaleDegree && (
        <div
          className={classNames("scaleStrip--stripSegmentLabel")}
          style={{ left: left + width / 2 }}
        >
          {formatLabel(pitch, scaleDegree)}
        </div>
      )}
      {scaleDegree?.role === "tonic" && (
        <div className="scaleStrip--tonicMarker" style={{ left }} />
      )}
    </React.Fragment>
  );
};

let getPitchWidth = (
  stringIdx: number,
  pcIdx: number,
  flatPitches: { stringIdx: number; pcIdx: number; pitch: Pitch }[],
  width: number
) => {
  let totalGapWidth = (flatPitches.length - 1) * GAP_WIDTH;
  let totalDivsWidth = width - totalGapWidth;
  let flatIdx = findIndex(
    flatPitches,
    (p) => p.stringIdx === stringIdx && p.pcIdx === pcIdx
  );
  let startCents = getPitchCents(flatPitches[flatIdx].pitch);
  let endCents =
    flatIdx < flatPitches.length - 1
      ? getPitchCents(flatPitches[flatIdx + 1].pitch)
      : 1200;
  let cents = endCents - startCents;
  return totalDivsWidth * (cents / 1200);
};

let getPitchX = (
  stringIdx: number,
  pcIdx: number,
  flatPitches: { stringIdx: number; pcIdx: number; pitch: Pitch }[],
  width: number
) => {
  let flatIdx = findIndex(
    flatPitches,
    (p) => p.stringIdx === stringIdx && p.pcIdx === pcIdx
  );
  let widthToTheLeft = range(0, flatIdx).reduce(
    (w, i) =>
      w +
      getPitchWidth(
        flatPitches[i].stringIdx,
        flatPitches[i].pcIdx,
        flatPitches,
        width
      ),
    0
  );
  return widthToTheLeft + flatIdx * GAP_WIDTH;
};

let allRatios = (ts: TuningSystem) =>
  every(
    ts.strings,
    (string) =>
      isNumber(string.ratioUpper) &&
      isNumber(string.ratioLower) &&
      every(
        string.pitchClasses,
        (pc) => isNumber(pc.ratioUpper) && isNumber(pc.ratioLower)
      )
  );
