import { sortedIndexBy } from "lodash";
import WebMidi, { MidiChannelModeMessages, Output } from "webmidi";
import { setInterval } from "worker-timers";

const OUTPUT_LATENCY_MS = 100;
const DEQUEUE_INTERVAL = Math.ceil(OUTPUT_LATENCY_MS / 10);

type NoteOnEvent = {
  type: "noteon";
  output: Output;
  note: number;
  pitchBend: number;
  channel: number | "all";
  velocity: number;
  time: number;
};
type NoteOffEvent = {
  type: "noteoff";
  output: Output;
  note: number;
  channel: number | "all";
  velocity: number;
  time: number;
};
type ControllerEvent = {
  type: "controller";
  output: Output;
  controller: number;
  value: number;
  channel: number | "all";
  time: number;
};
type ChannelModeEvent = {
  type: "channelmode";
  output: Output;
  mode: keyof MidiChannelModeMessages;
  time: number;
};
type ClockStartEvent = {
  type: "clockstart";
  output: Output;
  time: number;
};
type ClockEvent = {
  type: "clock";
  output: Output;
  time: number;
};
type StopEvent = {
  type: "stop";
  output: Output;
  time: number;
};
type SysexEvent = {
  type: "sysex";
  output: Output;
  device: number;
  data: number[];
  time: number;
};
type MIDIEvent =
  | NoteOnEvent
  | NoteOffEvent
  | ControllerEvent
  | ChannelModeEvent
  | ClockStartEvent
  | ClockEvent
  | StopEvent
  | SysexEvent;

export class MIDIOutputManager {
  private eventQueue: MIDIEvent[] = [];

  constructor() {
    setInterval(() => this.dequeueEvents(), DEQUEUE_INTERVAL);
  }

  sendNote(
    output: Output,
    configuredChannel: number | "all" | "mpe",
    note: number,
    pitchBend: number,
    channel: number | "all",
    velocity: number,
    duration: number,
    time: number
  ) {
    let noteOn: NoteOnEvent = {
      type: "noteon",
      output,
      note,
      pitchBend,
      channel,
      velocity,
      time,
    };
    let noteOff: NoteOffEvent = {
      type: "noteoff",
      output,
      note,
      channel,
      velocity,
      time: time + duration,
    };

    let offConflictIdx = -1;
    do {
      offConflictIdx = this.findConflictingNoteIndex(
        "noteoff",
        noteOn,
        duration,
        configuredChannel
      );
      if (offConflictIdx >= 0) {
        let off = this.eventQueue[offConflictIdx];
        console.log("expediting conflicting note-off", off);
        this.eventQueue.splice(offConflictIdx, 1);
        off.time = noteOn.time - 1;
        this.enqueueEvent(off);
      }
    } while (offConflictIdx >= 0);
    this.enqueueEvent(noteOn);
    let onConflictIdx = this.findConflictingNoteIndex(
      "noteon",
      noteOn,
      duration,
      configuredChannel
    );
    if (onConflictIdx < 0) {
      this.enqueueEvent(noteOff);
    } else {
      console.log(
        "expediting note-off at",
        noteOff.time,
        "because it would conflict with",
        this.eventQueue[onConflictIdx]
      );
      noteOff.time = this.eventQueue[onConflictIdx].time - 1;
      this.enqueueEvent(noteOff);
    }
  }

  sendController(
    output: Output,
    controller: number,
    value: number,
    channel: number | "all",
    time: number
  ) {
    this.enqueueEvent({
      type: "controller",
      output,
      controller,
      value,
      channel,
      time,
    });
  }

  channelMode(
    output: Output,
    mode: keyof MidiChannelModeMessages,
    time: number
  ) {
    this.enqueueEvent({ type: "channelmode", output, mode, time });
  }

  clockStart(output: Output, time: number) {
    this.enqueueEvent({ type: "clockstart", output, time });
  }

  clock(output: Output, time: number) {
    this.enqueueEvent({ type: "clock", output, time });
  }

  stop(output: Output, time: number) {
    this.enqueueEvent({ type: "stop", output, time });
  }

  sysex(output: Output, device: number, data: number[], time: number) {
    this.enqueueEvent({ type: "sysex", output, device, data, time });
  }

  now() {
    return WebMidi.time;
  }

  private enqueueEvent(event: MIDIEvent) {
    let index = sortedIndexBy(this.eventQueue, event, (e) => e.time);
    this.eventQueue.splice(index, 0, event);
  }

  private findConflictingNoteIndex(
    ofType: "noteon" | "noteoff",
    forEvent: NoteOnEvent,
    forDuration: number,
    configuredChannel: number | "all" | "mpe"
  ) {
    return this.eventQueue.findIndex(
      (e) =>
        e.type === ofType &&
        e.output === forEvent.output &&
        isOverlappingChannel(configuredChannel, e.channel, forEvent.channel) &&
        e.note === forEvent.note &&
        e.time > forEvent.time &&
        e.time < forEvent.time + forDuration
    );
  }

  private dequeueEvents() {
    while (
      this.eventQueue.length > 0 &&
      this.eventQueue[0].time <= this.now()
    ) {
      this.sendEvent(this.eventQueue.shift()!);
    }
  }

  private sendEvent(event: MIDIEvent) {
    switch (event.type) {
      case "noteon": {
        event.output.playNote(event.note, event.channel, {
          velocity: event.velocity,
          time: event.time + OUTPUT_LATENCY_MS,
        });
        event.output.sendPitchBend(event.pitchBend, event.channel, {
          time: event.time + OUTPUT_LATENCY_MS,
        });
        break;
      }
      case "noteoff": {
        event.output.stopNote(event.note, event.channel, {
          velocity: event.velocity,
          rawVelocity: false,
          time: event.time + OUTPUT_LATENCY_MS,
        });
        break;
      }
      case "controller": {
        event.output.sendControlChange(
          event.controller,
          event.value,
          event.channel,
          { time: event.time + OUTPUT_LATENCY_MS }
        );
        break;
      }
      case "channelmode": {
        event.output.sendChannelMode(event.mode, undefined, undefined, {
          time: event.time + OUTPUT_LATENCY_MS,
        });
        break;
      }
      case "clockstart": {
        event.output.sendStart({ time: event.time + OUTPUT_LATENCY_MS });
        break;
      }
      case "clock": {
        event.output.sendClock({ time: event.time + OUTPUT_LATENCY_MS });
        break;
      }
      case "stop": {
        event.output.sendStop({ time: event.time + OUTPUT_LATENCY_MS });
        break;
      }
      case "sysex": {
        event.output.sendSysex(event.device, event.data, {
          time: event.time + OUTPUT_LATENCY_MS,
        });
        break;
      }
    }
  }
}

function isOverlappingChannel(
  configuredChannel: number | "all" | "mpe",
  a: number | "all",
  b: number | "all"
) {
  if (configuredChannel === "all" /*|| configuredChannel === "mpe"*/)
    return true;
  return a === "all" || b === "all" || a === b;
}
