import { isNumber } from "lodash";
import { generatePath } from "react-router";

import { SCALE_DEGREE_NAMES } from "./constants";
import {
  Solmization,
  Pitch,
  TString,
  ScaleDegree,
  ScaleDegreeRole,
  TuningSystem,
  Scale,
} from "./main/core";

export function parseRefPitch(refPitchString?: string): {
  semitones: number;
  note?: string;
} {
  if (!refPitchString) {
    return { semitones: 60 };
  } else if (refPitchString.indexOf("-") > 0) {
    let [noteString, stString] = refPitchString.split("-");
    return { note: noteString.replace("s", "#"), semitones: +stString };
  } else {
    return { semitones: +refPitchString };
  }
}

export function formatRefPitch(semitones: number, note?: string) {
  if (note) {
    return `${note.replace("#", "s")}-${semitones}`;
  } else {
    return `${semitones}`;
  }
}

export function parseStrings(stringsString?: string): TString[] {
  if (stringsString) {
    return stringsString.split("s").map(parseString);
  } else {
    return [];
  }
}

export function parseString(sString: string): TString {
  let [openString, pitches] = sString.split("_");
  let openStringPitch = parsePitch(openString);
  let pitchVals = pitches.split("~").map(parsePitch);
  return {
    ...openStringPitch,
    pitchClasses: pitchVals.map((p) => ({ ...p, name8ve1: "", name8ve2: "" })),
  };
}

function parsePitch(pString: string): Pitch {
  if (pString.indexOf("r") > 0) {
    let [upper, lower] = pString.split("r");
    return { ratioUpper: +upper, ratioLower: +lower };
  } else {
    return { cents: +pString };
  }
}

export function formatStrings(strings: TString[]) {
  return strings
    .map(
      (s) => formatPitch(s) + "_" + s.pitchClasses.map(formatPitch).join("~")
    )
    .join("s");
}

function formatPitch(pitch: Pitch) {
  return isNumber(pitch.ratioUpper) && isNumber(pitch.ratioLower)
    ? `${pitch.ratioUpper}r${pitch.ratioLower}`
    : `${pitch.cents}`;
}

export function parseScale(scaleString?: string): ScaleDegree[] {
  if (scaleString) {
    return scaleString.split("s").map((d) => {
      let [stringIndex, pitchClassIndex, map, role] = d.split("~");
      return {
        stringIndex: +stringIndex,
        pitchClassIndex: +pitchClassIndex,
        map: +map,
        role: { t: "tonic", "1": "primary", "2": "secondary", n: "none" }[
          role as "t" | "1" | "2" | "n"
        ] as ScaleDegreeRole,
      };
    });
  } else {
    return [];
  }
}

export function formatScale(scaleDegrees: ScaleDegree[]) {
  return scaleDegrees
    .map((s) => {
      let role = { tonic: "t", primary: "1", secondary: "2", none: "n" }[
        s.role || "none"
      ];
      return `${s.stringIndex}~${s.pitchClassIndex}~${s.map}~${role}`;
    })
    .join("s");
}

export function parseSolmization(solString: string): Solmization {
  let sol = Object.keys(SCALE_DEGREE_NAMES).find(
    (n) => n.toLowerCase() === solString.toLowerCase()
  );
  if (!sol) {
    throw new Error("Invalid solmization string " + solString);
  }
  return sol as Solmization;
}

export function formatSolmization(sol: Solmization) {
  return sol.toLowerCase();
}

export function getLeimmaLink(
  tuningSystem?: TuningSystem,
  scale?: Scale
): string {
  if (scale) {
    return generatePath(
      "/leimma/:tuningSystemId/refpitch/:refPitch/tuningsystem/:strings/scale/:scaleId/:solmization/:scale?",
      {
        tuningSystemId: tuningSystem!.id ?? "",
        refPitch: formatRefPitch(
          tuningSystem!.refPitchNoteMidi,
          tuningSystem!.refPitchNoteName
        ),
        strings: formatStrings(tuningSystem!.strings),
        scaleId: scale.id ?? "new",
        solmization: formatSolmization("solfege"),
        scale:
          scale.scaleDegrees.length > 0
            ? formatScale(scale.scaleDegrees)
            : undefined,
      }
    );
  } else {
    return generatePath(
      "/leimma/:tuningSystemId/refpitch/:refPitch/tuningsystem/:strings",
      {
        tuningSystemId: tuningSystem!.id ?? "",
        refPitch: formatRefPitch(
          tuningSystem!.refPitchNoteMidi,
          tuningSystem!.refPitchNoteName
        ),
        strings: formatStrings(tuningSystem!.strings),
      }
    );
  }
}
