import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useGesture } from "react-use-gesture";
import { useSpring } from "@react-spring/core";
import { a, to, SpringConfig } from "@react-spring/web";
import { interpolate } from "flubber";
import { Frequency } from "tone";
import { memoize, once, range } from "lodash";
import classNames from "classnames";
import { useWindowWidth } from "@react-hook/window-size";

import "./FrequencyBand.scss";
import { MIN_REF_PITCH_MIDI, MAX_REF_PITCH_MIDI } from "./constants";
import {
  getOctaveDivisionBandWidth,
  OCTAVE_DIVISION_BAND_HEIGHT,
  OCTAVE_DIVISION_BAND_MARGIN,
  OCTAVE_DIVISION_BAND_Y,
} from "./OctaveDivisionBand";

export const FREQUENCY_BAND_HEIGHT = 175;
const MAJOR_TICK_INTERVAL_ST = 1;
const MINOR_TICK_INTERVAL_ST = 0.2;
const MAJOR_TICK_LINE_LENGTH = 50;
const MINOR_TICK_LINE_LENGTH = 25;
const EPSILON = 3;
const OCTAVE_SCREEN_Y_SHIFT = -100;
interface FrequencyBandProps {
  min: number;
  max: number;
  value: number;
  note?: string;
  entry?: "refPitch" | "octaveDivision";
  onChange: (newValue: number, note?: string) => void;
  onStartInteraction: () => void;
  onEndInteraction: () => void;
  onEntered?: () => void;
}
export const FrequencyBand: React.FC<FrequencyBandProps> = React.memo(
  ({
    min,
    max,
    value,
    note,
    entry,
    onChange,
    onStartInteraction,
    onEndInteraction,
    onEntered,
  }) => {
    let windowWidth = useWindowWidth();
    let majorTickWidth = getOctaveDivisionBandWidth(windowWidth) / 12;
    let majorTicks = useMemo(
      () =>
        range(
          MIN_REF_PITCH_MIDI,
          MAX_REF_PITCH_MIDI + 1,
          MAJOR_TICK_INTERVAL_ST
        ).filter((st) => st >= min && st <= max),
      [min, max]
    );
    let minorTicks = useMemo(
      () =>
        range(
          MIN_REF_PITCH_MIDI,
          MAX_REF_PITCH_MIDI + 1,
          MINOR_TICK_INTERVAL_ST
        ).filter((st) => st >= min && st <= max),
      [min, max]
    );
    let bandWidth = (majorTicks.length - 1) * majorTickWidth;
    let minX = -bandWidth;
    let maxX = 0;

    let stToX = useCallback(
      (freq: number) => {
        return minX + (1 - (freq - min) / (max - min)) * (maxX - minX);
      },
      [min, max, minX, maxX]
    );
    let xToSt = useCallback(
      (x: number) => {
        return min + (1 - (x - minX) / (maxX - minX)) * (max - min);
      },
      [min, max, minX, maxX]
    );

    let noteRef = useRef<string | undefined>(note);

    useEffect(() => {
      noteRef.current = note;
    }, [note]);

    let onXChange = useCallback(
      (x: number) => {
        let newSt = +xToSt(x).toFixed(5);
        onChange(newSt, noteRef.current);
      },
      [xToSt, onChange]
    );

    let [{ x }, setX] = useSpring(() => ({
      x: stToX(value),
    }));

    useEffect(() => {
      setX({
        onChange: (values: any) => {
          if (values.x > maxX) {
            setX({
              x: maxX,
              config: { decay: false },
            });
          } else if (values.x < minX) {
            setX({
              x: minX,
              config: { decay: false },
            });
          }
          onXChange(values.x);
        },
      });
    }, [onXChange, setX, minX, maxX]);

    useEffect(() => {
      if (Math.abs(stToX(value) - x.get()) > EPSILON) {
        setX({ x: stToX(value), immediate: true });
      }
    }, [value, stToX, x, setX]);

    let bindDrag = useGesture(
      {
        onDrag: ({ movement: [x], vxvy: [vx], down }) => {
          if (down) {
            setX({
              x,
              immediate: true,
              config: {
                decay: false,
              },
            });
          } else {
            setX({
              x,
              reset: true,
              immediate: false,
              config: {
                velocity: x < maxX && x > minX ? vx : 0,
                decay: true,
              },
            });
          }
        },
        onPointerDown: onStartInteraction,
        onPointerUp: onEndInteraction,
      },
      {
        drag: {
          axis: "x",
          initial: () => [x.get(), 0],
          bounds: { right: maxX, left: minX },
          rubberband: true,
        },
        eventOptions: { pointer: true },
      }
    );

    type SpringParams = {
      focusFill: string;
      focusStroke: string;
      focusShapeInterpolation: number;
      xShift: number;
      yShift: number;
      refPitchLineToY: number;
      delay?: number;
      config?: SpringConfig;
      onRest?: () => void;
    };
    let springs: {
      growCircleFromPoint: SpringParams;
      shiftBandToCorner: SpringParams;
      morphFocusIntoLine: SpringParams;
      morphFocusIntoBar: SpringParams;
      fadeBarOut: SpringParams;
    } = useMemo(
      () => ({
        growCircleFromPoint: {
          from: {
            focusFill: "rgba(0, 0, 0, 1)",
            focusShapeInterpolation: 0,
          },
          focusFill: "rgba(255, 255, 255, 0.25)",
          focusStroke: "rgba(255, 255, 255, 1)",
          focusShapeInterpolation: 1,
          refPitchLineToY: OCTAVE_DIVISION_BAND_HEIGHT / 2,
          xShift: 0,
          yShift: 0,
          delay: 200,
          config: { friction: 23, clamp: true },
          onRest: once(() => {
            onEntered?.();
          }),
        },
        shiftBandToCorner: {
          from: { xShift: 0, yShift: 0, focusInterpolation: 1 },
          xShift: -getOctaveDivisionBandWidth(windowWidth) / 2,
          yShift: OCTAVE_SCREEN_Y_SHIFT,
          focusFill: "rgba(255, 255, 255, 0.25)",
          focusStroke: "rgba(255, 255, 255, 1)",
          focusShapeInterpolation: 1,
          refPitchLineToY: OCTAVE_DIVISION_BAND_HEIGHT / 2,
          config: { friction: 23, clamp: true },
          onRest: once(() => {
            setSpringState("morphFocusIntoLine");
          }),
        },
        morphFocusIntoLine: {
          from: { focusShapeInterpolation: 1 },
          xShift: -getOctaveDivisionBandWidth(windowWidth) / 2,
          yShift: OCTAVE_SCREEN_Y_SHIFT,
          focusFill: "rgba(0, 0, 0, 1)",
          focusStroke: "rgba(0, 0, 0, 0)",
          focusShapeInterpolation: 2,
          refPitchLineToY:
            OCTAVE_DIVISION_BAND_HEIGHT -
            OCTAVE_SCREEN_Y_SHIFT +
            OCTAVE_DIVISION_BAND_Y,
          onRest: once(() => {
            setSpringState("morphFocusIntoBar");
          }),
        },
        morphFocusIntoBar: {
          from: { focusShapeInterpolation: 2 },
          xShift: -getOctaveDivisionBandWidth(windowWidth) / 2,
          yShift: OCTAVE_SCREEN_Y_SHIFT,
          focusFill: "rgba(0, 0, 0, 1)",
          focusStroke: "rgba(0, 0, 0, 0)",
          focusShapeInterpolation: 3,
          refPitchLineToY:
            OCTAVE_DIVISION_BAND_HEIGHT -
            OCTAVE_SCREEN_Y_SHIFT +
            OCTAVE_DIVISION_BAND_Y,
          onRest: once(() => {
            onEntered?.();
            setSpringState("fadeBarOut");
          }),
        },
        fadeBarOut: {
          xShift: -getOctaveDivisionBandWidth(windowWidth) / 2,
          yShift: OCTAVE_SCREEN_Y_SHIFT,
          focusFill: "rgba(0, 0, 0, 0)",
          focusStroke: "rgba(0, 0, 0, 0)",
          focusShapeInterpolation: 3,
          refPitchLineToY:
            OCTAVE_DIVISION_BAND_HEIGHT -
            OCTAVE_SCREEN_Y_SHIFT +
            OCTAVE_DIVISION_BAND_Y,
          delay: 150,
        },
      }),
      [onEntered, windowWidth]
    );
    let [springState, setSpringState] = useState<
      | "growCircleFromPoint"
      | "shiftBandToCorner"
      | "morphFocusIntoLine"
      | "morphFocusIntoBar"
      | "fadeBarOut"
    >(entry === "refPitch" ? "growCircleFromPoint" : "shiftBandToCorner");
    let [entrySpring, setEntrySpring] = useSpring(() => springs[springState]);
    useEffect(() => {
      console.log("springState", springState);
    }, [springState]);
    useEffect(() => {
      console.log("setEntrySpring", setEntrySpring);
    }, [setEntrySpring]);
    useEffect(() => {
      console.log("springs", springs);
    }, [springs]);
    useEffect(() => {
      setEntrySpring(springs[springState]);
    }, [springState, setEntrySpring, springs]);

    return (
      <a.g
        transform={to(
          [x, entrySpring.xShift, entrySpring.yShift],
          (x, xShift, yShift) => `translate(${x + xShift},${yShift})`
        )}
        className="frequencyBand"
      >
        <line
          className="frequencyBand--lineHorizontal"
          x1={0}
          x2={bandWidth}
          y1={0}
          y2={0}
        />
        {majorTicks.map((st) => {
          let x = ((st - min) / (max - min)) * bandWidth;
          return (
            <line
              key={st}
              className="frequencyBand--majorTickLine"
              x1={x}
              x2={x}
              y1={-MAJOR_TICK_LINE_LENGTH / 2}
              y2={MAJOR_TICK_LINE_LENGTH / 2}
            />
          );
        })}
        {minorTicks.map((st) => (
          <line
            key={st}
            className="frequencyBand--minorTickLine"
            x1={((st - min) / (max - min)) * bandWidth}
            x2={((st - min) / (max - min)) * bandWidth}
            y1={-MINOR_TICK_LINE_LENGTH / 2}
            y2={MINOR_TICK_LINE_LENGTH / 2}
          />
        ))}
        <rect
          className="frequencyBand--band"
          width={bandWidth}
          height={FREQUENCY_BAND_HEIGHT}
          y={-FREQUENCY_BAND_HEIGHT / 2}
          {...(bindDrag() as any)}
          data-tip="Drag to adjust reference pitch"
        />
        {majorTicks.map((st) => {
          let x = ((st - min) / (max - min)) * bandWidth;
          return (
            <text
              key={st}
              className={classNames("frequencyBand--majorTickLabel", {
                isSelected: note === Frequency(st, "midi").toNote(),
              })}
              x={x}
              y={MAJOR_TICK_LINE_LENGTH / 1.5}
              alignmentBaseline="hanging"
              onClick={() => {
                noteRef.current = Frequency(st, "midi").toNote();
                setX({ x: stToX(st) });
              }}
              data-tip="Click to use this note as the reference pitch"
            >
              {Frequency(st, "midi").toNote()}
            </text>
          );
        })}
        <a.line
          x1={x.to((x) => -x)}
          y1={-OCTAVE_DIVISION_BAND_HEIGHT / 2}
          x2={x.to((x) => -x)}
          y2={entrySpring.refPitchLineToY}
          className="refPitchScreen--refPitchLine"
        />
        <a.g transform={x.to((x) => `translate(${-x},0)`)}>
          <a.path
            d={entrySpring.focusShapeInterpolation.to((t) =>
              interpolateFocusShape(t, windowWidth)
            )}
            fill={entrySpring.focusFill}
            stroke={entrySpring.focusStroke}
            strokeWidth={3}
            className="frequencyBand--focusShape"
          />
        </a.g>
      </a.g>
    );
  }
);

let getCirclePath = (cx: number, cy: number, r: number) => `M ${cx - r}, ${cy}
a ${r},${r} 0 1,0 ${r * 2},0
a ${r},${r} 0 1,0 ${-r * 2},0`;

let getRectPath = (cx: number, cy: number, w: number, h: number) =>
  `M ${cx - w / 2}, ${cy - h / 2} L ${cx + w / 2}, ${cy - h / 2} L ${
    cx + w / 2
  }, ${cy + h / 2} L ${cx - w / 2}, ${cy + h / 2} L ${cx - w / 2}, ${
    cy - h / 2
  }`;

let pointPath = getCirclePath(0, 0, 50);
let circlePath = getCirclePath(0, 0, 90);
let linePath = getRectPath(
  0,
  -OCTAVE_SCREEN_Y_SHIFT +
    OCTAVE_DIVISION_BAND_Y +
    OCTAVE_DIVISION_BAND_HEIGHT / 2,
  10,
  OCTAVE_DIVISION_BAND_HEIGHT
);
let circleGrowthInterpolation = interpolate(pointPath, circlePath);
let circleToLineInterpolation = interpolate(circlePath, linePath);
let getLineToBarInterpolation = memoize((windowWidth) =>
  interpolate(
    linePath,
    getRectPath(
      windowWidth / 2 - OCTAVE_DIVISION_BAND_MARGIN,
      -OCTAVE_SCREEN_Y_SHIFT +
        OCTAVE_DIVISION_BAND_Y +
        OCTAVE_DIVISION_BAND_HEIGHT / 2,
      getOctaveDivisionBandWidth(windowWidth),
      OCTAVE_DIVISION_BAND_HEIGHT
    )
  )
);

function interpolateFocusShape(r: number, windowWidth: number) {
  if (r < 1) {
    return circleGrowthInterpolation(r);
  } else if (r < 2) {
    return circleToLineInterpolation(r - 1);
  } else {
    return getLineToBarInterpolation(windowWidth)(r - 2);
  }
}
