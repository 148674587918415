import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteProps,
  Redirect,
} from "react-router-dom";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import { AdminApp } from "./admin/AdminApp";
import { MIDIContext } from "./MIDIContext";
import { MIDIInput, MIDIOutput, UserProfile } from "./main/core";
import * as audio from "./main/audio";
import { Screens } from "./Screens";
import { NewOrExistingChoiceScreen } from "./NewOrExistingChoiceScreen";
import { Apotome } from "./apotome/Apotome";
import { useAdminAccess } from "./useAdminAccess";
import { Tooltip } from "./Tooltip";
import { UserProfileContext } from "./useUserProfile";
import { NewHome } from "./NewHome";
import { ServiceWorker } from "./ServiceWorker";

export const App: React.FC = () => {
  let [midiInput, setMidiInput] = useState<MIDIInput>({ channel: "all" });
  let [midiOutput, setMidiOutput] = useState<MIDIOutput>({
    channel: "mpe",
    internal: "synth",
    pitchBendRangeCents: 4800,
  });
  let [profile, setProfile] = useState<Promise<UserProfile | undefined>>();

  return (
    <Router>
      <ServiceWorker />
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        redirectUri={window.location.origin}
        cacheLocation="localstorage"
      >
        <UserProfileContext.Provider
          value={{ profilePromise: profile, updateProfilePromise: setProfile }}
        >
          <MIDIContext.Provider
            value={{
              input: midiInput,
              outputs: { leimma: midiOutput },
              onSetInput: (input) => {
                audio.allOff(midiOutput);
                setMidiInput(input);
              },
              onSetOutput: (track, output) => {
                audio.allOff(midiOutput);
                setMidiOutput(output);
              },
              onCCIn: (controller, value) => {
                audio.forwardMIDICC(controller, value, midiOutput);
              },
              onPitchBendIn: (value) => {
                audio.forwardMIDIPitchBend(value, midiOutput);
              },
            }}
          >
            <Switch>
              <AdminRoute path="/admin" component={AdminApp} />
              <Route path="/leimma/:tuningSystemId/refpitch">
                <Screens />
              </Route>
              <Route
                exact
                path="/leimma"
                component={NewOrExistingChoiceScreen}
              />
              <Route exact path="/apotome" component={Apotome} />
              <Route exact path="/" component={NewHome} />
            </Switch>
            <Tooltip />
          </MIDIContext.Provider>
        </UserProfileContext.Provider>
      </Auth0Provider>
    </Router>
  );
};

let AdminRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  let { isLoading, hasAdminAccess } = useAdminAccess();
  let { loginWithRedirect, isAuthenticated } = useAuth0();
  let C = Component as any;

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        redirectUri: `${window.location.origin}${window.location.pathname}`,
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return hasAdminAccess ? (
          <C {...props} />
        ) : !isLoading && isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <></>
        );
      }}
    />
  );
};
