import React, { useCallback, useState } from "react";
import useMeasure from "react-use-measure";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

import { TrackPlaybackVisualisation } from "./TrackPlaybackVisualisation";
import { TrackSubsetVisualisation } from "./TrackSubsetVisualisation";
import { TrackTriggersVisualisation } from "./TrackTriggersVisualisation";
import { TrackTuningVisualisation } from "./TrackTuningVisualisation";
import { Track, PlayState, IPlayer } from "../types";
import { TrackMarkerInteractionProps } from "./TrackMarkerInteractions";
import { isNumber } from "lodash";
import { TrackMarkerHighlight } from "./TrackMarkerHighlight";
import { TrackPitchLabels } from "./TrackPitchLabels";

let Container = styled(motion.div)`
  position: relative;
  width: 100%;
  background: var(--track-inner);
`;

interface TrackVisualisationProps {
  track: Track;
  playState: PlayState<IPlayer>;
  onToggleTrigger: (index: number) => void;
  onChangeTriggerVelocity: (index: number, velocity: number) => void;
  trackOpen: boolean;
}
export const TrackVisualisation: React.FC<TrackVisualisationProps> = ({
  track,
  playState,
  onToggleTrigger,
  onChangeTriggerVelocity,
  trackOpen,
}) => {
  let [ref, { width }] = useMeasure();
  let [highlightedTriggerIndex, setHighlightedTriggerIndex] = useState<
    number | null
  >(null);
  let highlightedPitchClass =
    isNumber(highlightedTriggerIndex) && track.tuning
      ? track.tuning.pitchClasses[highlightedTriggerIndex]
      : null;
  let highlightedTrigger = track.triggers.find(
    (t) => t.index === highlightedTriggerIndex
  );

  let closeHighlight = useCallback(() => {
    setHighlightedTriggerIndex(null);
  }, []);

  const variants = {
    open: {
      height: 120,
      transition: { duration: 0.2 },
      marginTop: 40,
      marginBottom: 40,
    },
    collapsed: {
      height: 40,
      transition: { duration: 0.2 },
      marginTop: 0,
      marginBottom: 0,
    },
  };

  return (
    <Container
      ref={ref}
      variants={variants}
      initial={"initial"}
      animate={trackOpen ? "open" : "collapsed"}
    >
      <TrackPitchLabels
        tuning={track.tuning}
        width={width}
        highlightIndex={highlightedTriggerIndex}
        trackOpen={trackOpen}
      />
      {trackOpen && (
        <TrackTuningVisualisation
          tuning={track.tuning}
          width={width}
          highlightIndex={highlightedTriggerIndex}
        />
      )}
      <TrackSubsetVisualisation
        tuning={track.tuning}
        subset={track.subset}
        width={width}
        highlightIndex={highlightedTriggerIndex}
        trackOpen={trackOpen}
      />
      <TrackPlaybackVisualisation
        trackId={track.id}
        tuning={track.tuning}
        subset={track.subset}
        playState={playState}
        width={width}
      />
      <TrackTriggersVisualisation
        trackId={track.id}
        tuning={track.tuning}
        subset={track.subset}
        triggers={track.triggers}
        playState={playState}
        width={width}
        highlightIndex={highlightedTriggerIndex}
      />
      <TrackMarkerInteractionProps
        tuning={track.tuning}
        width={width}
        onToggleTrigger={onToggleTrigger}
        onHighlightTrigger={setHighlightedTriggerIndex}
      />
      {trackOpen && highlightedPitchClass && (
        <TrackMarkerHighlight
          pc={highlightedPitchClass}
          isActive={!!highlightedTrigger}
          velocity={highlightedTrigger?.velocity ?? 70}
          trackWidth={width}
          onMouseDown={() =>
            isNumber(highlightedTriggerIndex) &&
            onToggleTrigger(highlightedTriggerIndex)
          }
          onClose={closeHighlight}
          onUpdateVelocity={(velocity) =>
            isNumber(highlightedTriggerIndex) &&
            onChangeTriggerVelocity(highlightedTriggerIndex, velocity)
          }
        />
      )}
    </Container>
  );
};
