import React from "react";

import { TrackControls } from "./types";
import { EventEmitter } from "events";
import { useAdwarUIAdapter } from "./adwarCompat";
import { TrackVisualisation } from "adwar-shared/src/trackvisualisation/TrackVisualisation";

import "./AdwarEditor.scss";

interface AdwarEditorProps {
  trackId: string;
  controls: TrackControls;
  events: EventEmitter;
  onSetTrackControls: (
    id: string,
    update: (oldControls: TrackControls) => TrackControls
  ) => void;
}

export const AdwarEditor: React.FC<AdwarEditorProps> = ({
  trackId,
  controls,
  events,
  onSetTrackControls,
}) => {
  let { adwarTrack, playState } = useAdwarUIAdapter(trackId, controls, events);
  if (!adwarTrack || !playState) return <></>;

  let selectAllTriggers = () => {
    onSetTrackControls(trackId, (c) => ({
      ...c,
      beatDivisionAdwarTriggers:
        adwarTrack?.subset?.degrees.map((d) => ({
          index: d.index,
          velocity:
            (c.beatDivisionAdwarTriggers ?? []).find((t) => t.index === d.index)
              ?.velocity ?? 75,
        })) ?? [],
    }));
  };

  let clearAllTriggers = () => {
    onSetTrackControls(trackId, (c) => ({
      ...c,
      beatDivisionAdwarTriggers: [],
    }));
  };

  return (
    <div className="adwarEditor">
      <div className="adwarEditor--visWrapper">
        <TrackVisualisation
          track={adwarTrack}
          playState={playState}
          trackOpen={true}
          onToggleTrigger={(index) =>
            onSetTrackControls(trackId, (c) =>
              toggleTriggerInControls(c, index)
            )
          }
          onChangeTriggerVelocity={(index, velocity) =>
            onSetTrackControls(trackId, (c) =>
              setTriggerVelocityInControls(c, index, velocity)
            )
          }
        />
      </div>
      <div className="adwarEditor--buttons">
        <button className="button" onClick={selectAllTriggers}>
          Select All
        </button>
        <button className="button" onClick={clearAllTriggers}>
          Clear All
        </button>
      </div>
      <button
        className="button closeButton"
        onClick={() =>
          onSetTrackControls(trackId, (c) => ({
            ...c,
            adwarEditorOpen: false,
          }))
        }
      >
        Close
      </button>
    </div>
  );
};

function toggleTriggerInControls(
  controls: TrackControls,
  index: number
): TrackControls {
  let newTriggers = controls.beatDivisionAdwarTriggers ?? [];
  if (newTriggers.find((t) => t.index === index)) {
    newTriggers = newTriggers.filter((t) => t.index !== index);
  } else {
    newTriggers = [...newTriggers, { index, velocity: 75 }];
  }
  return { ...controls, beatDivisionAdwarTriggers: newTriggers };
}

function setTriggerVelocityInControls(
  controls: TrackControls,
  index: number,
  velocity: number
): TrackControls {
  let newTriggers = controls.beatDivisionAdwarTriggers ?? [];
  let triggerIdx = newTriggers.findIndex((t) => t.index === index);
  if (triggerIdx < 0) return controls;
  return {
    ...controls,
    beatDivisionAdwarTriggers: [
      ...newTriggers.slice(0, triggerIdx),
      { index, velocity },
      ...newTriggers.slice(triggerIdx + 1),
    ],
  };
}
