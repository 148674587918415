import produce from "immer";
import { Output, Input } from "webmidi";
import { Frequency } from "tone";
import { isNull, isNumber, uniqBy, take, sortBy, last, maxBy } from "lodash";

export const MIN_NUMBER_OF_OCTAVE_DIVISIONS = 1;
export const MAX_NUMBER_OF_OCTAVE_DIVISIONS = 1200;

export type Solmization =
  | "english"
  | "solfege"
  | "northIndian"
  | "southIndian"
  | "german"
  | "dutch"
  | "japanese"
  | "javanese"
  | "byzantine";

export const SCALE_DEGREE_KEYBOARD_MAPPING = new Map([
  [0, 0],
  [1, 1],
  [2, 1],
  [3, 2],
  [4, 3],
  [5, 3],
  [6, 4],
  [7, 5],
  [8, 6],
  [9, 6],
  [10, 7],
  [11, 8],
  [12, 8],
  [13, 9],
  [14, 10],
  [15, 10],
  [16, 11],
]);
export const DEFAULT_KEYBOARD_TO_SCALE_DEGREE_MAPPING = new Map([
  [0, 0],
  [1, 1],
  [2, 3],
  [3, 5],
  [4, 6],
  [5, 7],
  [6, 8],
  [7, 10],
  [8, 12],
  [9, 13],
  [10, 15],
  [11, 16],
]);

export interface TuningSystemHeader {
  id?: number;
  category: string;
  name: string;
  description: string;
  source: string;
  refPitchNoteName: string;
  refPitchNoteMidi: number;
  refPitchHz: number;
}
export interface TuningSystem extends TuningSystemHeader {
  strings: TString[];
}
export interface ScaleHeader {
  id?: number;
  name: string;
  category?: string;
  description: string;
  source: string;
  tuningSystemId?: number;
}
export interface Scale extends ScaleHeader {
  solmization: Solmization;
  scaleDegrees: ScaleDegree[];
}
export type PitchClassId = number;
export interface Pitch {
  cents?: number;
  ratioUpper?: number;
  ratioLower?: number;
}
export interface TString extends Pitch {
  id?: number;
  name?: string;
  pitchClasses: PitchClass[];
}
export interface PitchClass extends Pitch {
  id?: PitchClassId;
  name8ve1: string;
  name8ve2: string;
}

export type ScaleDegreeRole = "tonic" | "primary" | "secondary" | "none";
export interface ScaleDegree {
  id?: number;
  stringIndex: number;
  pitchClassIndex: number;
  map: number | null;
  role?: ScaleDegreeRole;
}

export interface MIDIInput {
  input?: Input;
  channel: number | "all";
}
export interface MIDIOutput {
  output?: Output;
  internal?: "synth" | "string";
  channel: number | "all" | "mpe";
  pitchBendRangeCents: number;
}

export type UserProfile = {
  username: string;
  twitterHandle?: string;
  country?: string;
};

export type Weights = { [wKey: string]: number };

export type TuningSnapshot = {
  id: string;
  name: string;
  description: string;
  content: TuningSnapshotContent;
  createdByName: string;
  createdByEmail: string;
  createdAt: string;
  updatedAt: string;
};

export type TuningSnapshotContent = {
  tuningSystem?: TuningSystem;
  scale?: Scale;
  roleWeights?: Weights;
  scaleDegreeWeights?: Weights;
  activeScaleWeights?: "role" | "degree";
};

export interface State {
  tuningSystems: TuningSystemHeader[];
  scales: ScaleHeader[];
  currentTuningSystem?: TuningSystem;
  currentScale?: Scale;
  currentlyPlaying: { stringIdx: number; pcIdx: number; keyCode: number }[];
  currentMIDIInput: MIDIInput;
  currentMIDIOutput: MIDIOutput;
  keyboardOctave: number;
}

interface LoadTuningSystemsAction {
  type: "loadTuningSystems";
  tuningSystems: TuningSystemHeader[];
}
interface CreateNewTuningSystem {
  type: "createNewTuningSystem";
}
interface SetCurrentTuningSystem {
  type: "setCurrentTuningSystem";
  tuningSystem: TuningSystem;
  scales: ScaleHeader[];
}
interface CreateNewScaleAction {
  type: "createNewScale";
}
interface SetCurrentScaleAction {
  type: "setCurrentScale";
  scale: Scale;
}
interface UpdateTuningSystemInfoAction {
  type: "updateTuningSystemInfo";
  update: Partial<TuningSystem>;
}
interface UpdateScaleInfoAction {
  type: "updateScaleInfo";
  update: Partial<Scale>;
}
interface SetReferencePitchHzAction {
  type: "setReferencePitchHz";
  referencePitchHz: number;
}
interface SetReferencePitchNoteNameAction {
  type: "setReferencePitchNoteName";
  referencePitchNoteName: string;
}
interface SetNumberOfOctaveDivisionsAction {
  type: "setNumberOfOctaveDivisions";
  numberOfOctaveDivisions: number;
}
interface SetCentsAction {
  type: "setCents";
  index: number;
  cents: number;
}
interface SetSolmizationAction {
  type: "setSolmization";
  solmization: Solmization;
}
interface SetMappingAction {
  type: "setMapping";
  pitchClassIndex: number;
  map: number | null;
}

interface KeyDownAction {
  type: "keyDown";
  stringIdx: number;
  pcIdx: number;
  keyCode: number;
}
interface KeyUpAction {
  type: "keyUp";
  stringIdx: number;
  pcIdx: number;
  keyCode: number;
}
interface SetOctaveAction {
  type: "setOctave";
  octave: number;
}
interface SetCurrentMIDIInputAction {
  type: "setCurrentMIDIInput";
  input: MIDIInput;
}
interface SetCurrentMIDIOutputAction {
  type: "setCurrentMIDIOutput";
  output: MIDIOutput;
}

type Action =
  | LoadTuningSystemsAction
  | CreateNewTuningSystem
  | SetCurrentTuningSystem
  | CreateNewScaleAction
  | SetCurrentScaleAction
  | UpdateTuningSystemInfoAction
  | UpdateScaleInfoAction
  | SetReferencePitchHzAction
  | SetReferencePitchNoteNameAction
  | SetNumberOfOctaveDivisionsAction
  | SetCentsAction
  | SetSolmizationAction
  | SetMappingAction
  | KeyDownAction
  | KeyUpAction
  | SetOctaveAction
  | SetCurrentMIDIInputAction
  | SetCurrentMIDIOutputAction;

export const initialState: State = {
  tuningSystems: [],
  scales: [],
  keyboardOctave: 0,
  currentlyPlaying: [],
  currentMIDIInput: { channel: "all" },
  currentMIDIOutput: { channel: "all", pitchBendRangeCents: 200 },
};

export const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case "loadTuningSystems":
      draft.tuningSystems = action.tuningSystems;
      break;
    case "createNewTuningSystem":
      draft.currentTuningSystem = {
        category: "",
        name: "",
        description: "",
        source: "",
        refPitchHz: 261.63,
        refPitchNoteName: "C4",
        refPitchNoteMidi: -1,
        strings: [{ ratioUpper: 1, ratioLower: 1, pitchClasses: [] }],
      };
      draft.scales = [];
      draft.currentScale = undefined;
      break;
    case "setCurrentTuningSystem":
      draft.currentTuningSystem = action.tuningSystem;
      draft.scales = action.scales;
      draft.currentScale = undefined;
      break;
    case "createNewScale":
      draft.currentScale = {
        name: "",
        description: "",
        source: "",
        scaleDegrees: [],
        solmization: "solfege",
      };
      break;
    case "setCurrentScale":
      draft.currentScale = action.scale;
      break;
    case "updateTuningSystemInfo":
      draft.currentTuningSystem = {
        ...draft.currentTuningSystem!,
        ...action.update,
      };
      break;
    case "updateScaleInfo":
      draft.currentScale = { ...draft.currentScale!, ...action.update };
      break;
    case "setReferencePitchHz":
      draft.currentTuningSystem!.refPitchHz = action.referencePitchHz;
      draft.currentlyPlaying = [];
      break;
    case "setReferencePitchNoteName":
      draft.currentTuningSystem!.refPitchNoteName =
        action.referencePitchNoteName;
      break;
    case "setNumberOfOctaveDivisions":
      if (
        draft.currentTuningSystem!.strings[0].pitchClasses.length >
        action.numberOfOctaveDivisions
      ) {
        draft.currentTuningSystem!.strings[0].pitchClasses.length =
          action.numberOfOctaveDivisions;
      } else {
        let last = draft.currentTuningSystem!.strings[0].pitchClasses[
          draft.currentTuningSystem!.strings[0].pitchClasses.length - 1
        ] || { cents: 0, map: null, name8ve1: "", name8ve2: "" };
        while (
          draft.currentTuningSystem!.strings[0].pitchClasses.length <
          action.numberOfOctaveDivisions
        ) {
          draft.currentTuningSystem!.strings[0].pitchClasses.push({ ...last });
        }
      }
      draft.currentlyPlaying = [];
      break;
    case "setCents":
      draft.currentTuningSystem!.strings[0].pitchClasses[action.index].cents =
        action.cents;
      break;
    case "setSolmization":
      draft.currentScale!.solmization = action.solmization;
      break;
    case "setMapping":
      if (isNull(action.map)) {
        draft.currentScale!.scaleDegrees =
          draft.currentScale!.scaleDegrees.filter(
            (sd) => sd.pitchClassIndex !== action.pitchClassIndex
          );
      } else {
        let existingDegree = draft.currentScale!.scaleDegrees.find(
          (sd) => sd.pitchClassIndex === action.pitchClassIndex
        );
        if (existingDegree) {
          existingDegree.map = action.map;
        } else {
          draft.currentScale!.scaleDegrees.push({
            stringIndex: 0,
            pitchClassIndex: action.pitchClassIndex,
            map: action.map,
          });
        }
      }
      draft.currentlyPlaying = [];
      break;
    case "keyDown":
      draft.currentlyPlaying.push({
        stringIdx: action.stringIdx,
        pcIdx: action.pcIdx,
        keyCode: action.keyCode,
      });
      break;
    case "keyUp":
      draft.currentlyPlaying = draft.currentlyPlaying.filter(
        (p) =>
          !(
            p.stringIdx === action.stringIdx &&
            p.pcIdx === action.pcIdx &&
            p.keyCode === action.keyCode
          )
      );
      break;
    case "setOctave":
      draft.keyboardOctave = action.octave;
      break;
    case "setCurrentMIDIInput":
      draft.currentMIDIInput = action.input;
      draft.currentlyPlaying = [];
      break;
    case "setCurrentMIDIOutput":
      draft.currentMIDIOutput = action.output;
      draft.currentlyPlaying = [];
      break;
  }
  return draft;
});

export function centsToFrequency(
  cents: number,
  referencePitch: number,
  octave: number
) {
  let totalCents = cents + octave * 1200;
  return referencePitch * 2 ** (totalCents / 1200);
}

export function getMidiNoteName(pc: number) {
  return ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "Ab", "A", "Bb", "B"][pc];
}

export function isAccidental(sd: number) {
  let pc = SCALE_DEGREE_KEYBOARD_MAPPING.get(sd)!;
  return isAccidentalMidi(pc);
}

export function isAccidentalMidi(pc: number) {
  return pc === 1 || pc === 3 || pc === 6 || pc === 8 || pc === 10;
}

export function getScaleDegree(
  pithClassIdx: number,
  scaleDegrees: ScaleDegree[]
) {
  return scaleDegrees.find((sd) => sd.pitchClassIndex === pithClassIdx);
}

export function freqToMidi(freq: number) {
  return 69 + 12 * Math.log2(freq / 440);
}

export function midiToFreq(midi: number) {
  return Math.pow(2, (midi - 69) / 12) * 440;
}

export function formatPitch(pitch: Pitch, format: "cents" | "ratio") {
  if (format === "ratio") {
    if (isRatioPitch(pitch)) {
      let [numerator, denominator] = reduceRatio(
        pitch.ratioUpper!,
        pitch.ratioLower!
      );
      return `${toPrecision(numerator, 0)}:${toPrecision(denominator, 0)}`;
    } else {
      throw new Error(
        "Tried format pitch as a ratio when it is not defined as a ratio"
      );
    }
  } else {
    return toPrecision(getPitchCents(pitch), 0);
  }
}

function toPrecision(n: number, precision: number): number {
  return Math.round(n * Math.pow(10, precision)) / Math.pow(10, precision);
}
export function getPitchCents(pitch: Pitch) {
  if (isRatioPitch(pitch)) {
    let reducedPitch = reduceIntoOctave(pitch);
    return (
      (1200 * Math.log(reducedPitch.ratioUpper! / reducedPitch.ratioLower!)) /
      Math.log(2)
    );
  } else {
    return pitch.cents!;
  }
}

export function getPitchSum(p1: Pitch, p2: Pitch): Pitch {
  if (isRatioPitch(p1) && isRatioPitch(p2)) {
    return {
      ratioUpper: p1.ratioUpper! * p2.ratioUpper!,
      ratioLower: p1.ratioLower! * p2.ratioLower!,
    };
  } else {
    return { cents: getPitchCents(p1) + getPitchCents(p2) };
  }
}

export function getPitchDelta(p: Pitch, from: Pitch): Pitch {
  if (isRatioPitch(p) && isRatioPitch(from)) {
    return {
      ratioUpper: p.ratioUpper! * from.ratioLower!,
      ratioLower: p.ratioLower! * from.ratioUpper!,
    };
  } else {
    return { cents: getPitchCents(p) - getPitchCents(from) };
  }
}

export function reduceIntoOctave(pitch: Pitch): Pitch {
  if (isRatioPitch(pitch)) {
    let upper = pitch.ratioUpper!;
    let lower = pitch.ratioLower!;
    while (upper / lower >= 2) {
      lower *= 2;
    }
    while (upper / lower < 1) {
      upper *= 2;
    }
    return { ...pitch, ratioUpper: upper, ratioLower: lower };
  } else {
    return { ...pitch, cents: pitch.cents! % 1200 };
  }
}

export function isRatioPitch(pitch: Pitch) {
  return isNumber(pitch.ratioUpper) && isNumber(pitch.ratioLower);
}

export function reduceRatio(numerator: number, denominator: number) {
  function gcd(a: number, b: number): number {
    return b ? gcd(b, a % b) : a;
  }
  let g = gcd(numerator, denominator);
  return [numerator / g, denominator / g];
}

export function getExactOrEstimatedPitchRatio(pitch: Pitch) {
  if (isRatioPitch(pitch)) {
    return { numerator: pitch.ratioUpper!, denominator: pitch.ratioLower! };
  } else {
    let cents = getPitchCents(pitch);
    let decimalRatio =
      Math.round(1000000 * Math.pow(2, cents / 100 / 12)) / 1000000;
    return decimal2Rat(decimalRatio);
  }
}

function decimal2Rat(x: number) {
  let tolerance = 1.0e-6;
  let h1 = 1;
  let h2 = 0;
  let k1 = 0;
  let k2 = 1;
  let b = x;
  do {
    let a = Math.floor(b);
    let aux = h1;
    h1 = a * h1 + h2;
    h2 = aux;
    aux = k1;
    k1 = a * k1 + k2;
    k2 = aux;
    b = 1 / (b - a);
  } while (Math.abs(x - h1 / k1) > x * tolerance);

  return { numerator: h1, denominator: k1 };
}

export function getPrimeLimitRatios(
  limit: number,
  maxNumerator = 1_500_000,
  maxDenominator = 1_500_000,
  maxRatios = 500
): Pitch[] {
  let primes = getPrimesUpTo(limit);
  let limitSequence = generatePrimeLimitNumbers(primes);
  let result: Pitch[] = [{ ratioUpper: 1, ratioLower: 1 }];
  for (let i = 0; i < limitSequence.length; i++) {
    let ratioUpper = limitSequence[i];
    if (ratioUpper > maxNumerator) break;
    for (let j = i - 1; j >= 0; j--) {
      let ratioLower = limitSequence[j];
      if (ratioLower > maxDenominator) continue;
      let rat = ratioUpper / ratioLower;
      if (rat < 2 && rat > 1) {
        let [n, d] = reduceRatio(ratioUpper, ratioLower);
        result.push({ ratioUpper: n, ratioLower: d });
      } else if (rat <= 1) {
        break;
      }
    }
  }
  return take(
    uniqBy(result, (p) => `${p.ratioUpper}:${p.ratioLower}`),
    maxRatios
  );
}

function generatePrimeLimitNumbers(primes: number[], n = 1000) {
  let results: number[] = [1];
  let tests = primes.map((p) => p);
  let indexes = primes.map((p) => 0);
  for (let index = 1; index < n; index++) {
    results[index] = tests[getMinIndex(tests)];
    for (let p = 0; p < primes.length; p++) {
      if (results[index] === tests[p]) {
        indexes[p]++;
        tests[p] = primes[p] * results[indexes[p]];
      }
    }
  }
  return results;
}

function getPrimesUpTo(limit: number) {
  let primes = [2];
  for (let i = 3; i <= limit; i++) {
    if (isPrime(i)) {
      primes.push(i);
    }
  }
  return primes;
}

function isPrime(num: number) {
  for (let i = 2; i < num; i++) {
    if (num % i === 0) {
      return false;
    }
  }
  return true;
}

function getMinIndex(numbers: number[]) {
  let min = numbers[0],
    minIndex = 0;
  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] < min) {
      min = numbers[i];
      minIndex = i;
    }
  }
  return minIndex;
}

export function getRefPitchOctave(refPitchSt: number, refPitchNote?: string) {
  if (refPitchNote) {
    let noteSt = Frequency(refPitchNote).toMidi();
    return Math.floor(noteSt / 12) - 1;
  } else {
    return Math.floor(Math.floor(refPitchSt) / 12) - 1;
  }
}

export function getRefPitchClass(refPitchSt: number, refPitchNote?: string) {
  if (refPitchNote) {
    let noteSt = Frequency(refPitchNote).toMidi();
    return noteSt % 12;
  } else {
    return Math.floor(refPitchSt) % 12;
  }
}

export function getMonotonicallyIncreasingScale(
  scale: ScaleDegree[],
  strings: TString[]
) {
  let scaleCents = scale.map((s, scaleIndex) => ({
    cents: getPitchCents(
      reduceIntoOctave(
        getPitchSum(
          strings[s.stringIndex],
          strings[s.stringIndex].pitchClasses[s.pitchClassIndex]
        )
      )
    ),
    stringIndex: s.stringIndex,
    pitchClassIndex: s.pitchClassIndex,
    keyboardMapping: s.map!,
    role: s.role,
    scaleIndex,
  }));
  scaleCents = sortBy(scaleCents, (s) => s.keyboardMapping).reduce(
    (c, d) =>
      c.length === 0 || last(c)!.cents < d.cents
        ? [...c, d]
        : [...c, { ...d, cents: d.cents + 1200 }],
    [] as {
      cents: number;
      stringIndex: number;
      pitchClassIndex: number;
      keyboardMapping: number;
      scaleIndex: number;
      role: ScaleDegreeRole | undefined;
    }[]
  );
  return scaleCents;
}

export function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function weightedRandom(options: string[], weights: Weights) {
  let totalWeight = options.reduce((sum, r) => sum + weights[r], 0);
  if (totalWeight === 0) return undefined;

  let rnd = Math.random() * totalWeight;
  let sum = 0;
  for (let opt of options) {
    sum += weights[opt];
    if (rnd <= sum) {
      return opt;
    }
  }
}

export function weightedTop(options: string[], weights: Weights) {
  return maxBy(options, (o) => weights[o] ?? 0);
}
