import {
  getTransport,
  TransportTime,
  context,
  dbToGain,
  getDraw,
  Part,
  Ticks,
  immediate,
  TicksClass,
} from "tone";
import {
  isNumber,
  memoize,
  startCase,
  findIndex,
  debounce,
  random,
  isNull,
  findLast,
  max,
  partition,
  isEqual,
  flatten,
  intersection,
  difference,
  flatMap,
  uniqueId,
  isUndefined,
} from "lodash";
import {
  getMonotonicallyIncreasingScale,
  getRefPitchOctave,
  MIDIOutput,
  midiToFreq,
  Scale,
  TuningSystem,
  weightedRandom,
} from "../main/core";
import { Rnd } from "react-rnd";
import EventEmitter from "events";
import { getClosestMIDINote, getMIDIPitchBend } from "../main/audio";
import { Output } from "webmidi";
import { Simple1DNoise } from "./noise";
import * as lfos from "./lfos";
import * as adwar from "./adwarCompat";
import {
  NoteValue,
  DEXEDState,
  InstrumentBanks,
  OBXDState,
  ReturnTrackSettings,
  TimeSignatureDenominator,
  TrackControls,
  TrackInstrument,
  TransportControls,
  YoshimiState,
  NoteValueDivision,
  PhaseDivision,
  BeatDivision,
} from "./types";
import { LocalApotomePlayer } from "./LocalApotomePlayer";
import {
  NOTE_VALUE_BEAT_VALUES,
  BEAT_DIVISION_TUPLETS as NOTE_VALUE_TUPLETS,
  DEFAULT_RETURN_TRACK_SETTINGS,
  DEFAULT_TIME_SIGNATURE_DENOMINATOR,
  DEFAULT_TIME_SIGNATURE_NUMERATOR,
  MIN_VOLUME_DB,
  SYNCED_ECHO_OPTIONS,
  MIN_BASE_VELOCITY,
  MAX_BASE_VELOCITY,
  ACCENT_VELOCITY_DELTA,
  DEFAULT_TEMPO,
  MIN_NEGATIVE_NOTE_DELAY_S,
} from "./constants";
import { CompositeApotomePlayer } from "./CompositeApotomePlayer";
import { ApotomePlayer } from "./ApotomePlayer";
import {
  MIDI_CHANNEL_MPE_ROUND_ROBIN_MAX,
  MIDI_CHANNEL_MPE_ROUND_ROBIN_MIN,
  MIDI_CHANNEL_ROUND_ROBIN_MAX,
  MIDI_CHANNEL_ROUND_ROBIN_MIN,
} from "../constants";
import { MIDIOutputManager } from "./MIDIOutputManager";
import * as melody from "./melody";

export type PlayableScaleDegree = {
  role: "Tonic" | "Primary" | "Secondary" | "None" | "Rest";
  index: number;
  cents: number;
  stringIndex: number;
  pitchClassIndex: number;
  keyboardMapping: number;
};

export type PlayedNote = {
  type: "note";
  beatDivision: BeatDivision;
  beatDivisionRelativeDuration: number;
  chosenScaleWeight: string;
  scaleDegree: PlayableScaleDegree;
  interval: number | "8ve" | null;
  octave: number;
  freq: number;
  baseVelocity: number;
  velocity: number;
  time: number;
  ticks: number;
  delay: number;
  duration: number;
  stringIndex: number;
  pitchClassIndex: number;
};
export type PlayedRest = {
  type: "rest";
  beatDivision: BeatDivision;
  time: number;
  ticks: number;
  duration: number;
};
type PlayedEvent = PlayedNote | PlayedRest;

type VoiceState = {
  eventHistory: PlayedEvent[];
  queuedUpTupletRepeats: NoteValue[];
  velocityNoiseSeed: number;
  articulationSeed: number;
  noteDelaySeed: number;
  nextSchedule?: number;
  currentLoopPart?: Part;
  currentLoopLength?: number;
  currentLoopStartedOnTick?: number;
  euclideanIndex: number;
};
export type TrackState = {
  hasBasicSynth: boolean;
  hasString: boolean;
  hasObxd: boolean;
  lastObxdState?: OBXDState;
  hasDexed: boolean;
  lastDexedState?: DEXEDState;
  hasYoshimi: boolean;
  lastYoshimiState?: YoshimiState;
  controls: TrackControls;
  voices: [
    VoiceState,
    VoiceState,
    VoiceState,
    VoiceState,
    VoiceState,
    VoiceState,
    VoiceState,
    VoiceState
  ];
  startedOnTick?: number;
  nextNoteEchoes: Map<string, number>;
  lastPan: number;
  lastGain: number;
  lastSend1Gain: number;
  lastSend2Gain: number;
  lastTuningSystem?: TuningSystem;
  lastScale?: Scale;
  lfoStates: LFOModulatorState[];
  melodyShapeLfoStates: PitchLFOState[];
  melodyShapeLfoCurve: {
    time: number;
    octave: number;
    indexFraction: number;
    raw: number;
  }[];
  events: EventEmitter;
};

export type LFOState = {
  triggeredOnTick: number | null;
  pendingRetriggerTimes: number[];
  randomValue?: number;
  nextRandomValueAtTick?: number;
};

export type LFOModulatorState = LFOState & {
  lastTargets: { [instr in TrackInstrument]: number };
};

export type PitchLFOState = LFOState & { lastPhase: number };

let player = new CompositeApotomePlayer();

export function addPlayer(aPlayer: ApotomePlayer) {
  player.addPlayer(aPlayer);
}
export function removePlayer(aPlayer: ApotomePlayer) {
  player.removePlayer(aPlayer);
}

let noise = Simple1DNoise();

export let playbackEvents = new EventEmitter();

let midiOutputMgr = new MIDIOutputManager();

let tracks = new Map<string, TrackState>();
let transportControls: TransportControls = {
  tempo: DEFAULT_TEMPO,
  timeSignatureNumerator: DEFAULT_TIME_SIGNATURE_NUMERATOR,
  timeSignatureDenominator: DEFAULT_TIME_SIGNATURE_DENOMINATOR,
};
let returnTrackSettings = DEFAULT_RETURN_TRACK_SETTINGS;

let pluginGUIContainer: Rnd;

let midiOutputs: { [trackId: string]: MIDIOutput } = {};
let midiChannelRoundRobins = new Map<string, number>();
let midiClockOutputs: { output: Output; started: boolean }[] = [];
let midiClockTickSchedule: number | null = null;

export const getTrackScaleDegrees = memoize(
  (controls: TrackControls) => {
    if (controls.scale) {
      let scale = getMonotonicallyIncreasingScale(
        controls.scale.scaleDegrees,
        controls.tuningSystem!.strings
      ).map((sd) => ({
        role: sd.role ? startCase(sd.role) : "None",
        cents: sd.cents,
        stringIndex: sd.stringIndex,
        pitchClassIndex: sd.pitchClassIndex,
        keyboardMapping: sd.keyboardMapping,
      }));
      let tonicIndex = findIndex(scale, (s) => s.role === "Tonic");
      return [
        ...scale.slice(tonicIndex),
        ...scale
          .slice(0, tonicIndex)
          .map((sd) => ({ ...sd, cents: sd.cents + 1200 })),
      ].map((deg, index) => ({ ...deg, index })) as PlayableScaleDegree[];
    } else {
      return [];
    }
  },
  (controls: TrackControls) =>
    `${controls.tuningSystem?.id}-${controls.scale?.id}`
);

function getTrackTimeSignatureTicks(track: TrackState, currentTick: number) {
  let ticks = track.controls.overridetimeSignature
    ? getTimeSignatureTicks(
        track.controls.timeSignatureNumerator,
        track.controls.timeSignatureDenominator,
        getTransport().PPQ
      )
    : getTimeSignatureTicks(
        transportControls.timeSignatureNumerator,
        transportControls.timeSignatureDenominator,
        getTransport().PPQ
      );
  let trackTicks = track.controls.overridetimeSignature
    ? currentTick - track.startedOnTick!
    : currentTick;
  let currentTickWithinTimeSignature = trackTicks % ticks.totalTicks;
  return {
    totalTicks: ticks.totalTicks,
    currentTickWithinTimeSignature,
  };
}

function getTrackTimeSignatureTicksWithAccents(
  track: TrackState,
  currentTick: number
) {
  let { totalTicks, accentedTicks } = track.controls.overridetimeSignature
    ? getTimeSignatureTicks(
        track.controls.timeSignatureNumerator,
        track.controls.timeSignatureDenominator,
        getTransport().PPQ
      )
    : getTimeSignatureTicks(
        transportControls.timeSignatureNumerator,
        transportControls.timeSignatureDenominator,
        getTransport().PPQ
      );
  let trackTicks = track.controls.overridetimeSignature
    ? currentTick - track.startedOnTick!
    : currentTick;
  let currentTickWithinTimeSignature = trackTicks % totalTicks;
  let isCurrentTickAccented = accentedTicks.has(currentTickWithinTimeSignature);
  return {
    totalTicks,
    accentedTicks,
    currentTickWithinTimeSignature,
    isCurrentTickAccented,
  };
}

export function getTrackPhase(trackId: string, atTicks = getTransport().ticks) {
  let trackState = tracks.get(trackId);
  if (trackState) {
    let { totalTicks, currentTickWithinTimeSignature } =
      getTrackTimeSignatureTicks(trackState, atTicks);
    let phase = currentTickWithinTimeSignature / totalTicks;
    return phase;
  } else {
    return 0;
  }
}

export let getTimeSignatureTicks = memoize(
  (numerator: string, denominator: TimeSignatureDenominator, ppq: number) => {
    let compounds = numerator.split(/[^\d]/).map((c) => +c);
    let totalBeats = compounds.reduce((sum, c) => sum + c, 0);
    let denominatorQuarters = 4 / denominator;
    let denominatorTicks = denominatorQuarters * ppq;
    let totalTicks = totalBeats * denominatorTicks;
    let accentedTicks = new Set<number>();
    let tick = 0;
    for (let c of compounds) {
      accentedTicks.add(tick);
      tick += c * denominatorTicks;
    }
    return { totalTicks, accentedTicks };
  },
  (n: string, d: number, ppq: number) => `${n}/${d}@${ppq}`
);

function chooseBeatDivision(
  track: TrackState,
  voice: VoiceState,
  atTicks: number
):
  | (NoteValueDivision & { count: number; forceRest: boolean })
  | (PhaseDivision & { forceRest: boolean; velocity: number })
  | null {
  let controls = track.controls;
  switch (controls.beatDivisionType) {
    case "weights": {
      let division = weightedRandom(
        Object.keys(controls.beatDivisionWeights),
        controls.beatDivisionWeights
      );
      if (division) {
        return {
          type: "notevalue",
          value: division as NoteValue,
          count: 1,
          forceRest: false,
        };
      } else {
        return null;
      }
    }
    case "euclidean": {
      let pattern = euclideanPattern(
        controls.beatDivisionEuclideanK,
        controls.beatDivisionEuclideanN
      );
      let index = voice.euclideanIndex;
      if (pattern[index % pattern.length] > 0) {
        let count = 1;
        index++;
        while (pattern[index % pattern.length] <= 0 && count < pattern.length) {
          count++;
          index++;
        }
        track.events.emit(
          "euclideanStep",
          voice.euclideanIndex % pattern.length
        );
        voice.euclideanIndex = index;
        return {
          type: "notevalue",
          value: controls.beatDivisionEuclideanBeatValue,
          count,
          forceRest: false,
        };
      } else {
        let count = 0;
        while (pattern[index % pattern.length] <= 0 && count < pattern.length) {
          count++;
          index++;
        }
        voice.euclideanIndex = index;
        track.events.emit("euclideanStep", index % pattern.length);
        return {
          type: "notevalue",
          value: controls.beatDivisionEuclideanBeatValue,
          count,
          forceRest: true,
        };
      }
    }
    case "adwar": {
      let { currentTickWithinTimeSignature, totalTicks } =
        getTrackTimeSignatureTicks(track, atTicks);
      let phasing = adwar.getPhasing(track.controls);
      let triggerTicks = phasing.triggers.map((p) =>
        Math.round(p.phase * totalTicks)
      );
      let currentTriggerIndex = triggerTicks.indexOf(
        currentTickWithinTimeSignature
      );

      if (currentTriggerIndex >= 0) {
        let ticksToNext =
          currentTriggerIndex < triggerTicks.length - 1
            ? triggerTicks[currentTriggerIndex + 1] -
              currentTickWithinTimeSignature
            : totalTicks - currentTickWithinTimeSignature + triggerTicks[0];
        return {
          type: "phase",
          value: phasing.triggers[currentTriggerIndex].phase,
          velocity: phasing.triggers[currentTriggerIndex].velocity / 127,
          ticks: ticksToNext,
          forceRest: false,
        };
      } else {
        let nextTriggerIndex = triggerTicks.findIndex(
          (t) => t > currentTickWithinTimeSignature
        );
        if (nextTriggerIndex >= 0) {
          return {
            type: "phase",
            value: -1,
            velocity: 0,
            ticks:
              triggerTicks[nextTriggerIndex] - currentTickWithinTimeSignature,
            forceRest: true,
          };
        } else if (triggerTicks.length > 0) {
          return {
            type: "phase",
            value: -1,
            velocity: 0,
            ticks:
              totalTicks - currentTickWithinTimeSignature + triggerTicks[0],
            forceRest: true,
          };
        } else {
          return {
            type: "phase",
            value: -1,
            velocity: 0,
            ticks: totalTicks - currentTickWithinTimeSignature,
            forceRest: true,
          };
        }
      }
    }
  }
}

export let euclideanPattern = memoize(
  (k: number, n: number) => {
    let seq: number[][] = [];
    for (let i = 0; i < k; i++) {
      seq.push([1]);
    }
    for (let i = 0; i < n - k; i++) {
      seq.push([0]);
    }
    while (true) {
      let [head, remainder] = partition(seq, (i) => isEqual(i, seq[0]));
      if (remainder.length < 2) break;
      for (let i = 0; i < Math.min(head.length, remainder.length); i++) {
        seq[i] = seq[i].concat(seq.pop()!);
      }
    }
    return flatten(seq);
  },
  (k: number, n: number) => `${k}-${n}`
);

export function nextTrackId() {
  let mx = max(Array.from(tracks.keys()).map((i) => +i)) ?? 0;
  return `${mx + 1}`;
}

export function getTrackVoiceCounts(): Map<string, number> {
  let counts = new Map<string, number>();
  for (let [id, track] of Array.from(tracks.entries())) {
    counts.set(id, track.controls.voiceCount);
  }
  return counts;
}

export async function init(
  latencyHint?: number,
  lookAhead?: number
): Promise<{ banks: InstrumentBanks; isSupported: boolean }> {
  let banks = await LocalApotomePlayer.init(latencyHint, lookAhead);
  getTransport().bpm.value = DEFAULT_TEMPO;
  let localPlayer = new LocalApotomePlayer();
  player.addPlayer(localPlayer);
  startLoopers();
  startLFOTicks();
  return { banks, isSupported: localPlayer.isSupported() };
}

export async function start() {
  if (getTransport().state === "started") return;
  let startTicks = getTransport().ticks;
  let startTime = getTransport().now();
  for (let [id, track] of Array.from(tracks.entries())) {
    if (track.controls.started) {
      track.startedOnTick = startTicks;
      for (let voiceIdx = 0; voiceIdx < track.controls.voiceCount; voiceIdx++) {
        playVoiceStep(
          track,
          track.voices[voiceIdx],
          id,
          voiceIdx,
          startTicks,
          startTime
        );
      }
    }
  }
  await player.start();
  getTransport().start();
  playbackEvents.emit("start");
}

export async function stop(effectiveAtTicks?: number) {
  if (getTransport().state === "stopped") return;
  let doStop = () => {
    getTransport().stop();
    for (let track of Array.from(tracks.values())) {
      for (let voice of track.voices) {
        if (isNumber(voice.nextSchedule)) {
          getTransport().clear(voice.nextSchedule);
          voice.nextSchedule = undefined;
        }
        track.startedOnTick = undefined;
      }
      for (let [noteId, schedule] of track.nextNoteEchoes.entries()) {
        getTransport().clear(schedule);
        track.nextNoteEchoes.delete(noteId);
      }
    }
    let oneClockTickS = getTransport().toSeconds(`${getTransport().PPQ / 24}i`);
    let lookaheadS = context.lookAhead;
    let stopDelayS = lookaheadS * 2 + oneClockTickS;
    for (let clockOut of midiClockOutputs) {
      midiOutputMgr.stop(
        clockOut.output,
        midiOutputMgr.now() + stopDelayS * 1000
      );
      clockOut.started = false;
    }
    playbackEvents.emit("stop");
  };
  if (isNumber(effectiveAtTicks)) {
    getTransport().scheduleOnce(doStop, `${effectiveAtTicks}i`);
  } else {
    doStop();
  }
}

export function isRunning() {
  return getTransport().state === "started";
}

export function getTransportControls() {
  return transportControls;
}
export function setTransportControls(newTransportControls: TransportControls) {
  if (transportControls.tempo !== newTransportControls.tempo) {
    getTransport().bpm.value = newTransportControls.tempo;
    if (returnTrackSettings.echoTempoSync) {
      player.setEchoDelayTimes(
        getTransport().toSeconds(
          SYNCED_ECHO_OPTIONS[returnTrackSettings.echoDelayLeftSynced]
        ),
        getTransport().toSeconds(
          SYNCED_ECHO_OPTIONS[returnTrackSettings.echoDelayRightSynced]
        ),
        immediate()
      );
    }
  }
  transportControls = newTransportControls;
  playbackEvents.emit("transportControlsChange", newTransportControls);
}

export function setMasterVolume(newMasterVolume: number, rampTime = 0.03) {
  player.setMasterVolume(newMasterVolume, rampTime, immediate());
  playbackEvents.emit("masterVolumeChange", newMasterVolume);
}

export function resync() {
  let startTime = getTransport().nextSubdivision(`4n`);
  let startTicks = getTransport().getTicksAtTime(startTime);
  for (let [id, track] of Array.from(tracks.entries())) {
    if (track.controls.started) {
      for (let voice of track.voices) {
        if (isNumber(voice.nextSchedule)) {
          getTransport().clear(voice.nextSchedule);
        }
        voice.euclideanIndex = 0;
      }
      for (let voiceIdx = 0; voiceIdx < track.controls.voiceCount; voiceIdx++) {
        playVoiceStep(
          track,
          track.voices[voiceIdx],
          id,
          voiceIdx,
          startTicks,
          startTime
        );
      }
      track.startedOnTick = startTicks;
    }
  }
}

export function sendMidiPanic() {
  for (let midiOutput of Object.values(midiOutputs)) {
    if (midiOutput.output) {
      midiOutputMgr.channelMode(
        midiOutput.output,
        "allnotesoff",
        midiOutputMgr.now()
      );
      midiOutputMgr.channelMode(
        midiOutput.output,
        "resetallcontrollers",
        midiOutputMgr.now()
      );
    }
  }
  player.panic(immediate());
}

export let updateReturnTrackSettings = debounce(
  (newSettings: ReturnTrackSettings) => {
    returnTrackSettings = newSettings;
    player.setReverbSettings(
      newSettings.reverbDecay,
      newSettings.reverbPreDelay
    );
    player.setEchoFeedback(newSettings.echoFeedback, immediate());
    if (newSettings.echoTempoSync) {
      player.setEchoDelayTimes(
        getTransport().toSeconds(
          SYNCED_ECHO_OPTIONS[newSettings.echoDelayLeftSynced]
        ),
        getTransport().toSeconds(
          SYNCED_ECHO_OPTIONS[newSettings.echoDelayRightSynced]
        ),
        immediate()
      );
    } else {
      player.setEchoDelayTimes(
        newSettings.echoDelayLeftFree / 1000,
        newSettings.echoDelayRightFree / 1000,
        immediate()
      );
    }
  },
  200
);

export function setTrack(
  id: string,
  controls: TrackControls,
  launchTime?: number,
  launchTicks?: number
) {
  let track = tracks.get(id);
  if (!track) {
    track = {
      controls,
      hasBasicSynth: false,
      hasString: false,
      hasObxd: false,
      hasDexed: false,
      hasYoshimi: false,
      events: new EventEmitter(),
      lastPan: 0,
      lastGain: 1,
      lastSend1Gain: 1,
      lastSend2Gain: 1,
      voices: [
        {
          eventHistory: [],
          queuedUpTupletRepeats: [],
          velocityNoiseSeed: random(1000),
          articulationSeed: random(1000),
          noteDelaySeed: random(1000),
          euclideanIndex: 0,
        },
        {
          eventHistory: [],
          queuedUpTupletRepeats: [],
          velocityNoiseSeed: random(1000),
          articulationSeed: random(1000),
          noteDelaySeed: random(1000),
          euclideanIndex: 0,
        },
        {
          eventHistory: [],
          queuedUpTupletRepeats: [],
          velocityNoiseSeed: random(1000),
          articulationSeed: random(1000),
          noteDelaySeed: random(1000),
          euclideanIndex: 0,
        },
        {
          eventHistory: [],
          queuedUpTupletRepeats: [],
          velocityNoiseSeed: random(1000),
          articulationSeed: random(1000),
          noteDelaySeed: random(1000),
          euclideanIndex: 0,
        },
        {
          eventHistory: [],
          queuedUpTupletRepeats: [],
          velocityNoiseSeed: random(1000),
          articulationSeed: random(1000),
          noteDelaySeed: random(1000),
          euclideanIndex: 0,
        },
        {
          eventHistory: [],
          queuedUpTupletRepeats: [],
          velocityNoiseSeed: random(1000),
          articulationSeed: random(1000),
          noteDelaySeed: random(1000),
          euclideanIndex: 0,
        },
        {
          eventHistory: [],
          queuedUpTupletRepeats: [],
          velocityNoiseSeed: random(1000),
          articulationSeed: random(1000),
          noteDelaySeed: random(1000),
          euclideanIndex: 0,
        },
        {
          eventHistory: [],
          queuedUpTupletRepeats: [],
          velocityNoiseSeed: random(1000),
          articulationSeed: random(1000),
          noteDelaySeed: random(1000),
          euclideanIndex: 0,
        },
      ],
      lfoStates: controls.lfos.map(() => ({
        triggeredOnTick: null,
        pendingRetriggerTimes: [],
        lastTargets: {
          basicSynth: -1,
          obxd: -1,
          dexed: -1,
          midi: -1,
          string: -1,
          yoshimi: -1,
        },
      })),
      melodyShapeLfoStates: controls.melodyShapeLfos.map(() => ({
        triggeredOnTick: null,
        pendingRetriggerTimes: [],
        lastPhase: 0,
      })),
      melodyShapeLfoCurve: [],
      nextNoteEchoes: new Map(),
    };
    track.events.setMaxListeners(100);
    tracks.set(id, track);
    player.addTrack(id);
  }

  if (track.controls.scale !== controls.scale) {
    for (let voice of track.voices) {
      voice.eventHistory.length = 0;
    }
  }
  track.controls = controls;

  if (controls.instrument === "basicSynth") {
    if (!track.hasBasicSynth) {
      player.initTrackBasicSynth(
        id,
        controls.tone,
        controls.amplitudeEnvelope,
        controls.filterFrequency,
        controls.filterResonance
      );
      track.hasBasicSynth = true;
    }
    player.setTrackBasicSynthToneControls(
      id,
      controls.tone,
      controls.amplitudeEnvelope
    );
    player.setTrackBasicSynthFilterFrequency(
      id,
      controls.filterFrequency,
      immediate()
    );
    player.setTrackBasicSynthFilterQ(id, controls.filterResonance, immediate());
  } else {
    if (track.hasBasicSynth) {
      player.disposeTrackBasicSynth(id, immediate());
      track.hasBasicSynth = false;
    }
  }

  if (controls.instrument === "string") {
    if (!track.hasString) {
      player.initTrackString(id);
      track.hasString = true;
    }
  } else {
    if (track.hasString) {
      player.disposeTrackString(id, immediate());
      track.hasString = false;
    }
  }

  if (controls.instrument === "obxd") {
    if (!track.hasObxd) {
      let onLocalParamChange = (
        param: number,
        value: number,
        emitEvent: boolean
      ) => {
        player.setOBXDParam(id, param, value);
        if (emitEvent) {
          track?.events.emit("controlsChange", tracks.get(id)?.controls);
        }
      };
      player.initTrackOBXD(id, onLocalParamChange);
      track.hasObxd = true;
    }
    if (controls.obState !== track.lastObxdState) {
      player.setOBXDState(
        id,
        controls.obState.bank,
        controls.obState.preset,
        controls.obState.patchState
      );
      track.lastObxdState = controls.obState;
    }
    if (controls.pluginGUIOpen) {
      player.openOBXDGUI(id, pluginGUIContainer);
    } else {
      player.closeOBXDGUI(id);
    }
  } else {
    if (track.hasObxd) {
      player.disposeTrackOBXD(id, immediate());
      track.hasObxd = false;
      track.lastObxdState = undefined;
    }
  }

  if (controls.instrument === "dexed") {
    if (!track.hasDexed) {
      let onLocalParamChange = (
        param: number,
        value: number,
        newPackedPatch: number[]
      ) => {
        player.setDEXEDParam(id, param, value, newPackedPatch);
      };
      player.initTrackDEXED(id, onLocalParamChange);
      track.hasDexed = true;
    }
    if (controls.dxState !== track.lastDexedState) {
      player.setDEXEDState(
        id,
        controls.dxState.bank,
        controls.dxState.preset,
        controls.dxState.patchState
      );
      track.lastDexedState = controls.dxState;
    }
    if (controls.pluginGUIOpen) {
      player.openDEXEDGUI(id, pluginGUIContainer);
    } else {
      player.closeDEXEDGUI(id);
    }
  } else {
    if (track.hasDexed) {
      player.disposeTrackDEXED(id, immediate());
      track.hasDexed = false;
      track.lastDexedState = undefined;
    }
  }

  if (controls.instrument === "yoshimi") {
    if (!track.hasYoshimi) {
      player.initTrackYoshimi(id);
      track.hasYoshimi = true;
    }
    if (controls.yoshimiState !== track.lastYoshimiState) {
      player.setYoshimiState(
        id,
        controls.yoshimiState.bank,
        controls.yoshimiState.preset
      );
      track.lastYoshimiState = controls.yoshimiState;
    }
  } else {
    if (track.hasYoshimi) {
      player.disposeTrackYoshimi(id, immediate());
      track.hasYoshimi = false;
      track.lastYoshimiState = undefined;
    }
  }

  if (controls.instrument === "midi") {
    sendMidiTuningSysex(id, controls);
  }

  if (track.lastPan !== track.controls.pan) {
    player.setTrackPan(id, track.controls.pan, immediate());
    track.lastPan = track.controls.pan;
  }

  let isMuted = track.controls.muted || track.controls.soloStatus === "other";
  let newGain =
    isMuted || track.controls.volume === MIN_VOLUME_DB
      ? 0
      : dbToGain(track.controls.volume);
  if (track.lastGain !== newGain) {
    player.setTrackGain(id, newGain, immediate());
    track.lastGain = newGain;
  }
  if (track.lastSend1Gain !== track.controls.send1Gain) {
    player.setTrackSend1Gain(id, track.controls.send1Gain, immediate());
    track.lastSend1Gain = track.controls.send1Gain;
  }
  if (track.lastSend2Gain !== track.controls.send2Gain) {
    player.setTrackSend2Gain(id, track.controls.send2Gain, immediate());
    track.lastSend2Gain = track.controls.send2Gain;
  }

  hidePluginGUIContainerIfNoGUIOpen();

  if (
    track.controls.tuningSystem &&
    track.controls.scale &&
    (track.lastTuningSystem !== track.controls.tuningSystem ||
      track.lastScale !== track.controls.scale)
  ) {
    track.lastTuningSystem = track.controls.tuningSystem;
    track.lastScale = track.controls.scale;

    let tunings = Array.from(tracks.values()).map((track) => ({
      tuningSystem: track.lastTuningSystem,
      scale: track.lastScale,
    }));
    playbackEvents.emit("tuningsChange", tunings);
    player.setCurrentTunings(tunings);
  }

  let startTimeForNewVoices = launchTime;
  let startTicksForNewVoices = launchTicks;
  if (isUndefined(launchTime) || isUndefined(launchTicks)) {
    if (isNumber(track.startedOnTick)) {
      let trackTicks = getTrackTimeSignatureTicks(track, getTransport().ticks);
      let ticksFromNowToStart =
        trackTicks.totalTicks - trackTicks.currentTickWithinTimeSignature;
      startTicksForNewVoices = getTransport().ticks + ticksFromNowToStart;
      startTimeForNewVoices = getTransport().toSeconds(
        `${startTicksForNewVoices}i`
      ) as number;
    } else {
      if (getTransport().state === "started") {
        startTicksForNewVoices = getTransport().getTicksAtTime(
          getTransport().nextSubdivision(`4n`)
        );
        let secondsUntil = getTransport().toSeconds(
          `${startTicksForNewVoices - getTransport().ticks}i`
        );
        startTimeForNewVoices = getTransport().now() + secondsUntil;
      } else {
        startTicksForNewVoices = getTransport().ticks;
        startTimeForNewVoices = getTransport().now();
      }
    }
  }

  console.log(
    "for new voices time",
    startTimeForNewVoices,
    "ticks",
    startTicksForNewVoices
  );

  if (
    getTransport().state === "started" &&
    controls.started &&
    !isNumber(track.startedOnTick)
  ) {
    track.startedOnTick = startTicksForNewVoices;
    console.log("set track to start on tick", track.startedOnTick);
  } else if (!controls.started && track.startedOnTick) {
    track.startedOnTick = undefined;
    for (let [noteId, schedule] of track.nextNoteEchoes.entries()) {
      getTransport().clear(schedule);
      track.nextNoteEchoes.delete(noteId);
    }
  }

  for (let voiceIdx = 0; voiceIdx < track.voices.length; voiceIdx++) {
    if (
      getTransport().state === "started" &&
      controls.started &&
      !isNumber(track.voices[voiceIdx].nextSchedule) &&
      voiceIdx < controls.voiceCount
    ) {
      console.log("voice", voiceIdx, "starting");
      playVoiceStep(
        track,
        track.voices[voiceIdx],
        id,
        voiceIdx,
        startTicksForNewVoices!,
        startTimeForNewVoices!
      );
    } else if (
      (!controls.started || voiceIdx >= controls.voiceCount) &&
      isNumber(track.voices[voiceIdx].nextSchedule)
    ) {
      console.log("voice", voiceIdx, "stopping");
      getTransport().clear(track.voices[voiceIdx].nextSchedule!);
      track.voices[voiceIdx].nextSchedule = undefined;
      if (track.voices[voiceIdx].currentLoopPart) {
        track.voices[voiceIdx].currentLoopPart!.stop();
        track.voices[voiceIdx].currentLoopPart!.dispose();
      }
    }
  }
}

export function scheduleBatchUpdate(
  newTracks: { id: string; controls: TrackControls }[],
  newMidiOutputs: { [trackId: string]: MIDIOutput },
  newReturnTrackSettings: ReturnTrackSettings,
  newTransportControls: TransportControls,
  atTicks: number,
  startPlayback: boolean
) {
  return new Promise<void>((res) => {
    if (getTransport().state === "started") {
      let startTicks =
        atTicks >= 0
          ? atTicks
          : getTransport().getTicksAtTime(getTransport().nextSubdivision(`4n`));
      getTransport().scheduleOnce((t: number) => {
        let oldTrackIds = Array.from(tracks.keys());
        let newTrackIds = newTracks.map((t) => t.id);
        let tracksIdsToUpdate = intersection(oldTrackIds, newTrackIds);
        let trackIdsToRemove = difference(oldTrackIds, newTrackIds);
        let trackIdsToAdd = difference(newTrackIds, oldTrackIds);

        for (let trackId of trackIdsToRemove) {
          removeTrack(trackId);
        }
        for (let trackId of tracksIdsToUpdate) {
          setTrack(
            trackId,
            newTracks.find((t) => t.id === trackId)!.controls,
            t,
            startTicks
          );
        }
        for (let trackId of trackIdsToAdd) {
          setTrack(
            trackId,
            newTracks.find((t) => t.id === trackId)!.controls,
            t,
            startTicks
          );
        }
        updateReturnTrackSettings(newReturnTrackSettings);
        setTransportControls(newTransportControls);
        setMidiOutputs(newMidiOutputs);
        res();
      }, `${startTicks - 1}i`);
    } else {
      for (let trackId of Array.from(tracks.keys())) {
        removeTrack(trackId);
      }
      for (let newTrack of newTracks) {
        setTrack(newTrack.id, newTrack.controls);
      }
      setMidiOutputs(newMidiOutputs);
      updateReturnTrackSettings(newReturnTrackSettings);
      setTransportControls(newTransportControls);
      res();
    }
    if (startPlayback) {
      start();
    }
  });
}

function playVoiceStep(
  track: TrackState,
  voice: VoiceState,
  trackId: string,
  voiceIdx: number,
  atTicks: number,
  time: number
) {
  let beatDivisionToPlay: BeatDivision | null = null,
    baseVelocity = 1,
    multiplier = 1,
    forceRest = false;
  if (voice.queuedUpTupletRepeats.length > 0) {
    beatDivisionToPlay = {
      type: "notevalue",
      value: voice.queuedUpTupletRepeats.shift()!,
    };
  } else {
    let div = chooseBeatDivision(track, voice, atTicks);
    beatDivisionToPlay = div;
    if (div?.type === "notevalue") {
      multiplier = div.count;
      forceRest = div.forceRest;
      let tupletRepeats = 1;
      if (
        track.controls.beatDivisionType === "weights" &&
        track.controls.forceTuplets &&
        div.value in NOTE_VALUE_TUPLETS
      ) {
        tupletRepeats = NOTE_VALUE_TUPLETS[div.value];
      }

      for (let i = 0; i < tupletRepeats - 1; i++) {
        voice.queuedUpTupletRepeats.push(div.value);
      }
    } else if (div?.type === "phase") {
      forceRest = div.forceRest;
      baseVelocity = div.velocity;
    }
  }

  let nextTicks: number;
  if (beatDivisionToPlay) {
    let delayCompensatedTime = time - MIN_NEGATIVE_NOTE_DELAY_S;
    let beatDivisionTicks =
      beatDivisionToPlay.type === "notevalue"
        ? getTransport().toTicks({
            "1n": NOTE_VALUE_BEAT_VALUES[beatDivisionToPlay.value] * multiplier,
          })
        : beatDivisionToPlay.ticks;
    let duration = TransportTime(beatDivisionTicks, "i").toSeconds();
    let timeSignatureTicks = getTrackTimeSignatureTicksWithAccents(
      track,
      atTicks
    );
    let beatDivisionRelativeDuration =
      beatDivisionTicks / timeSignatureTicks.totalTicks;

    let allowRest = false;
    if (
      track.controls.beatDivisionType === "weights" &&
      beatDivisionToPlay.type === "notevalue"
    ) {
      let div = beatDivisionToPlay.value;
      let restToggle = track.controls.beatDivisionRestToggles.find(
        (t) => t.division === div
      );
      allowRest = restToggle ? restToggle.enabled : true;
    }
    let noteToPlay = forceRest
      ? { key: "Rest" }
      : melody.chooseNote(time, track.controls, allowRest, {
          lastNote: findLast(voice.eventHistory, (e) => e.type === "note") as
            | PlayedNote
            | undefined,
          concurrentNotes: getPlayedNotesOnTick(atTicks),
          shapeCurve: track.melodyShapeLfoCurve,
        });
    if (
      !voice.currentLoopPart &&
      noteToPlay.value?.scaleDegree &&
      isNumber(noteToPlay.value.octave)
    ) {
      let freq = calculateNoteFreq(track.controls, noteToPlay.value);
      let noteVelocity = chooseNoteVelocity(
        track,
        voice,
        atTicks,
        baseVelocity,
        timeSignatureTicks.isCurrentTickAccented
      );

      let delay = chooseNoteDelay(track, voice, duration, atTicks);
      let { choice: noteLength, duration: articulatedDuration } =
        chooseArticulatedDuration(
          beatDivisionToPlay,
          duration - delay,
          track,
          voice,
          atTicks
        );
      let playedNote: PlayedNote = {
        type: "note",
        beatDivision: beatDivisionToPlay,
        beatDivisionRelativeDuration,
        chosenScaleWeight: noteToPlay.key,
        scaleDegree: noteToPlay.value.scaleDegree,
        interval: noteToPlay.value.interval,
        octave: noteToPlay.value.octave,
        freq,
        baseVelocity,
        velocity: noteVelocity.velocity,
        time: delayCompensatedTime,
        ticks: atTicks,
        delay,
        duration,
        stringIndex: noteToPlay.value.scaleDegree.stringIndex,
        pitchClassIndex: noteToPlay.value.scaleDegree.pitchClassIndex,
      };
      playNoteOnTrack(
        track,
        trackId,
        voiceIdx,
        noteToPlay.value.scaleDegree.cents,
        noteToPlay.value.octave,
        freq,
        noteVelocity.velocity,
        noteVelocity.baseVelocity,
        noteVelocity.accented,
        delayCompensatedTime,
        delay,
        articulatedDuration,
        noteLength,
        playedNote
      );
      if (track.controls.noteEchoOn) {
        launchNoteEcho(
          uniqueId("noteecho"),
          track,
          trackId,
          voiceIdx,
          noteToPlay.value.scaleDegree.cents,
          noteToPlay.value.octave,
          freq,
          noteVelocity.velocity * track.controls.noteEchoFeed,
          noteVelocity.baseVelocity,
          noteVelocity.accented,
          atTicks,
          delay,
          articulatedDuration,
          noteLength,
          playedNote
        );
      }
      voice.eventHistory.push(playedNote);
    } else if (!voice.currentLoopPart) {
      let playedRest: PlayedRest = {
        type: "rest",
        beatDivision: beatDivisionToPlay,
        time: delayCompensatedTime,
        ticks: atTicks,
        duration,
      };
      playRestOnTrack(track, delayCompensatedTime, playedRest);
      voice.eventHistory.push(playedRest);
    }
    while (voice.eventHistory.length > 256) {
      voice.eventHistory.shift();
    }
    nextTicks = atTicks + beatDivisionTicks;
  } else {
    nextTicks = atTicks + getTransport().toTicks("1n");
  }

  voice.nextSchedule = getTransport().scheduleOnce(
    (t: number) => playVoiceStep(track, voice, trackId, voiceIdx, nextTicks, t),
    `${nextTicks}i`
  );
}

function launchNoteEcho(
  noteId: string,
  track: TrackState,
  id: string,
  voiceIdx: number,
  cents: number,
  octave: number,
  freq: number,
  velocity: number,
  baseVelocity: number,
  accented: boolean,
  atTicks: number,
  delay: number,
  articulatedDuration: number,
  noteLength: NoteValue | number,
  playedNote: PlayedNote
) {
  if (track.controls.noteEchoOn && velocity > 0.1) {
    let echoTicks = getTransport().toTicks({
      "1n": NOTE_VALUE_BEAT_VALUES[track.controls.noteEchoTime],
    });
    let echoAtTicks = atTicks + echoTicks;
    let schedule = getTransport().scheduleOnce((echoedTime: number) => {
      let delayCompensatedEchoedTime = echoedTime - MIN_NEGATIVE_NOTE_DELAY_S;
      playNoteOnTrack(
        track,
        id,
        voiceIdx,
        cents,
        octave,
        freq,
        velocity,
        baseVelocity,
        accented,
        delayCompensatedEchoedTime,
        delay,
        articulatedDuration,
        noteLength,
        playedNote
      );
      let nextVelocity = velocity * track.controls.noteEchoFeedback;
      launchNoteEcho(
        noteId,
        track,
        id,
        voiceIdx,
        cents,
        octave,
        freq,
        nextVelocity,
        baseVelocity,
        accented,
        echoAtTicks,
        delay,
        articulatedDuration,
        noteLength,
        playedNote
      );
    }, `${echoAtTicks}i`);
    track.nextNoteEchoes.set(noteId, schedule);
  } else {
    track.nextNoteEchoes.delete(noteId);
  }
}

function startLoopers() {
  let looperTicksPerStep = getTransport().toTicks("4n");
  let looperTicks = 0;
  getTransport().scheduleRepeat(
    function loopers(time) {
      for (let [id, track] of Array.from(tracks.entries())) {
        for (
          let voiceIdx = 0;
          voiceIdx < track.controls.voiceCount;
          voiceIdx++
        ) {
          let voice = track.voices[voiceIdx];
          if (
            track.controls.looper &&
            track.controls.looper !== voice.currentLoopLength
          ) {
            if (voice.currentLoopPart) {
              voice.currentLoopPart.stop(time);
              voice.currentLoopPart.dispose();
            }

            let { totalTicks } = getTrackTimeSignatureTicks(track, 0);
            let loopTicks = totalTicks * track.controls.looper;
            let loopDuration = `${loopTicks}i`;
            let ticksAtLoopStart =
              voice.currentLoopStartedOnTick ?? looperTicks;

            let ticksFrom = ticksAtLoopStart - loopTicks;
            let loopNotes: [
              TicksClass,
              { event: PlayedEvent; noteTicksInLoop: number }
            ][] = [];
            for (let playedEvent of voice.eventHistory) {
              let noteTicksInLoop = playedEvent.ticks - ticksFrom;
              if (noteTicksInLoop >= 0) {
                loopNotes.push([
                  Ticks(noteTicksInLoop),
                  { event: playedEvent, noteTicksInLoop },
                ]);
              }
            }

            let loopedTimes = -1;
            voice.currentLoopPart = new Part(
              (
                time: number,
                {
                  event,
                  noteTicksInLoop,
                }: { event: PlayedEvent; noteTicksInLoop: number }
              ) => {
                let delayCompensatedTime = time - MIN_NEGATIVE_NOTE_DELAY_S;
                if (event === loopNotes[0][1].event) {
                  loopedTimes++;
                }
                if (event.type === "note") {
                  let atTicks =
                    ticksAtLoopStart +
                    loopTicks * loopedTimes +
                    noteTicksInLoop;
                  let timeSignatureTicks =
                    getTrackTimeSignatureTicksWithAccents(track, atTicks);
                  let delay = chooseNoteDelay(
                    track,
                    voice,
                    event.duration,
                    atTicks
                  );
                  let { choice: noteLength, duration: articulatedDuration } =
                    chooseArticulatedDuration(
                      event.beatDivision,
                      event.duration - delay,
                      track,
                      voice,
                      atTicks
                    );
                  let noteVelocity = chooseNoteVelocity(
                    track,
                    voice,
                    atTicks,
                    event.baseVelocity,
                    timeSignatureTicks.isCurrentTickAccented
                  );
                  playNoteOnTrack(
                    track,
                    id,
                    voiceIdx,
                    event.scaleDegree.cents,
                    event.octave,
                    event.freq,
                    noteVelocity.velocity,
                    noteVelocity.baseVelocity,
                    noteVelocity.accented,
                    delayCompensatedTime,
                    delay,
                    articulatedDuration,
                    noteLength,
                    event
                  );
                } else {
                  playRestOnTrack(track, delayCompensatedTime, event);
                }
              },
              loopNotes as any
            );
            voice.currentLoopPart.loop = true;
            voice.currentLoopPart.loopStart = 0;
            voice.currentLoopPart.loopEnd = loopDuration;
            voice.currentLoopPart.start(
              TransportTime(getTransport().ticks, "i").toSeconds()
            );
            voice.currentLoopLength = track.controls.looper;
            voice.currentLoopStartedOnTick = ticksAtLoopStart;
          } else if (voice.currentLoopPart && !track.controls.looper) {
            voice.currentLoopPart.stop(time);
            voice.currentLoopPart.dispose();
            voice.currentLoopPart = undefined;
            voice.currentLoopLength = undefined;
            voice.currentLoopStartedOnTick = undefined;
          }
        }
      }
      looperTicks += looperTicksPerStep;
    },
    "4n",
    0
  );
}

function startLFOTicks() {
  getTransport().scheduleRepeat(
    (time) => {
      let delayCompensatedTime = time - MIN_NEGATIVE_NOTE_DELAY_S;
      lfos.runLFOTick(
        tracks,
        player,
        midiOutputs,
        midiOutputMgr,
        delayCompensatedTime
      );
    },
    "16n",
    0
  );
}

function getPlayedNotesOnTick(tick: number) {
  let result: {
    note: PlayedNote;
    forcePolyphony: "none" | "unison" | "octaves";
  }[] = [];
  tracks.forEach((track) => {
    for (let voice of track.voices) {
      for (let i = voice.eventHistory.length - 1; i >= 0; i--) {
        if (
          voice.eventHistory[i].ticks === tick &&
          voice.eventHistory[i].type === "note"
        ) {
          result.push({
            note: voice.eventHistory[i] as PlayedNote,
            forcePolyphony:
              track.controls.forcePolyphony === true
                ? "unison"
                : track.controls.forcePolyphony === false
                ? "none"
                : track.controls.forcePolyphony,
          });
        }
        if (voice.eventHistory[i].ticks < tick) {
          break;
        }
      }
    }
  });
  return result;
}

export function calculateNoteFreq(
  controls: TrackControls,
  noteToPlay: { scaleDegree: PlayableScaleDegree; octave: number }
) {
  let refPitchOctave = getRefPitchOctave(
    controls.tuningSystem?.refPitchNoteMidi || 0,
    controls.tuningSystem?.refPitchNoteName
  );
  let baseFreq = midiToFreq(controls.tuningSystem?.refPitchNoteMidi || 0);
  let freq = baseFreq * 2 ** (noteToPlay.scaleDegree.cents / 1200);
  freq *= 2 ** (noteToPlay.octave - refPitchOctave);
  return freq;
}

function chooseNoteVelocity(
  track: TrackState,
  voice: VoiceState,
  atTicks: number,
  baseVelocity: number,
  isTickAccented: boolean
) {
  let accented = track.controls.useAccentVelocity && isTickAccented;
  let noiseValue = noise.getVal(voice.velocityNoiseSeed + atTicks / 500);
  let relVelocity =
    track.controls.minVelocity +
    noiseValue * (track.controls.maxVelocity - track.controls.minVelocity);

  let velocity =
    baseVelocity *
    (MIN_BASE_VELOCITY + relVelocity * (MAX_BASE_VELOCITY - MIN_BASE_VELOCITY));

  if (accented) {
    velocity += ACCENT_VELOCITY_DELTA;
  }
  return { velocity, baseVelocity: relVelocity, accented };
}

function chooseArticulatedDuration(
  beatDivision: BeatDivision,
  duration: number,
  track: TrackState,
  voice: VoiceState,
  atTicks: number
) {
  if (track.controls.activeArticulation === "minMax") {
    let noiseValue = noise.getVal(voice.articulationSeed + atTicks / 500);
    let rnd =
      track.controls.minNoteLength +
      noiseValue *
        (track.controls.maxNoteLength - track.controls.minNoteLength);
    return { choice: rnd, duration: rnd * duration };
  } else {
    let maxDur =
      beatDivision.type === "notevalue"
        ? NOTE_VALUE_BEAT_VALUES[beatDivision.value]
        : beatDivision.ticks;
    let articulationDur =
      beatDivision.type === "notevalue"
        ? track.controls.noteLengths[beatDivision.value]
        : 1;
    return {
      choice: beatDivision.type === "notevalue" ? beatDivision.value : -1,
      duration: Math.min(
        duration,
        Math.max(
          0.01,
          getTransport().toSeconds({ "1n": maxDur * articulationDur })
        )
      ),
    };
  }
}

function chooseNoteDelay(
  track: TrackState,
  voice: VoiceState,
  duration: number,
  atTicks: number
) {
  let noiseValue = noise.getVal(voice.noteDelaySeed + atTicks / 500);
  return Math.min(
    track.controls.minBeatDelay / 1000 +
      (noiseValue *
        (track.controls.maxBeatDelay - track.controls.minBeatDelay)) /
        1000,
    duration - 0.01
  );
}

function playNoteOnTrack(
  track: TrackState,
  trackId: string,
  voiceIdx: number,
  cents: number,
  octave: number,
  freq: number,
  velocity: number,
  baseVelocity: number,
  accented: boolean,
  time: number,
  delay: number,
  duration: number,
  chosenNoteLength: NoteValue | number | undefined,
  playedNote: PlayedNote
) {
  if (track.controls.instrument === "string") {
    player.playTrackNoteOnString(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "basicSynth") {
    player.playTrackNoteOnBasicSynth(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "obxd") {
    player.playTrackNoteOnOBXD(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "dexed") {
    player.playTrackNoteOnDEXED(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "yoshimi") {
    player.playTrackNoteOnYoshimi(
      trackId,
      cents,
      octave,
      freq,
      duration,
      delay,
      velocity,
      time
    );
  } else if (track.controls.instrument === "midi") {
    let output = midiOutputs[trackId];
    if (output && output.output) {
      let midiNote = getClosestMIDINote(freq, output.pitchBendRangeCents, null);
      let pitchBend = getMIDIPitchBend(
        freq,
        midiNote,
        output.pitchBendRangeCents
      );
      let midiNoteTime =
        midiOutputMgr.now() +
        (time + delay - getTransport().immediate()) * 1000;
      let channel = getMidiChannel(output);
      midiOutputMgr.sendNote(
        output.output,
        output.channel,
        midiNote,
        pitchBend,
        channel,
        velocity,
        duration * 1000 - 10,
        midiNoteTime
      );
      player.playTrackNoteOnMIDI(
        trackId,
        cents,
        octave,
        freq,
        duration,
        delay,
        velocity,
        time
      );
    }
  }
  for (let i = 0; i < track.controls.lfos.length; i++) {
    if (track.controls.lfos[i].on && track.controls.lfos[i].retrigger) {
      track.lfoStates[i].pendingRetriggerTimes.push(time);
    }
  }

  let eventData = {
    voiceIdx,
    stringIndex: playedNote.stringIndex,
    pitchClassIndex: playedNote.pitchClassIndex,
    beatDivision: playedNote.beatDivision.value,
    beatDivisionRelativeDuration: playedNote.beatDivisionRelativeDuration,
    chosenScaleWeight: playedNote.chosenScaleWeight,
    cents: playedNote.scaleDegree.cents,
    scaleDegreeIndex: playedNote.scaleDegree.index,
    freq,
    velocity,
    baseVelocity,
    accented,
    time,
    duration,
    delay: delay * 1000,
    octave: playedNote.octave,
    interval: playedNote.interval,
    noteLength: chosenNoteLength,
  };
  getDraw().schedule(() => track.events.emit("note", eventData), time + delay);
  track.events.emit("noteImmediate", {
    ...eventData,
    scheduledForTime: time + delay,
  });
}

function getMidiChannel(output: MIDIOutput) {
  if (output.channel === "all" || output.channel === "mpe") {
    let min =
      output.channel === "all"
        ? MIDI_CHANNEL_ROUND_ROBIN_MIN
        : MIDI_CHANNEL_MPE_ROUND_ROBIN_MIN;
    let max =
      output.channel === "all"
        ? MIDI_CHANNEL_ROUND_ROBIN_MAX
        : MIDI_CHANNEL_MPE_ROUND_ROBIN_MAX;
    let rr = midiChannelRoundRobins.get(output.output!.id) ?? min;
    if (rr < min) {
      rr = min;
    }
    let nextRr = rr + 1;
    if (nextRr > max) {
      nextRr = min;
    }
    midiChannelRoundRobins.set(output.output!.id, nextRr);
    return rr;
  } else {
    return output.channel;
  }
}

function playRestOnTrack(
  track: TrackState,
  time: number,
  playedRest: PlayedRest
) {
  getDraw().schedule(
    () =>
      track.events.emit("note", {
        beatDivision: playedRest.beatDivision.value,
        chosenScaleWeight: "Rest",
        duration: playedRest.duration,
      }),
    time
  );
}

export function removeTrack(id: string) {
  let track = tracks.get(id);
  if (track) {
    for (let voice of track.voices) {
      if (isNumber(voice.nextSchedule)) {
        getTransport().clear(voice.nextSchedule);
      }
      if (voice.currentLoopPart) {
        voice.currentLoopPart.stop();
        voice.currentLoopPart.dispose();
      }
    }
  }
  if (track) {
    for (let [noteId, schedule] of track.nextNoteEchoes.entries()) {
      getTransport().clear(schedule);
      track.nextNoteEchoes.delete(noteId);
    }
  }
  if (track?.controls.pluginGUIOpen) {
    player.closeOBXDGUI(id);
    player.closeDEXEDGUI(id);
  }
  if (track?.hasBasicSynth) {
    player.disposeTrackBasicSynth(id, immediate());
    track.hasBasicSynth = false;
  }
  if (track?.hasString) {
    player.disposeTrackString(id, immediate());
    track.hasString = false;
  }
  if (track?.hasObxd) {
    player.disposeTrackOBXD(id, immediate());
    track.hasObxd = false;
    track.lastObxdState = undefined;
  }
  if (track?.hasDexed) {
    player.disposeTrackDEXED(id, immediate());
    track.hasDexed = false;
    track.lastDexedState = undefined;
  }
  if (track?.hasYoshimi) {
    player.disposeTrackYoshimi(id, immediate());
    track.hasYoshimi = false;
    track.lastYoshimiState = undefined;
  }
  player.removeTrack(id);
  tracks.delete(id);
  hidePluginGUIContainerIfNoGUIOpen();
}

export function getTrackEvents(id: string) {
  return tracks.get(id)?.events;
}

export function getTrackMelodyShapeCurve(id: string) {
  return tracks.get(id)?.melodyShapeLfoCurve ?? [];
}

export function getTrackControls(id: string) {
  return tracks.get(id)?.controls;
}

export function setPluginGUIContainer(container: Rnd) {
  pluginGUIContainer = container;
}

export function setMidiOutputs(newOutputs: { [id: string]: MIDIOutput }) {
  midiOutputs = newOutputs;
  for (let trackId of Object.keys(newOutputs)) {
    let track = tracks.get(trackId);
    if (track) {
      sendMidiTuningSysex(trackId, track.controls);
    }
  }
}

function hidePluginGUIContainerIfNoGUIOpen() {
  setTimeout(() => {
    if (!Array.from(tracks.values()).find((l) => l.controls.pluginGUIOpen)) {
      pluginGUIContainer.updateSize({ width: 0, height: 0 });
    }
  });
}

export function setMidiClockOutputs(outputs: Output[]) {
  for (let output of outputs) {
    if (!midiClockOutputs.find((o) => o.output === output)) {
      midiClockOutputs.push({ output, started: false });
    }
  }
  midiClockOutputs = midiClockOutputs.filter((current) =>
    outputs.find((o) => current.output === o)
  );
  if (midiClockOutputs.length > 0 && isNull(midiClockTickSchedule)) {
    let clockTicks = getTransport().PPQ / 24;
    let startTime = getTransport().nextSubdivision(`4n`);
    let startTicks = getTransport().getTicksAtTime(startTime);
    console.log("starting midi clock at", `${clockTicks}i`, "time", startTicks);
    midiClockTickSchedule = getTransport().scheduleRepeat(
      (time) => sendMidiClockTick(time - MIN_NEGATIVE_NOTE_DELAY_S),
      `${clockTicks}i`,
      `${startTicks}i`
    );
  } else if (midiClockOutputs.length === 0 && !isNull(midiClockTickSchedule)) {
    console.log("stopping midi clock");
    getTransport().clear(midiClockTickSchedule);
    midiClockTickSchedule = null;
  }
}

function sendMidiClockTick(atTime: number) {
  if (getTransport().state === "stopped") return;
  let time = midiOutputMgr.now() + (atTime - immediate()) * 1000;
  for (let output of midiClockOutputs) {
    if (output.output.state === "connected") {
      if (!output.started) {
        midiOutputMgr.clockStart(output.output, time);
        output.started = true;
      }
      midiOutputMgr.clock(output.output, time);
    }
  }
}

function sendMidiTuningSysex(trackId: string, controls: TrackControls) {
  let output = midiOutputs[trackId];
  if (output && output.output) {
    let scaleDegrees = getTrackScaleDegrees(controls);
    let includedScaleDegrees = scaleDegrees.filter((sd) => sd.role !== "None");
    let cents = includedScaleDegrees.map((sd) => sd.cents);
    if (cents.length > 0) {
      let centsRelativeToFirst = cents.map((c) => c - cents[0]);
      let midiBytes = flatMap(centsRelativeToFirst, centsToMidiBytes);
      midiOutputMgr.sysex(output.output, 0x7e, midiBytes, midiOutputMgr.now());
    }
  }
}

function centsToMidiBytes(cents: number) {
  let centsMul = Math.round(cents * 1000);
  // low byte, middle byte, high byte
  return [centsMul & 0x7f, (centsMul >> 7) & 0x7f, (centsMul >> 14) & 0x7f];
}
