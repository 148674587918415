import React from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Tuning } from "../types";
import { getPitchCents } from "../utils";

const Container = styled(motion.div)`
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(var(--unit) * -1);
  bottom: calc(var(--unit) * -1);
  pointer-events: none;
`;

interface PitchLabelProps {
  left: number;
  index: number;
  isHighlighted: boolean;
}
const PitchLabel = styled.div<PitchLabelProps>(
  ({ left, index, isHighlighted }) => `
  position: absolute;
  z-index: ${isHighlighted ? "997" : "auto"};
  top: ${index % 2 === 0 ? "0" : "auto"};
  bottom: ${index % 2 !== 0 ? "0" : "auto"};
  left: 0;
  width: var(--unit);
  height: var(--unit);
  transform: translateX(${left ? left - 20 : -20}px);
  color: ${
    isHighlighted ? "var(--pitch-label-highlight)" : "var(--pitch-label)"
  };
  background: ${isHighlighted ? "var(--track)" : "transparent"};
  transition: color 0.05s, background-color 0.05s;
  `
);

const PitchRatio = styled.div`
  width: var(--unit);
  height: var(--unit);
  display: inline-grid;
  grid-template-rows: repeat(3, min-content);
  place-content: center;
  align-items: center;
  user-select: none;
`;

const PitchRatioUpper = styled.div`
  text-align: center;
`;

interface PitchRatioDividerProps {
  isHighlighted: boolean;
}
const PitchRatioDivider = styled.div<PitchRatioDividerProps>(
  ({ isHighlighted }) => `
  height: 0.5px;
  background: ${
    isHighlighted ? "var(--pitch-label-highlight)" : "var(--pitch-label)"
  };
  transition: background 0.05s;
  margin: 1px 0 0 0;
`
);

const PitchRatioLower = styled.div`
  text-align: center;
`;

const PitchCents = styled.div`
  width: var(--unit);
  height: var(--unit);
  display: grid;
  place-content: center;
  text-align: center;
`;

interface TrackPitchLabelsProps {
  tuning?: Tuning;
  width: number;
  highlightIndex?: number | null;
  trackOpen: boolean;
}
export const TrackPitchLabels: React.FC<TrackPitchLabelsProps> = ({
  tuning,
  width,
  highlightIndex,
  trackOpen,
}) => {
  let pcs = tuning?.pitchClasses ?? [];

  const variants = {
    hidden: {
      opacity: 0,
      transition: { duration: 0.2 },
    },
    visible: {
      opacity: 1,
      transition: { duration: 0.2, delay: 0.1 },
    },
  };

  return (
    <Container
      variants={variants}
      initial={"initial"}
      animate={trackOpen ? "visible" : "hidden"}
    >
      {pcs.map((pc, i) => (
        <PitchLabel
          index={i}
          key={i}
          left={(getPitchCents(pc) / 1200) * width}
          isHighlighted={i === highlightIndex}
        >
          {pc.type === "ratio" ? (
            <PitchRatio>
              <PitchRatioUpper>{pc.upper}</PitchRatioUpper>
              <PitchRatioDivider isHighlighted={i === highlightIndex} />
              <PitchRatioLower>{pc.lower}</PitchRatioLower>
            </PitchRatio>
          ) : (
            <PitchCents>{pc.cents.toFixed(1)}</PitchCents>
          )}
        </PitchLabel>
      ))}
    </Container>
  );
};
