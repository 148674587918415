import { random, range, sample } from "lodash";
import { NoteValue, InstrumentBanks, TrackControls } from "./types";
import * as tunings from "./tuningsAccess";
import { MIN_NOTE_LENGTH, MAX_BEAT_DELAY, MAX_NOTE_LENGTH } from "./Track";

export async function randomizeTrack(
  controls: TrackControls,
  instrumentBanks: InstrumentBanks,
  accessToken?: string
): Promise<Partial<TrackControls>> {
  if (!controls.followTuningFromLeadTrack) {
    controls = { ...controls, ...(await randomizeTrackTuning(accessToken)) };
  }

  if (!controls.followScaleWeightsFromLeadTrack) {
    controls = {
      ...controls,
      activeScaleWeights: "role",
      roleWeights: {
        Tonic: Math.random(),
        Primary: Math.random(),
        Secondary: 0,
        None: 0,
        Rest: Math.random(),
      },
    };
  }

  if (!controls.followAllowedIntervalsFromLeadTrack) {
    controls = {
      ...controls,
      allowedIntervals: (
        range(0, controls.scale!.scaleDegrees.length) as (number | "8ve")[]
      )
        .concat(["8ve"])
        .map((interval) => ({
          interval,
          allowed: Math.random() < 0.6,
        })),
      forcePolyphony: Math.random() < 0.5,
    };
  }

  controls = {
    ...controls,
    octaveWeights: {
      0: random(0, 0.2),
      1: random(0, 0.3),
      2: random(true),
      3: random(true),
      4: random(true),
      5: random(true),
      6: random(0, 0.5),
      7: random(0, 0.3),
      8: random(0, 0.2),
    },
  };

  controls = {
    ...controls,
    useAccentVelocity: Math.random() < 0.5,
  };

  if (!controls.followBeatDivisionWeightsFromLeadTrack) {
    let divRnd = Math.random();
    if (divRnd < 0.5) {
      controls = {
        ...controls,
        beatDivisionType: "weights",
        beatDivisionWeights: {
          "1": Math.random(),
          "1/2": Math.random(),
          "1/3": Math.random(),
          "1/4": Math.random(),
          "1/5": Math.random(),
          "1/6": Math.random(),
          "1/7": Math.random(),
          "1/8": Math.random(),
          "1/9": Math.random(),
          "1/10": Math.random(),
          "1/11": Math.random(),
          "1/12": Math.random(),
          "1/13": Math.random(),
          "1/14": Math.random(),
          "1/15": Math.random(),
          "1/16": Math.random(),
          "2": 0,
          "3": 0,
          "4": 0,
          "5": 0,
          "6": 0,
          "7": 0,
          "8": 0,
          "9": 0,
          "10": 0,
          "11": 0,
          "12": 0,
          "13": 0,
          "14": 0,
          "15": 0,
          "16": 0,
        },
      };
    } else if (divRnd < 0.65) {
      controls = {
        ...controls,
        beatDivisionType: "weights",
        beatDivisionWeights: {
          "1": Math.random(),
          "1/2": 0,
          "1/3": 0,
          "1/4": 0,
          "1/5": 0,
          "1/6": 0,
          "1/7": 0,
          "1/8": 0,
          "1/9": 0,
          "1/10": 0,
          "1/11": 0,
          "1/12": 0,
          "1/13": 0,
          "1/14": 0,
          "1/15": 0,
          "1/16": 0,
          "2": Math.random(),
          "3": Math.random(),
          "4": Math.random(),
          "5": Math.random(),
          "6": Math.random(),
          "7": Math.random(),
          "8": Math.random(),
          "9": Math.random(),
          "10": Math.random(),
          "11": Math.random(),
          "12": Math.random(),
          "13": Math.random(),
          "14": Math.random(),
          "15": Math.random(),
          "16": Math.random(),
        },
      };
    } else {
      let k = random(1, 15);
      let n = random(k + 1, 16);
      controls = {
        ...controls,
        beatDivisionType: "euclidean",
        beatDivisionEuclideanK: k,
        beatDivisionEuclideanN: n,
        beatDivisionEuclideanBeatValue: sample([
          "1",
          "1/2",
          "1/4",
          "1/8",
          "1/16",
        ]) as NoteValue,
      };
    }
  }

  let minBeatDelay = 0;
  let maxBeatDelay = random(minBeatDelay, MAX_BEAT_DELAY * 0.6);
  let minNoteLength = MIN_NOTE_LENGTH;
  let maxNoteLength = MAX_NOTE_LENGTH;
  let minVelocity = 0;
  let maxVelocity = 1;
  controls = {
    ...controls,
    minBeatDelay,
    maxBeatDelay,
    activeArticulation: "minMax",
    minNoteLength,
    maxNoteLength,
    minVelocity,
    maxVelocity,
  };

  let instrumentChoice = Math.random();
  if (instrumentChoice < 0.1) {
    controls = {
      ...controls,
      instrument: "basicSynth",
    };
  } else if (instrumentChoice < 0.2) {
    controls = {
      ...controls,
      instrument: "string",
    };
  } else if (instrumentChoice < 0.5) {
    let bank = random(0, instrumentBanks.OBXD.length - 1);
    let preset = random(0, instrumentBanks.OBXD[bank].presets.length - 1);
    controls = {
      ...controls,
      instrument: "obxd",
      obState: {
        bank,
        preset,
        patchState: instrumentBanks.OBXD[bank].presets[preset].patch,
      },
    };
  } else if (instrumentChoice < 0.8) {
    let bank = random(0, instrumentBanks.DEXED.length - 1);
    let preset = random(0, instrumentBanks.DEXED[bank].presets.length - 1);
    controls = {
      ...controls,
      instrument: "dexed",
      dxState: {
        bank,
        preset,
        patchState: instrumentBanks.DEXED[bank].presets[preset].patch,
      },
    };
  } else {
    let bank = random(0, instrumentBanks.Yoshimi.length - 1);
    let preset = random(0, instrumentBanks.Yoshimi[bank].presets.length - 1);
    controls = {
      ...controls,
      instrument: "yoshimi",
      yoshimiState: {
        bank,
        preset,
      },
    };
  }

  controls = {
    ...controls,
    pan: random(-0.5, 0.5),
    send1Gain: random(0, 0.6),
    send2Gain: Math.random() < 0.3 ? random(0, 0.6) : 0,
  };

  return controls;
}

async function randomizeTrackTuning(
  accessToken?: string
): Promise<Partial<TrackControls>> {
  let sanity = 10;
  while (sanity-- > 0) {
    let tuningSystems = await tunings.loadTuningSystems(accessToken);
    let tuningSystem = sample(tuningSystems)!;
    let scales = (await tunings.loadScales(accessToken)).filter(
      (scale) => scale.tuningSystemId === tuningSystem.id
    );
    if (scales.length > 0) {
      let scale = sample(scales)!;
      return {
        tuningSystem,
        scale,
      };
    }
  }
  return {};
}
