import React, { useCallback } from "react";
import classNames from "classnames";

import {
  TrackControls,
  TrackLFO,
  TrackLFOShape,
  TrackModulatorLFO,
} from "./types";
import { ToggleSwitch } from "./ToggleSwitch";
import { LFO_TARGET_OPTIONS } from "./constants";
import { KnobControl } from "./KnobControl";
import { ALL_BEAT_DIVISION_WEIGHT_KEYS } from "./Track";

import "./LFOControls.scss";

interface LFOControlsProps<T extends TrackLFO | TrackModulatorLFO> {
  id: string;
  controls: TrackControls;
  prop: "lfos" | "melodyShapeLfos";
  onSetControls: (
    id: string,
    update: (oldControls: TrackControls) => TrackControls
  ) => void;
}
export function LFOControls<T extends TrackLFO | TrackModulatorLFO>({
  id,
  controls,
  prop,
  onSetControls,
}: LFOControlsProps<T>) {
  let onUpdateLFOShape = useCallback(
    (index: number, shape: TrackLFOShape) => {
      onSetControls(id, (c) => ({
        ...c,
        [prop]: [
          ...c[prop].slice(0, index),
          {
            ...c[prop][index],
            shape,
          },
          ...c[prop].slice(index + 1),
        ],
      }));
    },
    [id, onSetControls, prop]
  );

  let onUpdateLFOTarget = useCallback(
    (index: number, target: number) => {
      let updateTarget = (
        controls: TrackControls,
        lfo: TrackLFO | TrackModulatorLFO
      ): TrackLFO | TrackModulatorLFO => {
        if ("targets" in lfo) {
          return {
            ...lfo,
            targets: { ...lfo.targets, [controls.instrument]: target },
          };
        } else {
          return lfo;
        }
      };
      onSetControls(id, (c) => ({
        ...c,
        [prop]: [
          ...c[prop].slice(0, index),
          updateTarget(c, c[prop][index]),
          ...c[prop].slice(index + 1),
        ],
      }));
    },
    [id, onSetControls, prop]
  );
  let onUpdateLFORate = useCallback(
    (index: number, rate: number) => {
      onSetControls(id, (c) => ({
        ...c,
        [prop]: [
          ...c[prop].slice(0, index),
          { ...c[prop][index], rate: ALL_BEAT_DIVISION_WEIGHT_KEYS[rate] },
          ...c[prop].slice(index + 1),
        ],
      }));
    },
    [id, onSetControls, prop]
  );
  let onUpdateLFOUnsyncedRate = useCallback(
    (index: number, unsyncedRate: number) => {
      onSetControls(id, (c) => ({
        ...c,
        [prop]: [
          ...c[prop].slice(0, index),
          { ...c[prop][index], unsyncedRate },
          ...c[prop].slice(index + 1),
        ],
      }));
    },
    [id, onSetControls, prop]
  );
  let onUpdateLFOPhase = useCallback(
    (index: number, phase: number) => {
      onSetControls(id, (c) => ({
        ...c,
        [prop]: [
          ...c[prop].slice(0, index),
          { ...c[prop][index], phase },
          ...c[prop].slice(index + 1),
        ],
      }));
    },
    [id, onSetControls, prop]
  );
  let onUpdateLFOAmount = useCallback(
    (index: number, amount: number) => {
      onSetControls(id, (c) => ({
        ...c,
        [prop]: [
          ...c[prop].slice(0, index),
          { ...c[prop][index], amount },
          ...c[prop].slice(index + 1),
        ],
      }));
    },
    [id, onSetControls, prop]
  );

  return (
    <div className="lfoControlsContainer">
      {(controls[prop] as T[]).map((lfo, idx) => (
        <React.Fragment key={idx}>
          <div
            className={classNames("lfo", {
              isEnabled: lfo.on,
            })}
          >
            <label className="toggleSwitchLabel">
              <ToggleSwitch
                checked={lfo.on}
                onToggle={(checked) => {
                  onSetControls(id, (c) => ({
                    ...c,
                    [prop]: [
                      ...c[prop].slice(0, idx),
                      { ...lfo, on: checked },
                      ...c[prop].slice(idx + 1),
                    ],
                  }));
                }}
              />
              LFO {idx + 1}
            </label>
          </div>
          {lfo.on && (
            <div className="lfoControls">
              <div className="lfoControls--col">
                {"targets" in lfo && (
                  <select
                    className="select lfoControls--target"
                    value={
                      (lfo as TrackModulatorLFO).targets[controls.instrument]
                    }
                    onChange={(evt) =>
                      onUpdateLFOTarget(idx, +evt.target.value)
                    }
                  >
                    <option value={-1}>Target</option>
                    {controls.instrument !== "midi" &&
                      LFO_TARGET_OPTIONS.track.map((target) => (
                        <option key={target.param} value={target.param}>
                          {target.label}
                        </option>
                      ))}
                    {LFO_TARGET_OPTIONS[controls.instrument].map((target) => (
                      <option key={target.param} value={target.param}>
                        {target.label}
                      </option>
                    ))}
                  </select>
                )}
                <label className="lfoControls--sync">
                  <ToggleSwitch
                    checked={lfo.synced ?? true}
                    onToggle={(checked) => {
                      onSetControls(id, (c) => ({
                        ...c,
                        [prop]: [
                          ...c[prop].slice(0, idx),
                          { ...lfo, synced: checked },
                          ...c[prop].slice(idx + 1),
                        ],
                      }));
                    }}
                  />
                  Sync
                </label>
                <label className="lfoControls--retrigger">
                  <ToggleSwitch
                    checked={lfo.retrigger}
                    onToggle={(checked) => {
                      onSetControls(id, (c) => ({
                        ...c,
                        [prop]: [
                          ...c[prop].slice(0, idx),
                          { ...lfo, retrigger: checked },
                          ...c[prop].slice(idx + 1),
                        ],
                      }));
                    }}
                  />
                  Retrigger
                </label>
              </div>
              <div className="lfoControls--col">
                <select
                  className="select"
                  value={lfo.shape}
                  onChange={(evt) =>
                    onUpdateLFOShape(idx, evt.target.value as TrackLFOShape)
                  }
                >
                  <option value="sine">Sine</option>
                  <option value="square">Square</option>
                  <option value="rampUp">Ramp up</option>
                  <option value="rampDown">Ramp down</option>
                  <option value="random">Random</option>
                </select>
                <div className="lfoControls--modulationKnobs">
                  {(lfo.synced || !("synced" in lfo)) && (
                    <KnobControl
                      label="Rate"
                      value={ALL_BEAT_DIVISION_WEIGHT_KEYS.indexOf(lfo.rate)}
                      min={0}
                      max={ALL_BEAT_DIVISION_WEIGHT_KEYS.length - 1}
                      step={1}
                      valueFunction={(v) => ALL_BEAT_DIVISION_WEIGHT_KEYS[v]}
                      onUpdateValue={(val) => onUpdateLFORate(idx, val)}
                    />
                  )}
                  {lfo.synced === false && (
                    <KnobControl
                      label="Rate"
                      value={lfo.unsyncedRate ?? 0.1}
                      min={0}
                      max={3}
                      step={0.01}
                      onUpdateValue={(val) => onUpdateLFOUnsyncedRate(idx, val)}
                      valueSuffix="Hz"
                    />
                  )}
                  <KnobControl
                    label="Phase"
                    value={lfo.phase}
                    min={0}
                    max={1}
                    step={0.01}
                    onUpdateValue={(val) => onUpdateLFOPhase(idx, val)}
                  />
                  <KnobControl
                    label="Amount"
                    value={lfo.amount}
                    min={0}
                    max={127}
                    step={1}
                    onUpdateValue={(val) => onUpdateLFOAmount(idx, val)}
                  />
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
