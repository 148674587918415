import React from "react";
import styled from "@emotion/styled";
import { Tuning } from "../types";
import { getPitchCents } from "../utils";

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

interface PointerTargetProps {
  left: number;
}
const PointerTarget = styled.div<PointerTargetProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  transform: ${({ left }) => `translateX(${left}px)`};
  cursor: pointer;
`;

interface TrackMarkerInteractionProps {
  tuning?: Tuning;
  width: number;
  onToggleTrigger: (index: number) => void;
  onHighlightTrigger: (index: number) => void;
}
export const TrackMarkerInteractionProps: React.FC<
  TrackMarkerInteractionProps
> = ({ tuning, width, onToggleTrigger, onHighlightTrigger }) => {
  let pcs = tuning?.pitchClasses ?? [];

  return (
    <Container>
      {pcs.map((pc, i) => (
        <PointerTarget
          key={i}
          left={(getPitchCents(pc) / 1200) * width}
          onClick={() => onToggleTrigger(i)}
          onPointerOver={() => onHighlightTrigger(i)}
        />
      ))}
    </Container>
  );
};
